import configIcon from "../assets/config.svg"
import appsIcon from "../assets/apps.svg"
import exitIcon from "../assets/exit.svg"
import helpIcon from "../assets/help.svg"

export default function BottomActions({actions}){
    return(
        <div className="bottom-actions">
            {actions.map((action,i)=>{
                let icon=""
                if(action.icon=="config"){
                    icon=configIcon
                }else if(action.icon=="apps"){
                    icon=appsIcon
                }else if(action.icon=="exit"){
                    icon=exitIcon
                }else if(action.icon=="help"){
                    icon=helpIcon
                }
                return(
                    <div key={i} className="bottom-action" onClick={action.clickEvent}>
                        {action.label!="" && action.label}
                        {action.icon!="" && <img src={icon} />}
                    </div>
                )
            })}
        </div>
    )
}