import {useContext} from "react"
import GlobalContext from "../contexts/GlobalContext"
import useLoader from "../hooks/useLoader"
import axios from "axios"

export default function useApi(){

    const [globalContext]=useContext(GlobalContext)
    const {manageLoader}=useLoader()

    const requestApi=async (endpoint,post)=>{
        let url="https://ius-santander.duckdns.org/api/"+endpoint
        //let url="https://localhost/api/"+endpoint
        manageLoader("show")
        let postData={
            ...post,
            session_token:globalContext.session_token
        }
        if(post.upload){
            postData=new FormData()
            for(var key in post){
                postData.append(key,post[key])
            }
            postData.append("session_token",globalContext.session_token)
        }
        //console.log(postData)
        return new Promise((resolve,reject)=>{
            axios({
                method:"post",
                url:url,
                data:postData,
                headers:post.upload?{"Content-Type":"multipart/form-data"}:null
            }).then((response)=>{
                manageLoader("hide")
                //console.log("* "+endpoint+" *")
                //console.log(response.data)
                //console.log(response)
                //console.log("--------------")
                resolve(response.data)
            }).catch((error)=>{
                //console.log(error)
                manageLoader("hide")
                reject(error)
            })
        })
    }

    return requestApi

}