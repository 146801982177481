import {useState,useRef,useEffect} from "react"
import useApi from "../hooks/useApi"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import Divisor from "../commons/Divisor"
import Textbox from "../commons/Textbox"
import Selector from "../commons/Selector"
import Switch from "../commons/Switch"
import ButtonCommand from "../commons/ButtonCommand"

export default function CollectionsEdit({params}){

    const section="collections-edit"
    const requestApi=useApi()
    const [ready,setReady]=useState(0)
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const mode=params.mode
    const [idVal,setIdVal]=useState(0)
    const [defaultData,setDefaultData]=useState("")

    const nameInput=useRef()
    const [nameVal,setNameVal]=useState("")

    const dateStartInput=useRef()
    const [dateStartVal,setDateStartVal]=useState("")

    const dateEndInput=useRef()
    const [dateEndVal,setDateEndVal]=useState("")

    const periodsInput=useRef()
    const [periodsVal,setPeriodsVal]=useState("")
    const periodsOptions=[
        {label:"PRE12",value:1},
        {label:"SPLIT",value:2},
        {label:"PRE16",value:3},
        {label:"PRE16D",value:4},
        {label:"PRE16AC",value:5},
        {label:"AC12",value:6},
        {label:"AC16",value:7},
        {label:"CONTRASPLIT",value:8},
        {label:"POS",value:9},
        {label:"AMORT",value:10}
    ]

    const operationsInput=useRef()
    const [operationsVal,setOperationsVal]=useState("")
    const operationsOptions=[
        {label:"Indiferente",value:""},
        {label:"Con operaciones",value:"with_operations"},
        {label:"Sin operaciones",value:"without_operations"}
    ]

    const operationTypeInput=useRef()
    const [operationTypeVal,setOperationTypeVal]=useState("")
    const operationTypeOptions=[
        {label:"Compra de acciones",value:5},
        {label:"Venta de acciones",value:7},
        {label:"Ampliación de capital",value:2},
        {label:"Alta por canje",value:1},
        {label:"Baja por canje",value:4},
        {label:"Compra de derechos",value:6},
        {label:"Venta de derechos",value:8},
        {label:"Baja por amortización",value:3},
        {label:"Punto de inicio",value:9},
        {label:"Compra de bonos",value:10},
        {label:"Venta de bonos",value:11}
    ]

    const marketInput=useRef()
    const [marketVal,setMarketVal]=useState("")
    const marketOptions=[
        {label:"Primario",value:"primary"},
        {label:"Secundario",value:"secondary"}
    ]

    const premiumInput=useRef()
    const [premiumVal,setPremiumVal]=useState("")
    const premiumOptions=[
        {label:"Indiferente",value:""},
        {label:"Es premium",value:"premium"},
        {label:"No es premium",value:"not_premium"}
    ]
    
    const bufetesInput=useRef()
    const [bufetesVal,setBufetesVal]=useState("")
    const [bufetesOptions,setBufetesOptions]=useState([])

    const clientStatusInput=useRef()
    const [clientStatusVal,setClientStatusVal]=useState("")
    const clientStatusOptions=[
        {label:"Todos",value:""},
        {label:"Activos",value:"actives"},
        {label:"Inactivos",value:"inactives"}
    ]

    const subclientsInput=useRef()
    const [subclientsVal,setSubclientsVal]=useState("")
    const subclientsOptions=[
        {label:"Indiferente",value:""},
        {label:"Con personas asociadas",value:"subclients"},
        {label:"Sin personas asociadas",value:"not_subclients"}
    ]

    const filesInput=useRef()
    const [filesVal,setFilesVal]=useState("")
    const filesOptions=[
        {label:"Con fichas generales",value:"sheet"},
        {label:"Con poderes para pleitos",value:"power"},
        {label:"Con comprobantes de operaciones",value:"operations"},
        {label:"Con complementarios",value:"complement"},
        {label:"Sin documentos",value:"nothing"}
    ]

    const tagsInput=useRef()
    const [tagsVal,setTagsVal]=useState("")
    const tagsOptions=[
        {label:"Rojo",value:"1",color:"#FF3B2F"},
        {label:"Amarillo",value:"2",color:"#FFD207"},
        {label:"Fucsia",value:"3",color:"#FC5CB9"},
        {label:"Verde",value:"4",color:"#4CDA64"},
        {label:"Violeta",value:"5",color:"#9C71DA"},
        {label:"Naranja",value:"6",color:"#FF9501"},
        {label:"Azulado",value:"7",color:"#67899B"}
    ]

    const [statusVal,setStatusVal]=useState(1)
   
    const loadBufetes=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("bufetes/list",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(item){
                            newOptions.push({label:item.name,value:item.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadData=()=>{
        return new Promise(function(resolve){
            if(mode=="edit"){
                resolve(
                    requestApi("collections/info",{
                            reference:params.reference
                        })
                        .then(data=>{
                            return data.data
                        })
                )
            }else{
                resolve(
                    ()=>{return ""}
                )
            }
        })
    }

    useEffect(()=>{
        load()
    },[params.reference])

    const load=()=>{
        Promise.all([loadBufetes,loadData].map(func=>func())).then((result)=>{
            setBufetesOptions(result[0])
            setDefaultData(result[1])
            setReady(1)
        })
    }

    useEffect(()=>{
        if(mode=="edit" && ready){
            setIdVal(defaultData.id)
            setNameVal(defaultData.name)
            setDateStartVal(defaultData.date_start)
            setDateEndVal(defaultData.date_end)
            if(defaultData["periods"].length>0){
                let newPeriodsValue=[]
                periodsOptions.forEach(function(item){
                    defaultData["periods"].forEach(function(period){
                        if(period==item.value){
                            newPeriodsValue.push({label:item.label,value:item.value})
                        }
                    })
                })
                periodsInput.current.setValue(newPeriodsValue)                
            }
            if(defaultData.operations!=""){
                operationsOptions.forEach(function(item){
                    if(item.value==defaultData.operations){
                        operationsInput.current.setValue({label:item.label,value:item.value})
                    }
                })                
            }
            if(defaultData["operation_types"].length>0){
                let newOperationTypeValue=[]
                operationTypeOptions.forEach(function(item){
                    defaultData["operation_types"].forEach(function(operation_type){
                        if(operation_type==item.value){
                            newOperationTypeValue.push({label:item.label,value:item.value})
                        }
                    })
                })
                operationTypeInput.current.setValue(newOperationTypeValue)                
            }
            if(defaultData["markets"].length>0){
                let newMarketValue=[]
                marketOptions.forEach(function(item){
                    defaultData["markets"].forEach(function(market){
                        if(market==item.value){
                            newMarketValue.push({label:item.label,value:item.value})
                        }
                    })
                })
                marketInput.current.setValue(newMarketValue)
            }
            if(defaultData.premium!=""){
                premiumOptions.forEach(function(item){
                    if(item.value==defaultData.premium){
                        premiumInput.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(defaultData["bufetes"].length>0){
                let newBufetesValue=[]
                bufetesOptions.forEach(function(item){
                    defaultData["bufetes"].forEach(function(bufete){
                        if(bufete==item.value){
                            newBufetesValue.push({label:item.label,value:item.value})
                        }
                    })
                })
                bufetesInput.current.setValue(newBufetesValue)
            }
            if(defaultData.clients_status!=""){
                clientStatusOptions.forEach(function(item){
                    if(item.value==defaultData.clients_status){
                        clientStatusInput.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(defaultData.subclients!=""){
                subclientsOptions.forEach(function(item){
                    if(item.value==defaultData.subclients){
                        subclientsInput.current.setValue({label:item.label,value:item.value})
                    }
                })                
            }
            if(defaultData["files"].length>0){
                let newFilesValue=[]
                filesOptions.forEach(function(item){
                    defaultData["files"].forEach(function(file){
                        if(file==item.value){
                            newFilesValue.push({label:item.label,value:item.value})
                        }
                    })
                })
                filesInput.current.setValue(newFilesValue)
            }
            if(defaultData["tags"].length>0){
                let newTagsValue=[]
                tagsOptions.forEach(function(item){
                    defaultData["tags"].forEach(function(tag){
                        if(tag==item.value){
                            newTagsValue.push({label:item.label,value:item.value,color:item.color})
                        }
                    })
                })
                tagsInput.current.setValue(newTagsValue)
            }
            setStatusVal(defaultData.status)
        }else{
            setNameVal("")
            setDateStartVal("")
            setDateEndVal("")
            setPeriodsVal("")
            setOperationsVal("")
            setOperationTypeVal("")
            setMarketVal("")
            setPremiumVal("")
            setBufetesVal("")
            setClientStatusVal("")
            setSubclientsVal("")
            setFilesVal("")
            setTagsVal("")
            setStatusVal(1)
        }
    },[defaultData])

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            requestApi("collections/save",{
                    id:idVal,
                    mode:mode,
                    name:nameVal,
                    date_start:dateStartVal,
                    date_end:dateEndVal,
                    periods:periodsInput.current.getValue(),
                    operations:operationsInput.current.getValue()[0]?operationsInput.current.getValue()[0].value:"",
                    operation_types:operationTypeInput.current.getValue(),
                    markets:marketInput.current.getValue(),
                    premium:premiumInput.current.getValue()[0]?premiumInput.current.getValue()[0].value:"",
                    bufetes:bufetesInput.current.getValue(),
                    clients_status:clientStatusInput.current.getValue()[0]?clientStatusInput.current.getValue()[0].value:"",
                    subclients:subclientsInput.current.getValue()[0]?subclientsInput.current.getValue()[0].value:"",
                    files:filesInput.current.getValue(),
                    tags:tagsInput.current.getValue(),
                    status:statusVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        if(mode=="create"){
                            changeToast("created_m","")
                        }else if(mode=="edit"){
                            changeToast("saved","")
                        }
                        refresh({board:"collections",close:"collections-edit"})
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const remove=()=>{
        requestApi("collections/remove",{
                reference:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    refresh({board:"collections",close:"collections-edit"})
                }else{
                    changeToast("generic_error","")
                }
            })
    }

    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft={""}
                actionsRight={<ButtonAction label="Guardar" icon="wink" clickEvent={saveData} />} />
            <BoardContent>
                <div className="board-form">
                    <Textbox ref={nameInput} type="text" label="Nombre del grupo" val={nameVal} setVal={setNameVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={dateStartInput} type="date" label="Fecha de inicio" val={dateStartVal} setVal={setDateStartVal} disabled={false} />
                    <Textbox ref={dateEndInput} type="date" label="Fecha de finalización" val={dateEndVal} setVal={setDateEndVal} disabled={false} />
                    <Divisor />
                    <Selector ref={periodsInput} options={periodsOptions} label="Períodos" placeholder="" multiple={true} searchable={false} style="field" val={periodsVal} setVal={setPeriodsVal} />
                    <Selector ref={premiumInput} options={premiumOptions} label="Premium" placeholder="" multiple={false} searchable={false} style="field" val={premiumVal} setVal={setPremiumVal} />
                    <Divisor />
                    <Selector ref={operationTypeInput} options={operationTypeOptions} label="Tipos de operaciones" placeholder="" multiple={true} searchable={false} style="field" val={operationTypeVal} setVal={setOperationTypeVal} />
                    <Selector ref={marketInput} options={marketOptions} label="Mercados" placeholder="" multiple={true} searchable={false} style="field" val={marketVal} setVal={setMarketVal} />
                    <Selector ref={operationsInput} options={operationsOptions} label="Cantidad de operaciones" placeholder="" multiple={false} searchable={false} style="field" val={operationsVal} setVal={setOperationsVal} />
                    <Divisor />
                    <Selector ref={tagsInput} options={tagsOptions} label="Etiquetas" placeholder="" multiple={true} searchable={false} style="field" val={tagsVal} setVal={setTagsVal} />
                    <Selector ref={bufetesInput} options={bufetesOptions} label="Bufetes" placeholder="" multiple={true} searchable={true} style="field" val={bufetesVal} setVal={setBufetesVal} />
                    <Divisor />
                    <Selector ref={clientStatusInput} options={clientStatusOptions} label="Estado de los clientes" placeholder="" multiple={false} searchable={false} style="field" val={clientStatusVal} setVal={setClientStatusVal} />
                    <Selector ref={subclientsInput} options={subclientsOptions} label="Personas asociadas" placeholder="" multiple={false} searchable={false} style="field" val={subclientsVal} setVal={setSubclientsVal} />
                    <Divisor />
                    <Selector ref={filesInput} options={filesOptions} label="Documentos" placeholder="" multiple={true} searchable={false} style="field" val={filesVal} setVal={setFilesVal} />
                    <Divisor />
                    <Switch onLabel="Activo" offLabel="Inactivo" val={[statusVal,setStatusVal]} clickEvent="" />
                    {mode=="edit" &&
                    <div className="commands-block">
                        <ButtonCommand label="Eliminar" icon="remove" clickEvent={remove} />
                    </div>}
                </div>
            </BoardContent>
        </Board>
    )
}