import {useState,useEffect,Fragment} from "react"
import {formatAmount} from "../hooks/Commons"
import useApi from "../hooks/useApi"
import useBoard from "../hooks/useBoard"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import DataBlock from "../components/DataBlock"
import DataRows from "../components/DataRows"
import DataList from "../components/DataList"
import DataListItem from "../components/DataListItem"
import DataClient from "../components/DataClient"

export default function CollectionsData({params}){

    const section="collection-data"
    const requestApi=useApi()
    const [ready,setReady]=useState(0)
    const {loadBoard}=useBoard()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const [data,setData]=useState("")

    const loadData=()=>{
        requestApi("collections/info",{
                reference:params.reference
            })
            .then(data=>{
                setData(data.data)
                setReady(1)
            })
    }

    useEffect(()=>{
        loadData()
    },[])

    useEffect(()=>{
        loadData()
    },[params.reference])

    const saveData=()=>{
        requestApi("collections/save_data",{
                id:data["id"],
                clients:data["clients"].clients
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("saved","")
                    refresh({board:"collections",close:"collections-data"})
                    setTimeout(()=>{
                        loadBoard({params:{alias:"collections-data",level:2,theme:"rabbit2",reference:params.reference}})
                    },100)
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    const getPackage=()=>{
        requestApi("clients/package",{
                reference:params.reference,
                context:"collection"
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("package_generated","")
                    let link=document.createElement("a")
                    link.setAttribute("download","")
                    link.href=data.file
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    const getAssembly=()=>{
        requestApi("collections/assembly",{
                id:data["id"]
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("assembly_generated","")
                    let link=document.createElement("a")
                    link.setAttribute("download","")
                    link.setAttribute("target","_blank")
                    link.href=data.file
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    const excludeClient=(client)=>{
        const newClients=data.clients
        if(newClients.clients[client].excluded==0){
            newClients.clients[client].excluded=1
        }else{
            newClients.clients[client].excluded=0
        }
        setData({...data,clients:newClients})
    }

    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft={
                    <Fragment>
                        <ButtonAction label="Guardar" icon="wink" clickEvent={saveData} />
                        <ButtonAction label="Paquete" icon="folder" style="alt" clickEvent={getPackage} />
                        <ButtonAction label="Ensamblar" icon="assembly" style="alt" clickEvent={getAssembly} />
                    </Fragment>
                }
                actionsRight={<ButtonAction label="Editar" icon="edit" clickEvent={()=>{loadBoard({params:{alias:"collections-edit",level:2,theme:"rabbit",reference:params.reference,mode:"edit"}})}} />} />
            <BoardContent>
                {data &&
                <div className="board-data board-data-xl">
                    <DataBlock title="Información" style="bluish">
                        <DataRows items={[
                            {label:"Nombre",content:data.name,style:"bold"},
                            {label:"Fecha de inicio",content:data.date_start!=""?data.date_start:"Sin especificar",style:""},
                            {label:"Fecha de finalización",content:data.date_end!=""?data.date_end:"Sin especificar",style:""},
                            {label:"Períodos",content:data.periods_caption==""?"Cualquiera":data.periods_caption,style:""},
                            {label:"Premium",content:data.premium=="premium"?"Es premium":data.premium=="not_premium"?"No es premium":"Indiferente",style:""}
                        ]} />
                    </DataBlock>
                    <DataBlock title="Operaciones" style="white">
                        <DataRows items={[
                            {label:"Tipos de operaciones",content:data.operation_types_caption==""?"Todos":data.operation_types_caption,style:""},
                            {label:"Mercados",content:data.markets_caption==""?"Indiferente":data.markets_caption,style:""},
                            {label:"Cantidad de operaciones",content:data.operations=="with_operations"?"Con operaciones":data.operations=="without_operations"?"Sin operaciones":"Indiferente",style:""}
                        ]} />
                    </DataBlock>
                    <DataBlock title="Otros filtros" style="white">
                        <DataRows items={[
                            {label:"Bufetes",content:data.bufetes_caption==""?"Todos":data.bufetes_caption,style:""},
                            {label:"Estado de los clientes",content:data.clients_status=="actives"?"Activos":data.clients_status=="inactives"?"Inactivos":"Todos",style:""},
                            {label:"Personas asociadas",content:data.subclients=="subclients"?"Con personas asociadas":data.subclients=="not_subclients"?"Sin personas asociadas":"Indiferente",style:""},
                            {label:"Documentos",content:data.files_caption==""?"Indiferente":data.files_caption,style:""}
                        ]} />
                    </DataBlock>
                    <DataBlock title="" style="filled">
                        <div className="data-title-big data-colored">Monto reclamable</div>
                        <div className="data-title-medium data-colored">{formatAmount(data.clients.amounts.claimable,"EUR","display")} <span className="data-transparent">({formatAmount(data.clients.amounts.claimable_global,"EUR","display")})</span></div>
                    </DataBlock>
                    <DataBlock title="Resumen de operaciones" style="bordered">
                        <DataRows context="summary-collection" items={data.clients.summary} />
                    </DataBlock>  
                    {data.clients.clients.length>0 &&
                    <DataBlock title={data.clients.quantity.clients+(data.clients.quantity.clients==1?" cliente":" clientes")} style="white">
                        <DataList>
                            {data.clients.clients.map((item,i)=>{
                                return(
                                    <DataListItem key={i} left={<DataClient context="collection" item={item} index={i} excludeEvent={excludeClient} />} right={""} />
                                )
                            })}
                        </DataList>
                    </DataBlock>}
                </div>}
            </BoardContent>
        </Board>
    )
}