import {useState,useRef,useContext,forwardRef,Fragment} from "react"
import GlobalContext from "../contexts/GlobalContext"
import useApi from "../hooks/useApi"
import useViewer from "../hooks/useViewer"
import useToast from "../hooks/useToast"
import ButtonSmall from "../commons/ButtonSmall"
import upload from "../assets/upload.svg"
import wordIcon from "../assets/word.png"
import excelIcon from "../assets/excel.png"
import pdfIcon from "../assets/pdf.png"

const Uploader=forwardRef(({context,fileClass,val,setVal,label,labelUploading,note,restrict,multiple,viewer},ref)=>{
    
    const [globalContext]=useContext(GlobalContext)
    const {changeToast}=useToast()
    const {changeViewer}=useViewer()
    const requestApi=useApi()
    const [uploading,setUploading]=useState(false)
    const [hasFile,setHasFile]=useState(false)
    const [progressPercent,setProgressPercent]=useState()
    const progressBar=useRef()

    const select=()=>{
        if(!uploading){
            ref.current.click()
        }
    }

    const change=()=>{
        for(let i=0;i<ref.current.files.length;i++){
            let file=ref.current.files[i]
            if(restrict!=""){
                if(!restrict.includes(file.type)){
                    changeToast("file_format_not_allowed","")
                    ref.current.value=""
                    return false
                }
            }
            setUploading(true)
            let endpoint=""
            if(context=="clients-import"){
                endpoint="clients/upload"
            }else if(context=="client-files"){
                endpoint="clients/file_upload"
            }else if(context=="persons-import"){
                endpoint="persons/upload"
            }else if(context=="models-template"){
                endpoint="models/template_upload"
            }else if(context=="templates-template"){
                endpoint="templates/template_upload"
            }
            let formData=new FormData()
            formData.append("token","sauh3747321021nfubs324")
            formData.append("file",file)
            if(fileClass){
                formData.append("class",fileClass)
            }
            let xhr=new XMLHttpRequest()
            xhr.responseType="json"
            xhr.upload.addEventListener("progress",progress,false)
            xhr.addEventListener("load",success,false)
            xhr.addEventListener("error",error,false)
            xhr.addEventListener("abort",abort,false)
            xhr.open("POST",globalContext["api_url"]+endpoint)
            xhr.send(formData)
        }
    }

    const progress=(e)=>{
        let percent=(e.loaded/e.total)*100
        progressBar.current.style.width=percent+"%"
        setProgressPercent("("+Math.round(percent)+"%"+")")
    }
  
    const success=(e)=>{
        progressBar.current.style.width="0%"
        setUploading(false)
        setProgressPercent("")
        setVal(e.target.response)
        setHasFile("¡Archivo subido!")
    }

    const error=()=>{
        progressBar.current.style.width="0%"
        setUploading(false)
        setProgressPercent("")
        alert("Upload failed...")
    }

    const abort=()=>{
        progressBar.current.style.width="0%"
        setUploading(false)
        setProgressPercent("")
        alert("Upload aborted....")
    }

    const remove=(e)=>{
        e.stopPropagation()
        if(context=="clients-import"){
        }else if(context=="persons-import"){
        }else if(context=="client-files"){
            requestApi("clients/file_remove",{
                    context:"temp",
                    file:val
                })
                .then(data=>{})
        }else if(context=="models-template"){
            requestApi("models/template_remove",{
                    context:"temp",
                    file:val
                })
                .then(data=>{})
        }else if(context=="templates-template"){
            requestApi("templates/template_remove",{
                    context:"temp",
                    file:val
                })
                .then(data=>{})
        }
        setVal("")
    }

    const open=()=>{
        if(viewer==true){
            console.log(val)
            changeViewer(val.url,val.extension)
        }
    }

    return(
        <div className="field noselect">
            <div className="uploader">
                {(val=="" || val==undefined)?
                <Fragment>
                    <div className="uploader-button" onClick={select} style={{cursor:uploading?"not-allowed":"pointer"}}>
                        <div className="uploader-button-layout">
                            <div className="uploader-icon"><img src={upload} /></div>
                            <div>{uploading?labelUploading:label} {progressPercent}</div>
                        </div>
                    </div>
                    <input ref={ref} type="file" className="uploader-input" name="file" multiple={multiple?"multiple":null} onChange={change} />
                    {note!="" && <div className="uploader-note">{note}</div>}
                    {uploading &&
                    <div className="uploader-progress">
                        <div ref={progressBar} className="uploader-progress-bar"></div>
                    </div>}
                </Fragment>:
                <Fragment>
                    <div className="field-label">Documento</div>
                    <div className={"uploader-file"+(!(viewer==true && (val.extension=="docx" || val.extension=="xlsx" || val.extension=="pptx" || val.extension=="pdf")) ? " uploader-file-no-hover":"")} onClick={(viewer==true && (val.extension=="docx" || val.extension=="xlsx" || val.extension=="pptx" || val.extension=="pdf")) && open}>
                        <div className="uploader-preview-icon"><img src={val.extension=="docx"?wordIcon:val.extension=="xlsx"?excelIcon:val.extension=="pdf"?pdfIcon:""} /></div>
                        <div className="uploader-preview">{(viewer==true && (val.extension=="docx" || val.extension=="xlsx" || val.extension=="pptx" || val.extension=="pdf"))?"Previsualizar":"Subido"}</div>
                        <div className="uploader-remove">
                            <ButtonSmall icon="remove" clickEvent={(e)=>{remove(e)}} />
                        </div>
                    </div>
                </Fragment>}
            </div>
        </div>
    )
})

export default Uploader