import useBoard from "../hooks/useBoard"
import Finder from "../commons/Finder"
import ButtonCircle from "../commons/ButtonCircle"

export default function BoardTools({section,keyword,setKeyword,filter,setFilter,selection,setSelection}){

    const {loadBoard}=useBoard()

    const manageSelection=()=>{
        let selectionNew={...selection}
        if(selection.on==0){
            selectionNew["on"]=1
            setSelection(selectionNew)
        }else{
            selectionNew["on"]=0
            setSelection(selectionNew)
        }
    }

    return(
        <div className="board-header">
            {(section=="clients" || section=="collections" || section=="models" || section=="bufetes" || section=="imports" || section=="persons" || section=="assemblages" || section=="templates" || section=="imports-persons") &&
            <Finder keyword={keyword} setKeyword={setKeyword} />}
            {section=="clients" &&
            <ButtonCircle icon="create" clickEvent={()=>{loadBoard({params:{alias:"clients-edit",level:2,theme:"rabbit",mode:"create"}})}} />}
            {section=="collections" &&
            <ButtonCircle icon="create" clickEvent={()=>{loadBoard({params:{alias:"collections-edit",level:2,theme:"rabbit",mode:"create"}})}} />}
            {section=="models" &&
            <ButtonCircle icon="create" clickEvent={()=>{loadBoard({params:{alias:"models-edit",level:2,theme:"rabbit",mode:"create"}})}} />}
            {section=="bufetes" &&
            <ButtonCircle icon="create" clickEvent={()=>{loadBoard({params:{alias:"bufetes-edit",level:2,theme:"rabbit",mode:"create"}})}} />}
            {section=="persons" &&
            <ButtonCircle icon="create" clickEvent={()=>{loadBoard({params:{alias:"persons-edit",level:2,theme:"rabbit",mode:"create"}})}} />}
            {section=="assemblages" &&
            <ButtonCircle icon="create" clickEvent={()=>{loadBoard({params:{alias:"assemblages-edit",level:2,theme:"rabbit",mode:"create"}})}} />}
            {section=="templates" &&
            <ButtonCircle icon="create" clickEvent={()=>{loadBoard({params:{alias:"templates-edit",level:2,theme:"rabbit",mode:"create"}})}} />}
        </div>
    )
}