import {useState,useEffect,useRef,useContext,Fragment} from "react"
import GlobalContext from "../contexts/GlobalContext"
import useApi from "../hooks/useApi"
import useBoard from "../hooks/useBoard"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import ButtonCircle from "../commons/ButtonCircle"
import Textbox from "../commons/Textbox"
import Uploader from "../commons/Uploader"
import FieldTextbox from "../commons/FieldTextbox"
import ImportBlock from "../components/ImportBlock"
import FieldSelect from "../commons/FieldSelect"
import DataBlock from "../components/DataBlock"
import ok from "../assets/ok.svg"
import notOk from "../assets/not-ok.svg"
import magic from "../assets/magic.webp"

export default function ClientsImport({params}){

    const section="clients-import"
    const [globalContext]=useContext(GlobalContext)
    const {changeDialog,manageDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const requestApi=useApi()
    const [ready,setReady]=useState(1)
    const {loadBoard}=useBoard()
    const fileInput=useRef()
    const [fileVal,setFileVal]=useState()
    const nameInput=useRef()
    const [nameVal,setNameVal]=useState("")

    const [data,setData]=useState()

    const [operationTypeOptions]=useState([
        {label:"Operación desconocida",value:0},
        {label:"Alta por canje",value:1},
        {label:"Ampliación de capital",value:2},
        {label:"Baja por amortización",value:3},
        {label:"Baja por canje",value:4},
        {label:"Compra de acciones",value:5},
        {label:"Compra de derechos",value:6},
        {label:"Venta de acciones",value:7},
        {label:"Venta de derechos",value:8},
        {label:"Compra de bonos",value:10},
        {label:"Venta de bonos",value:11}
    ])

    const [bufeteOptions,setBufeteOptions]=useState([])
    useEffect(()=>{
        requestApi("bufetes/list",{})
            .then(data=>{
                let newOptions=[]
                data.items.forEach(function(item){
                    newOptions.push({label:item.name,value:item.id})
                })
                setBufeteOptions(newOptions)
            })
    },[])

    useEffect(()=>{
        if(fileVal){
            requestApi("clients/import",{
                    name:fileVal.name,
                    extension:fileVal.extension
                })
                .then(data=>{
                    setData(data)
                })
        }
    },[fileVal])

    const changeField=(val,index,field,fieldInner,indexInner,type)=>{
        let temp={...data}
        if(fieldInner){
            if(indexInner>=0){
                temp["items"][index][field][indexInner][fieldInner]=val.target.value
            }else{
                temp["items"][index][field][fieldInner]=val.target.value
            }
        }else{
            temp["items"][index][field]=val.target.value
        }
        setData(temp)
    }

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            requestApi("clients/import_save",{
                    name:nameVal,
                    data:data
                })
                .then(data=>{
                    if(data.status=="success"){
                        changeToast("imported","")
                        refresh({board:"clients",close:"clients-import"})
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const discard=()=>{
        changeDialog("discard_import","",()=>{
            manageDialog("hide")
            requestApi("clients/import_discard",{
                    name:data.name,
                    extension:data.extension
                })
                .then(data=>{
                    if(data.status=="success"){
                        loadBoard({params:{alias:"clients-edit",level:1,theme:"rabbit",mode:"create"}})
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        })
    }

    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft={
                    <Fragment>
                        {!data &&
                        <Fragment>
                            <ButtonCircle icon="back" style="alt" clickEvent={()=>{loadBoard({params:{alias:"clients-edit",level:3,theme:"rabbit",mode:"create"}})}} />
                        </Fragment>}                        
                    </Fragment>}
                actionsRight={
                    <Fragment>
                        {data &&
                        <Fragment>
                            <ButtonAction label="Descartar" icon="" style="alt" clickEvent={discard} />
                            <ButtonAction label="Importar" icon="wink" clickEvent={saveData} />
                        </Fragment>}
                    </Fragment>
                } />
            <BoardContent>
                {(!fileVal && !data) &&
                <div className="board-form">
                    <Uploader ref={fileInput} context="clients-import" val={fileVal} setVal={setFileVal} label="Subir archivo" labelUploading="Subiendo archivo" restrict={["application/zip","application/x-zip-compressed"]} multiple={false} />
                </div>}
                {(fileVal && !data) &&
                <div className="import-blocks">
                    <div className="import-magic">
                        <img src={magic} />
                    </div>                    
                </div>}
                {data &&
                <div className="import-blocks">
                    <DataBlock title="" style="bordered">
                        <div className="data-title-big data-colored">{data.items.length==1?"Se va a importar":"Se van a importar"} {data.items.length} {data.items.length==1?"cliente":"clientes"}</div>
                        <div className="data-colored">Este es el momento de revisar los datos y corregir errores.</div>
                        <Textbox ref={nameInput} type="text" label="Grupo de importación" val={nameVal} setVal={setNameVal} disabled={false} />
                    </DataBlock>
                    {data.items.map((item,i)=>{
                        return(
                            <ImportBlock key={i}>
                                <div className="import-section import-section-first">Cliente #{item.id}</div>
                                <div className="import-half">
                                    <FieldTextbox val={item.first_name} label="Nombre" field="first_name" index={i} changeEvent={changeField} review={item.first_name==""?true:false} />
                                    <FieldTextbox val={item.last_name} label="Apellido" field="last_name" index={i} changeEvent={changeField} review={item.last_name==""?true:false} />
                                </div>
                                <div className="import-third">
                                    <FieldTextbox val={item.nif} label="NIF" field="nif" index={i} changeEvent={changeField} review={item.nif==""?true:false} />
                                    <FieldSelect val={item.bufete} options={bufeteOptions} label="Bufete" field="bufete" index={i} changeEvent={changeField} review={item.bufete==""?true:false} />
                                    <FieldTextbox val={item.client_id} label="Número de cliente" field="client_id" index={i} changeEvent={changeField} review={item.client_id==""?true:false} />
                                </div>                                
                                {(!Array.isArray(item.attached_1) || !Array.isArray(item.attached_2) || !Array.isArray(item.attached_3) || !Array.isArray(item.attached_4) || !Array.isArray(item.attached_5) || !Array.isArray(item.attached_6) || !Array.isArray(item.attached_7) || !Array.isArray(item.attached_8) || !Array.isArray(item.attached_9) || !Array.isArray(item.attached_10)) &&
                                <div className="import-section">Personas asociadas</div>}
                                {!Array.isArray(item.attached_1) && <div>
                                    <div className="import-third">
                                        <FieldTextbox val={item.attached_1.first_name} label="Nombre" field="attached_1" fieldInner="first_name" index={i} changeEvent={changeField} review={item.attached_1.first_name==""?true:false} />
                                        <FieldTextbox val={item.attached_1.last_name} label="Apellido" field="attached_1" fieldInner="last_name" index={i} changeEvent={changeField} review={item.attached_1.last_name==""?true:false} />
                                        <FieldTextbox val={item.attached_1.nif} label="NIF" field="attached_1" fieldInner="nif" index={i} changeEvent={changeField} review={item.attached_1.nif==""?true:false} />
                                    </div>
                                </div>}
                                {!Array.isArray(item.attached_2) && <div>
                                    <div className="import-third">
                                        <FieldTextbox val={item.attached_2.first_name} label="Nombre" field="attached_2" fieldInner="first_name" index={i} changeEvent={changeField} review={item.attached_2.first_name==""?true:false} />
                                        <FieldTextbox val={item.attached_2.last_name} label="Apellido" field="attached_2" fieldInner="last_name" index={i} changeEvent={changeField} review={item.attached_2.last_name==""?true:false} />
                                        <FieldTextbox val={item.attached_2.nif} label="NIF" field="attached_2" fieldInner="nif" index={i} changeEvent={changeField} review={item.attached_2.nif==""?true:false} />
                                    </div>
                                </div>}
                                {!Array.isArray(item.attached_3) && <div>
                                    <div className="import-third">
                                        <FieldTextbox val={item.attached_3.first_name} label="Nombre" field="attached_3" fieldInner="first_name" index={i} changeEvent={changeField} review={item.attached_3.first_name==""?true:false} />
                                        <FieldTextbox val={item.attached_3.last_name} label="Apellido" field="attached_3" fieldInner="last_name" index={i} changeEvent={changeField} review={item.attached_3.last_name==""?true:false} />
                                        <FieldTextbox val={item.attached_3.nif} label="NIF" field="attached_3" fieldInner="nif" index={i} changeEvent={changeField} review={item.attached_3.nif==""?true:false} />
                                    </div>
                                </div>}
                                {!Array.isArray(item.attached_4) && <div>
                                    <div className="import-third">
                                        <FieldTextbox val={item.attached_4.first_name} label="Nombre" field="attached_4" fieldInner="first_name" index={i} changeEvent={changeField} review={item.attached_4.first_name==""?true:false} />
                                        <FieldTextbox val={item.attached_4.last_name} label="Apellido" field="attached_4" fieldInner="last_name" index={i} changeEvent={changeField} review={item.attached_4.last_name==""?true:false} />
                                        <FieldTextbox val={item.attached_4.nif} label="NIF" field="attached_4" fieldInner="nif" index={i} changeEvent={changeField} review={item.attached_4.nif==""?true:false} />
                                    </div>
                                </div>}
                                {!Array.isArray(item.attached_5) && <div>
                                    <div className="import-third">
                                        <FieldTextbox val={item.attached_5.first_name} label="Nombre" field="attached_5" fieldInner="first_name" index={i} changeEvent={changeField} review={item.attached_5.first_name==""?true:false} />
                                        <FieldTextbox val={item.attached_5.last_name} label="Apellido" field="attached_5" fieldInner="last_name" index={i} changeEvent={changeField} review={item.attached_5.last_name==""?true:false} />
                                        <FieldTextbox val={item.attached_5.nif} label="NIF" field="attached_5" fieldInner="nif" index={i} changeEvent={changeField} review={item.attached_5.nif==""?true:false} />
                                    </div>
                                </div>}
                                {!Array.isArray(item.attached_6) && <div>
                                    <div className="import-third">
                                        <FieldTextbox val={item.attached_6.first_name} label="Nombre" field="attached_6" fieldInner="first_name" index={i} changeEvent={changeField} review={item.attached_6.first_name==""?true:false} />
                                        <FieldTextbox val={item.attached_6.last_name} label="Apellido" field="attached_6" fieldInner="last_name" index={i} changeEvent={changeField} review={item.attached_6.last_name==""?true:false} />
                                        <FieldTextbox val={item.attached_6.nif} label="NIF" field="attached_6" fieldInner="nif" index={i} changeEvent={changeField} review={item.attached_6.nif==""?true:false} />
                                    </div>
                                </div>}
                                {!Array.isArray(item.attached_7) && <div>
                                    <div className="import-third">
                                        <FieldTextbox val={item.attached_7.first_name} label="Nombre" field="attached_7" fieldInner="first_name" index={i} changeEvent={changeField} review={item.attached_7.first_name==""?true:false} />
                                        <FieldTextbox val={item.attached_7.last_name} label="Apellido" field="attached_7" fieldInner="last_name" index={i} changeEvent={changeField} review={item.attached_7.last_name==""?true:false} />
                                        <FieldTextbox val={item.attached_7.nif} label="NIF" field="attached_7" fieldInner="nif" index={i} changeEvent={changeField} review={item.attached_7.nif==""?true:false} />
                                    </div>
                                </div>}
                                {!Array.isArray(item.attached_8) && <div>
                                    <div className="import-third">
                                        <FieldTextbox val={item.attached_8.first_name} label="Nombre" field="attached_8" fieldInner="first_name" index={i} changeEvent={changeField} review={item.attached_8.first_name==""?true:false} />
                                        <FieldTextbox val={item.attached_8.last_name} label="Apellido" field="attached_8" fieldInner="last_name" index={i} changeEvent={changeField} review={item.attached_8.last_name==""?true:false} />
                                        <FieldTextbox val={item.attached_8.nif} label="NIF" field="attached_8" fieldInner="nif" index={i} changeEvent={changeField} review={item.attached_8.nif==""?true:false} />
                                    </div>
                                </div>}
                                {!Array.isArray(item.attached_9) && <div>
                                    <div className="import-third">
                                        <FieldTextbox val={item.attached_9.first_name} label="Nombre" field="attached_9" fieldInner="first_name" index={i} changeEvent={changeField} review={item.attached_9.first_name==""?true:false} />
                                        <FieldTextbox val={item.attached_9.last_name} label="Apellido" field="attached_9" fieldInner="last_name" index={i} changeEvent={changeField} review={item.attached_9.last_name==""?true:false} />
                                        <FieldTextbox val={item.attached_9.nif} label="NIF" field="attached_9" fieldInner="nif" index={i} changeEvent={changeField} review={item.attached_9.nif==""?true:false} />
                                    </div>
                                </div>}
                                {!Array.isArray(item.attached_10) && <div>
                                    <div className="import-third">
                                        <FieldTextbox val={item.attached_10.first_name} label="Nombre" field="attached_10" fieldInner="first_name" index={i} changeEvent={changeField} review={item.attached_10.first_name==""?true:false} />
                                        <FieldTextbox val={item.attached_10.last_name} label="Apellido" field="attached_10" fieldInner="last_name" index={i} changeEvent={changeField} review={item.attached_10.last_name==""?true:false} />
                                        <FieldTextbox val={item.attached_10.nif} label="NIF" field="attached_10" fieldInner="nif" index={i} changeEvent={changeField} review={item.attached_10.nif==""?true:false} />
                                    </div>
                                </div>}
                                <div className="import-section">Archivos</div>
                                <div className="import-files">
                                    {item.sheet!="" ?
                                    <a href={globalContext["api_url"]+"temp/"+data.name+"/"+item.folder_name+"/"+item.sheet} target="_blank" className="import-file">
                                        <div><img src={ok} /></div>
                                        <div>Ficha</div>
                                    </a>
                                    :<div className="import-file import-file-alert">
                                        <div><img src={notOk} /></div>
                                        <div>Falta ficha</div>
                                    </div>}
                                    {item.powers.length>0?
                                    <Fragment>
                                        {item.powers.map((power,powerIndex)=>{
                                            return(
                                                <a className="import-file" key={powerIndex} href={globalContext["api_url"]+"temp/"+data.name+"/"+item.folder_name+"/"+power} target="_blank">
                                                    <div><img src={ok} /></div>
                                                    <div>Poder {powerIndex+1}</div>
                                                </a>
                                            )
                                        })}
                                    </Fragment>
                                    :<div className="import-file import-file-alert">
                                        <div><img src={notOk} /></div>
                                        <div>Falta poder</div>
                                    </div>}
                                    {item.operations.length>0?
                                    <Fragment>
                                        {item.operations.map((operation,operationIndex)=>{
                                            return(
                                                <a className="import-file" key={operationIndex} href={globalContext["api_url"]+"temp/"+data.name+"/"+item.folder_name+"/"+operation} target="_blank">
                                                    <div><img src={ok} /></div>
                                                    <div>Operaciones {operationIndex+1}</div>
                                                </a>
                                            )
                                        })}
                                    </Fragment>
                                    :<div className="import-file import-file-alert">
                                        <div><img src={notOk} /></div>
                                        <div>Faltan comprobantes</div>
                                    </div>}
                                </div>
                                <div className="import-section">Operaciones ({item.records.length})</div>
                                <div className="import-records">
                                    {item.records.map((record,index)=>{
                                        return(
                                            <div key={index} className="import-record">
                                                <FieldTextbox val={record.date_formatted} label="" theme="alt" field="records" fieldInner="date_formatted" index={i} indexInner={index} changeEvent={changeField} review={(record.date<946695600 || record.date>1735700400)?true:false} />
                                                <FieldSelect val={record.operation_type} options={operationTypeOptions} label="" theme="alt" field="records" fieldInner="operation_type" index={i} indexInner={index} changeEvent={changeField} review={record.operation_type==0?true:false} />
                                                <FieldTextbox val={record.quantity} label="" theme="alt" type="number" align="right" field="records" fieldInner="quantity" index={i} indexInner={index} changeEvent={changeField} review={(record.quantity<0 || record.quantity==="")?true:false} />
                                                <FieldTextbox val={record.amount} label="" theme="alt" type="number" align="right" field="records" fieldInner="amount" index={i} indexInner={index} changeEvent={changeField} review={(record.amount<0 || record.amount==="")?true:false} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </ImportBlock>
                        )
                    })}
                </div>}
            </BoardContent>
        </Board>
    )
}