import {useContext} from "react"
import ViewerContext from "../contexts/ViewerContext"

export default function useViewer(){
    const [viewerContext,setViewerContext]=useContext(ViewerContext)
    let viewerTimer
    const changeViewer=(file,extension)=>{
        let viewerContextNew={...viewerContext}
        viewerContextNew["file"]=file
        viewerContextNew["extension"]=extension
        setViewerContext(viewerContextNew)
    }
    const manageViewer=(action)=>{
        if(viewerTimer){
            clearTimeout(viewerTimer)
        }
        if(action=="show"){
            document.getElementById("viewer").style.display="block"
            viewerTimer=setTimeout(()=>{
                document.getElementById("viewer").classList.add("viewer-show")
            },50)
        }else if(action=="hide"){
            document.getElementById("viewer").classList.remove("viewer-show")
            viewerTimer=setTimeout(()=>{
                document.getElementById("viewer").style.display="none"
                let viewerContextNew={...viewerContext}
                viewerContextNew["file"]=""
                viewerContextNew["extension"]=""
                setViewerContext(viewerContextNew)
            },250)
        }
    }
    return {manageViewer,changeViewer}
}