import {useState,useEffect,useRef,Fragment} from "react"
import {formatAmount} from "../hooks/Commons"
import useApi from "../hooks/useApi"
import useBoard from "../hooks/useBoard"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import useViewer from "../hooks/useViewer"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import DataBlock from "../components/DataBlock"
import DataRows from "../components/DataRows"
import DataList from "../components/DataList"
import DataListItem from "../components/DataListItem"
import ButtonSmall from "../commons/ButtonSmall"
import DataRecord from "../components/DataRecord"
import DataCalculate from "../components/DataCalculate"
import DataFile from "../components/DataFile"
import ItemListEdit from "../commons/ItemListEdit"
import Textbox from "../commons/Textbox"
import Numbox from "../commons/Numbox"
import Switch from "../commons/Switch"
import Selector from "../commons/Selector"
import Uploader from "../commons/Uploader"

export default function ClientsData({params}){

    const section="clients-data"
    const requestApi=useApi()
    const [ready,setReady]=useState(0)
    const {loadBoard,closeBoard}=useBoard()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {changeViewer}=useViewer()
    const {refresh}=useRefresh()
    const [data,setData]=useState("")

    const loadData=()=>{
        requestApi("clients/info",{
                reference:params.reference
            })
            .then(data=>{
                setData(data.data)
                setReady(1)
            })
    }

    useEffect(()=>{
        loadData()
    },[])

    useEffect(()=>{
        loadData()
        setSubclientEditMode(0)
        setSubclientEditIndex("")
        setOperationEditMode(0)
        setOperationEditIndex("")
        setFileEditMode(0)
    },[params.reference])

    //Subclients

    const [subclientEditMode,setSubclientEditMode]=useState(0)
    const [subclientEditIndex,setSubclientEditIndex]=useState("")
    const subclientFirstNameInput=useRef()
    const [subclientFirstNameVal,setSubclientFirstNameVal]=useState("")
    const subclientLastNameInput=useRef()
    const [subclientLastNameVal,setSubclientLastNameVal]=useState("")
    const subclientNifInput=useRef()
    const [subclientNifVal,setSubclientNifVal]=useState("")
    const [subclientStatusVal,setSubclientStatusVal]=useState(1)

    const addSubclient=()=>{
        setSubclientFirstNameVal("")
        setSubclientLastNameVal("")
        setSubclientNifVal("")
        setSubclientStatusVal(1)
        setSubclientEditMode(1)
        setSubclientEditIndex("")
    }

    const editSubclient=(i)=>{
        setSubclientEditMode(1)
        setSubclientEditIndex(i)
        setTimeout(()=>{
            setSubclientFirstNameVal(data.subclients[i]["first_name"])
            setSubclientLastNameVal(data.subclients[i]["last_name"])
            setSubclientNifVal(data.subclients[i]["nif"])
            setSubclientStatusVal(data.subclients[i]["status"])
        },100)
    }

    const cancelSubclient=()=>{
        setSubclientFirstNameVal("")
        setSubclientLastNameVal("")
        setSubclientNifVal("")
        setSubclientStatusVal(1)
        setSubclientEditMode(0)
        setSubclientEditIndex("")
    }

    const saveSubclient=()=>{
        if(subclientFirstNameVal==""){
            subclientFirstNameInput.current.focus()
        }else{
            if(subclientLastNameVal==""){
                subclientLastNameInput.current.focus()
            }else{
                const newSubclients=[...data.subclients]
                if(subclientEditIndex===""){
                    let newSubclient={
                        id:0,
                        first_name:subclientFirstNameVal,
                        last_name:subclientLastNameVal,
                        nif:subclientNifVal,
                        status:subclientStatusVal
                    }
                    newSubclients.push(newSubclient)
                }else{
                    newSubclients[subclientEditIndex]["first_name"]=subclientFirstNameVal
                    newSubclients[subclientEditIndex]["last_name"]=subclientLastNameVal
                    newSubclients[subclientEditIndex]["nif"]=subclientNifVal
                    newSubclients[subclientEditIndex]["status"]=subclientStatusVal
                }
                setData({...data,subclients:newSubclients})
                setSubclientEditMode(0)
                setSubclientEditIndex("")
            }
        }
    }

    const removeSubclient=(i)=>{
        const newSubclients=[...data.subclients]
        newSubclients.splice(i,1)
        setData({...data,subclients:newSubclients})
    }

    //Operations

    const [operationEditMode,setOperationEditMode]=useState(0)
    const [operationEditIndex,setOperationEditIndex]=useState("")
    const operationMomentInput=useRef()
    const [operationMomentVal,setOperationMomentVal]=useState("")
    const operationTypeInput=useRef()
    const operationTypeOptions=[
        {label:"Compra de acciones",value:5},
        {label:"Compra de derechos",value:6},
        {label:"Ampliación de capital",value:2},
        {label:"Venta de acciones",value:7},
        {label:"Venta de derechos",value:8},
        {label:"Alta por canje",value:1},
        {label:"Baja por canje",value:4},
        {label:"Baja por amortización",value:3},
        {label:"Compra de bonos",value:10},
        {label:"Venta de bonos",value:11}
    ]
    const [operationTypeVal,setOperationTypeVal]=useState("")
    const operationQuantityInput=useRef()
    const [operationQuantityVal,setOperationQuantityVal]=useState("")
    const operationAmountInput=useRef()
    const [operationAmountVal,setOperationAmountVal]=useState("")

    const addOperation=()=>{
        setOperationMomentVal("")
        setOperationTypeVal("")
        setOperationQuantityVal("")
        setOperationAmountVal("")
        setOperationEditMode(1)
        setOperationEditIndex("")
    }

    const editOperation=(i)=>{
        setOperationEditMode(1)
        setOperationEditIndex(i)
        setTimeout(()=>{
            setOperationMomentVal(data.operations[i]["moment"])
            operationTypeInput.current.setValue(operationTypeOptions.find(o=>o.value===data.operations[i]["type"]))
            setOperationQuantityVal(data.operations[i]["quantity"])
            setOperationAmountVal(data.operations[i]["amount"])
        },100)
    }

    const cancelOperation=()=>{
        setOperationMomentVal("")
        setOperationTypeVal("")
        setOperationQuantityVal("")
        setOperationAmountVal("")
        setOperationEditMode(0)
        setOperationEditIndex("")
    }

    const saveOperation=()=>{
        if(operationMomentVal==""){
            operationMomentInput.current.focus()
        }else{
            if(operationTypeInput.current.getValue().length==0){
                operationTypeInput.current.focus()
            }else{
                if(operationQuantityVal==""){
                    operationQuantityInput.current.focus()
                }else{
                    if(operationAmountVal==""){
                        operationAmountInput.current.focus()
                    }else{
                        const newOperations=[...data.operations]
                        if(operationEditIndex===""){
                            let newOperation={
                                id:0,
                                moment:operationMomentVal,
                                type:operationTypeInput.current.getValue()[0].value,
                                detail:operationTypeInput.current.getValue()[0].label,
                                quantity:operationQuantityVal,
                                amount:operationAmountVal
                            }
                            newOperations.push(newOperation)
                        }else{
                            newOperations[operationEditIndex]["moment"]=operationMomentVal
                            newOperations[operationEditIndex]["type"]=operationTypeInput.current.getValue()[0].value
                            newOperations[operationEditIndex]["detail"]=operationTypeInput.current.getValue()[0].label
                            newOperations[operationEditIndex]["quantity"]=operationQuantityVal
                            newOperations[operationEditIndex]["amount"]=operationAmountVal
                        }
                        setData({...data,operations:newOperations})
                        setOperationEditMode(0)
                        setOperationEditIndex("")
                    }
                }
            }
        }
    }

    const removeOperation=(i)=>{
        const newOperations=[...data.operations]
        newOperations.splice(i,1)
        setData({...data,operations:newOperations})
    }

    //Files
    
    const [fileEditMode,setFileEditMode]=useState(0)
    const fileClassInput=useRef()
    const fileClassOptions=[
        {label:"Ficha general",value:"sheet"},
        {label:"Poder para pleitos",value:"power"},
        {label:"Comprobantes de operaciones",value:"operations"},
        {label:"Complementario",value:"complement"}
    ]
    const [fileClassVal,setFileClassVal]=useState("")
    const fileInput=useRef()
    const [fileVal,setFileVal]=useState()

    const addFile=()=>{
        setFileClassVal("")
        setFileVal("")
        setFileEditMode(1)
    }

    const cancelFile=()=>{
        setFileClassVal("")
        setFileVal("")
        setFileEditMode(0)
    }

    const saveFile=()=>{
        if(fileClassInput.current.getValue().length==0){
            fileClassInput.current.focus()
        }else{
            if(fileVal==""){

            }else{
                const newFiles=[...data.files]
                let newFile={
                    id:0,
                    client:data["id"],
                    name:fileVal["name"],
                    original_name:fileVal["original_name"],
                    context:fileVal["context"],
                    title:fileVal["title"],
                    class:fileClassInput.current.getValue()[0].value,
                    extension:fileVal["extension"],
                    url:fileVal["url"]
                }
                newFiles.push(newFile)
                setData({...data,files:newFiles})
                setFileEditMode(0)
            }
        }
    }

    const previewFile=(i)=>{
        changeViewer(data.files[i].url,data.files[i].extension)
    }

    const downloadFile=(i)=>{
        let link=document.createElement("a")
        link.setAttribute("download","")
        link.href=data.files[i].url
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    const removeFile=(i)=>{
        if(data.files[i]["context"]=="temp"){
            console.log(data.files[i])
            requestApi("clients/file_remove",{
                    context:"temp",
                    file:data.files[i]
                })
                .then(data=>{})
        }
        const newFiles=[...data.files]
        newFiles.splice(i,1)
        setData({...data,files:newFiles})
    }

    const saveData=()=>{
        requestApi("clients/save_data",{
                id:data["id"],
                subclients:data["subclients"],
                operations:data["operations"],
                files:data["files"]
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("saved","")
                    refresh({board:"clients",close:"clients-data"})
                    setTimeout(()=>{
                        loadBoard({params:{alias:"clients-data",level:2,theme:"rabbit2",reference:params.reference}})
                    },100)
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    const getPackage=()=>{
        requestApi("clients/package",{
                reference:data["id"],
                context:"client"
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("package_generated","")
                    let link=document.createElement("a")
                    link.setAttribute("download","")
                    link.href=data.file
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    const getAssembly=()=>{
        requestApi("clients/assembly",{
                id:data["id"]
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("assembly_generated","")
                    let link=document.createElement("a")
                    link.setAttribute("download","")
                    link.setAttribute("target","_blank")
                    link.href=data.file
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft={
                    <Fragment>
                        {params.context!="collection" && 
                        <Fragment>
                            <ButtonAction label="Guardar" icon="wink" clickEvent={saveData} />
                            <ButtonAction label="Paquete" icon="folder" style="alt" clickEvent={getPackage} />
                            <ButtonAction label="Ensamblar" icon="assembly" style="alt" clickEvent={getAssembly} />
                        </Fragment>}
                        {params.context=="collection" && 
                        <Fragment>
                            <ButtonAction label="Cerrar" icon="" style="alt" clickEvent={()=>{closeBoard(params.alias)}} />
                        </Fragment>}
                    </Fragment>
                }
                actionsRight={
                    <Fragment>
                        {params.context!="collection" &&
                        <ButtonAction label="Editar" icon="edit" clickEvent={()=>{loadBoard({params:{alias:"clients-edit",level:2,theme:"rabbit",reference:params.reference,mode:"edit"}})}} />}
                    </Fragment>
                } />
            <BoardContent>
                {data &&
                <div className="board-data board-data-xl">
                    <DataBlock title="Datos personales" style="bluish">
                        <DataRows items={[
                            {label:"Nombres",content:data.first_name?data.first_name:"‒",style:"bold"},
                            {label:"Apellidos",content:data.last_name?data.last_name:"‒",style:"bold"},
                            {label:"NIF",content:data.nif?data.nif:"‒",style:"bold"},
                            {label:"Bufete",content:data.bufete_name?data.bufete_name:"‒",style:""},
                            {label:"N° Cliente",content:data.client_id?data.client_id:"‒",style:""},
                            {label:"Estado",content:data.status==1?"Activo":"Inactivo",style:""}
                        ]} />
                    </DataBlock>
                    <DataBlock title="Personas asociadas" style="white">
                        <DataList>
                            {data.subclients.map((item,i)=>{
                                return(
                                    <DataListItem key={i} status={item.status}
                                        left={
                                            <Fragment>
                                                <div className="data-medium data-colored">{item.last_name?item.last_name:"‒"}, {item.first_name?item.first_name:"‒"}</div>
                                                <div>NIF <span className="data-medium">{item.nif?item.nif:"‒"}</span> ({item.status==1?"Activa":"Inactiva"})</div>
                                            </Fragment>
                                        }
                                        right={
                                            <div className="data-actions">
                                                <ButtonSmall icon="edit" clickEvent={()=>{editSubclient(i)}} />
                                                <ButtonSmall icon="remove" clickEvent={()=>{removeSubclient(i)}} />
                                            </div>
                                        }
                                    />
                                )
                            })}
                            {subclientEditMode==1 &&
                            <DataListItem left={
                                <ItemListEdit>
                                    <div className="item-list-edit-half">
                                        <Textbox ref={subclientFirstNameInput} type="text" appearance="compact" label="Nombres" val={subclientFirstNameVal} setVal={setSubclientFirstNameVal} disabled={false} />
                                        <Textbox ref={subclientLastNameInput} type="text" appearance="compact" label="Apellidos" val={subclientLastNameVal} setVal={setSubclientLastNameVal} disabled={false} />
                                    </div>
                                    <div className="item-list-edit-half">
                                        <Textbox ref={subclientNifInput} type="text" appearance="compact" label="NIF" val={subclientNifVal} setVal={setSubclientNifVal} disabled={false} />
                                        <Switch onLabel="Activa" offLabel="Inactiva" style="compact" val={[subclientStatusVal,setSubclientStatusVal]} clickEvent="" />
                                    </div>
                                </ItemListEdit>
                            } right={
                                <div className="item-list-edit-actions">
                                    <div>
                                        <ButtonSmall icon="cancel" clickEvent={()=>{cancelSubclient()}} />
                                        <ButtonSmall icon="confirm" style="confirm" clickEvent={()=>{saveSubclient()}} />
                                    </div>
                                </div>
                            } />}
                            <DataListItem left={<ButtonSmall icon="add" style="alt" clickEvent={()=>{addSubclient()}} />} right={""} />
                        </DataList>
                    </DataBlock>
                    <DataBlock title="Resumen de operaciones" style="bordered">
                        <DataRows context="summary" items={data.summary} />
                    </DataBlock>
                    <DataBlock title="Operaciones" style="white">
                        <DataList>
                            <DataRecord header={true} />
                            {data.operations.map((item,i)=>{
                                return(
                                    <DataListItem key={i} left={<DataRecord item={item} index={i} editEvent={editOperation} removeEvent={removeOperation} />} right={""} />
                                )
                            })}
                            {operationEditMode==1 &&
                            <DataListItem left={
                                <ItemListEdit>
                                    <div className="item-list-edit-half">
                                        <Textbox ref={operationMomentInput} type="date" appearance="compact" label="Fecha" val={operationMomentVal} setVal={setOperationMomentVal} disabled={false} />
                                        <Selector ref={operationTypeInput} appearance="compact" options={operationTypeOptions} label="Operación" placeholder="" multiple={false} searchable={false} style="field" val={operationTypeVal} setVal={setOperationTypeVal} />
                                    </div>
                                    <div className="item-list-edit-half">
                                        <Textbox ref={operationQuantityInput} appearance="compact" type="number" label="Cantidad" val={operationQuantityVal} setVal={setOperationQuantityVal} disabled={false} />
                                        <Numbox ref={operationAmountInput} appearance="compact" label="Valor" val={operationAmountVal} setVal={setOperationAmountVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={""} suffix={""} placeholder="" />
                                    </div>
                                </ItemListEdit>
                            } right={
                                <div className="item-list-edit-actions">
                                    <div>
                                        <ButtonSmall icon="cancel" clickEvent={()=>{cancelOperation()}} />
                                        <ButtonSmall icon="confirm" style="confirm" clickEvent={()=>{saveOperation()}} />
                                    </div>
                                </div>
                            } />}
                            <DataListItem left={<ButtonSmall icon="add" style="alt" clickEvent={()=>{addOperation()}} />} right={""} />
                        </DataList>
                    </DataBlock>
                    <DataBlock title="" style="filled">
                        <div className="data-title-big data-colored">Monto reclamable</div>
                        <div className="data-title-medium data-colored">{formatAmount(data.claimable,"EUR","display")}</div>
                    </DataBlock>
                    <DataBlock title="Cálculo de reclamaciones" style="white">
                        <div className="data-table">
                            <DataCalculate header={true} />
                            {data.calculate.records.map((item,i)=>{
                                return(
                                    <DataCalculate key={i} item={item} />
                                )
                            })}
                        </div>
                    </DataBlock>
                    <DataBlock title="Documentos" style="bordered">
                        <DataList>
                            {data.files.map((item,i)=>{
                                return(
                                    <DataListItem key={i} left={<DataFile item={item} index={i} previewEvent={previewFile} downloadEvent={downloadFile} removeEvent={removeFile} />} right={""} />
                                )
                            })}
                            {fileEditMode==1 &&
                            <DataListItem left={
                                <ItemListEdit>
                                    <div className="item-list-edit-full">
                                        <Selector ref={fileClassInput} appearance="compact" options={fileClassOptions} label="Tipo de documento" placeholder="" multiple={false} searchable={false} style="field" val={fileClassVal} setVal={setFileClassVal} />
                                        <Uploader ref={fileInput} context="client-files" fileClass={fileClassVal} val={fileVal} setVal={setFileVal} label="Subir documento" labelUploading="Subiendo documento" restrict={["application/vnd.openxmlformats-officedocument.wordprocessingml.document","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/vnd.openxmlformats-officedocument.presentationml.presentation","application/pdf","application/zip","image/jpeg","image/x-png","image/png","image/gif","image/tiff","image/webp","application/x-rar-compressed","text/plain"]} multiple={false} viewer={true} />
                                    </div>
                                </ItemListEdit>
                            } right={
                                <div className="item-list-edit-actions">
                                    <div>
                                        <ButtonSmall icon="cancel" clickEvent={()=>{cancelFile()}} />
                                        <ButtonSmall icon="confirm" style="confirm" clickEvent={()=>{saveFile()}} />
                                    </div>
                                </div>
                            } />}
                            <DataListItem left={<ButtonSmall icon="add" style="alt" clickEvent={()=>{addFile()}} />} right={""} />
                        </DataList>
                    </DataBlock>
                </div>}
            </BoardContent>
        </Board>
    )
}