import {Fragment} from "react"
import {formatAmount} from "../hooks/Commons"
import useApi from "../hooks/useApi"
import useBoard from "../hooks/useBoard"
import useDialog from "../hooks/useDialog"
import ButtonSmall from "../commons/ButtonSmall"

export default function DataClient({context,item,index,excludeEvent}){
    
    const requestApi=useApi()
    const {loadBoard}=useBoard()
    const {changeDialog}=useDialog()

    const openClient=(client)=>{
        loadBoard({params:{alias:"clients-data",level:3,theme:"rabbit2",reference:client,context:"collection"}})
    }

    const getPackage=(client)=>{
        requestApi("clients/package",{
                reference:client,
                context:"client"
            })
            .then(data=>{
                if(data.status=="success"){
                    let link=document.createElement("a")
                    link.setAttribute("download","")
                    link.href=data.file
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <div className={"data-client"+(item.status==0?" data-client-off":"")+(item.excluded==1?" data-client-excluded":"")}>
            <div className="data-client-name">
                <div className="data-client-title data-medium">{item.last_name}, {item.first_name}</div>
                {context=="collection" &&
                <Fragment>
                    <div className="data-colored data-small">Reclamable: <span className="data-medium">{formatAmount(item.claimable,"EUR","display")} <span className="data-transparent">({formatAmount(item.claimable_global,"EUR","display")})</span></span></div>                                  
                </Fragment>}
                {context=="imports" &&
                <div className="data-colored data-small"><span className="data-medium">{item.nif!=""?item.nif:"‒"}</span> (<span>{item.client_id!=""?item.client_id:"‒"}</span>)</div>}
            </div>
            <div className="data-client-actions">
                {context=="collection" &&
                <Fragment>
                    <ButtonSmall icon="preview" clickEvent={()=>{openClient(item.id)}} />
                    <ButtonSmall icon="exclude" clickEvent={()=>{excludeEvent(index)}} />
                </Fragment>}
            </div>
        </div>
    )
}