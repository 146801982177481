import {useState,useRef,useEffect} from "react"
import useApi from "../hooks/useApi"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import Divisor from "../commons/Divisor"
import Textbox from "../commons/Textbox"
import Switch from "../commons/Switch"
import ButtonCommand from "../commons/ButtonCommand"

export default function BufetesEdit({params}){

    const requestApi=useApi()
    const [ready,setReady]=useState(0)
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const mode=params.mode
    const [idVal,setIdVal]=useState(0)
    const [defaultData,setDefaultData]=useState("")

    const nameInput=useRef()
    const websiteInput=useRef()
    const [nameVal,setNameVal]=useState()
    const [websiteVal,setWebsiteVal]=useState()
    const [clientsVal,setClientsVal]=useState(0)
    const [statusVal,setStatusVal]=useState(1)

    const loadData=()=>{
        setReady(0)
        if(mode=="edit"){
            requestApi("bufetes/info",{
                    reference:params.reference
                })
                .then(data=>{
                    setReady(1)
                    setDefaultData(data.data)
                })
        }else{
            setDefaultData("")
            setReady(1)
        }
    }

    useEffect(()=>{
        loadData()
    },[params.reference])

    useEffect(()=>{
        if(mode=="edit"){
            setIdVal(defaultData.id)
            setNameVal(defaultData.name)
            setWebsiteVal(defaultData.website)
            setClientsVal(defaultData.clients)
            setStatusVal(defaultData.status)
        }else{
            setIdVal(0)
            setNameVal("")
            setWebsiteVal("")
            setStatusVal(1)
        }
    },[defaultData])

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            requestApi("bufetes/save",{
                    id:idVal,
                    mode:mode,
                    name:nameVal,
                    website:websiteVal,
                    status:statusVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        if(mode=="create"){
                            changeToast("created_m","")
                        }else if(mode=="edit"){
                            changeToast("saved","")
                        }
                        refresh({board:"bufetes",close:"bufetes-edit"})
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const remove=()=>{
        requestApi("bufetes/remove",{
                reference:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    refresh({board:"bufetes",close:"bufetes-edit"})
                }else{
                    changeToast("generic_error","")
                }
            })
    }
    
    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft={""}
                actionsRight={<ButtonAction label="Guardar" icon="wink" clickEvent={saveData} />} />
            <BoardContent>
                <div className="board-data">
                    <Textbox ref={nameInput} type="text" label="Nombre" val={nameVal} setVal={setNameVal} disabled={false} />
                    <Textbox ref={websiteInput} type="text" label="Sitio web" val={websiteVal} setVal={setWebsiteVal} disabled={false} />
                    <Divisor />
                    <Switch onLabel="Activo" offLabel="Inactivo" val={[statusVal,setStatusVal]} clickEvent="" />
                    {mode=="edit" &&
                    <div className="commands-block">
                        <ButtonCommand label="Eliminar" icon="remove" clickEvent={remove} blocked={clientsVal>0?true:false} />
                    </div>}                
                </div>
            </BoardContent>
        </Board>
    )
}