export default function FieldSelect({label,val,options,field,fieldInner,index,indexInner,type,theme,review,changeEvent}){
    return(
        <div className="import-field-block">
            {label!="" && <div className="import-field-label">{label}</div>}
            <select className={"import-field-textbox import-field-textbox-select"+(theme=="alt"?" import-field-textbox-alt":"")+(review==true?" import-field-review":"")} value={val} onChange={(value)=>{changeEvent(value,index,field,fieldInner,indexInner,type)}} >
                {options.map((option,i)=>{
                    return(
                        <option key={i} value={option.value}>{option.label}</option>
                    )
                })}
            </select>
        </div>
    )
}