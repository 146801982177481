import {useEffect,useContext,Fragment} from "react"
import DialogContext from "../contexts/DialogContext"
import useDialog from "../hooks/useDialog"
import ButtonDialogPrimary from "../commons/ButtonDialogPrimary"
import ButtonDialogSecondary from "../commons/ButtonDialogSecondary"

export default function Dialog(){
    const [dialogContext]=useContext(DialogContext)
    const {manageDialog}=useDialog()
    useEffect(()=>{
        if(dialogContext.dialog!=""){
            manageDialog("show")
        }
    },[dialogContext])
    return(
        <div id="dialog" className="dialog noselect">
            <div className="dialog-overlay"></div>
            <div className="dialog-frame">
                <div className="dialog-window">
                    <div className="dialog-message">
                        {dialogContext.dialog=="generic_error" &&
                        <div><strong>Algo no anduvo bien.</strong> Intenta nuevamente en unos minutos.</div>}
                        {dialogContext.dialog=="user_inactive" &&
                        <div><strong>La cuenta está inactiva.</strong> Para conocer más detalles, ponte en contacto con Ius+Aequitas.</div>}
                        {dialogContext.dialog=="user_blocked" &&
                        <div><strong>La cuenta está bloqueada.</strong> Para conocer más detalles, ponte en contacto con Ius+Aequitas.</div>}
                        {dialogContext.dialog=="user_not_exists" &&
                        <div><strong>Ninguna cuenta está asociada a ese correo.</strong> Si no recuerdas o no conoces los datos de acceso, ponte en contacto con Ius+Aequitas.</div>}
                        {dialogContext.dialog=="user_not_authorized" &&
                        <div><strong>El acceso no ha sido autorizado.</strong> Para conocer más detalles, ponte en contacto con Ius+Aequitas.</div>}
                        {dialogContext.dialog=="user_wrong_password" &&
                        <div><strong>La contraseña es incorrecta.</strong> Revísala para poder continuar.</div>}
                        {dialogContext.dialog=="password_mismatch" &&
                        <div><strong>Las dos contraseñas no coinciden.</strong> Revísalas para poder continuar.</div>}
                        {dialogContext.dialog=="password_length" &&
                        <div><strong>La contraseña debe tener al menos 7 caracteres.</strong> Revísala para poder continuar.</div>}
                        {dialogContext.dialog=="email_exists" &&
                        <div><strong>El email ya está asignado a un usuario.</strong> Elige otro para poder continuar.</div>}
                        {dialogContext.dialog=="not_account" &&
                        <div><strong>Acceso restringido.</strong> Si aún no posees una cuenta o tienes problemas para acceder, ponte en contacto con el equipo de <strong>Ius+Aequitas</strong>.</div>}
                        {dialogContext.dialog=="terminate_session" &&
                        <div><strong>Se cerrará la sesión activa en ese dispositivo.</strong> ¿Seguro quieres continuar?</div>}
                        {dialogContext.dialog=="terminate_this_session" &&
                        <div><strong>Vas a salir de Ius+Aequitas en este dispositivo.</strong> ¿Seguro quieres continuar?</div>}
                        {dialogContext.dialog=="remove" &&
                        <div><strong>Se va a eliminar este elemento y no podrá recuperarse luego.</strong> ¿Seguro quieres continuar?</div>}
                        {dialogContext.dialog=="remove_blocked" &&
                        <div><strong>No es posible eliminar este bufete.</strong> Para habilitar la eliminación no deben haber clientes asociados.</div>}
                        {dialogContext.dialog=="discard_import" &&
                        <div><strong>Al descartar esta importación los datos no se guardarán en forma definitiva.</strong> ¿Seguro quieres continuar?</div>}
                        {dialogContext.dialog=="undo_import" &&
                        <div><strong>Al deshacer esta importación se borrarán todos los clientes importados, incluyendo personas asociadas, operaciones y archivos.</strong> ¿Seguro quieres continuar?</div>}
                    </div>
                    <div className="dialog-actions">
                        {(dialogContext.dialog=="generic_error" || dialogContext.dialog=="user_inactive" || dialogContext.dialog=="user_blocked" || dialogContext.dialog=="user_not_exists" || dialogContext.dialog=="user_not_authorized" || dialogContext.dialog=="password_mismatch" || dialogContext.dialog=="password_length" || dialogContext.dialog=="email_exists" || dialogContext.dialog=="not_account" || dialogContext.dialog=="remove_blocked") && <Fragment>
                        <ButtonDialogPrimary label="Lo entiendo" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                        {dialogContext.dialog=="user_wrong_password" && <Fragment>
                        <ButtonDialogPrimary label="Reintentar" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                        {(dialogContext.dialog=="terminate_session" || dialogContext.dialog=="terminate_this_session") && <Fragment>
                        <ButtonDialogPrimary label="Cerrar sesión" clickEvent={dialogContext.action} />
                        <ButtonDialogSecondary label="Cancelar" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                        {dialogContext.dialog=="remove" && <Fragment>
                        <ButtonDialogPrimary label="Sí, eliminar" clickEvent={dialogContext.action} />
                        <ButtonDialogSecondary label="Cancelar" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                        {dialogContext.dialog=="discard_import" && <Fragment>
                        <ButtonDialogPrimary label="Sí, descartar" clickEvent={dialogContext.action} />
                        <ButtonDialogSecondary label="Cancelar" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                        {dialogContext.dialog=="undo_import" && <Fragment>
                        <ButtonDialogPrimary label="Sí, deshacer" clickEvent={dialogContext.action} />
                        <ButtonDialogSecondary label="Cancelar" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                    </div>
                </div>
            </div>
        </div>
    )
}