import {Fragment} from "react"

export default function Board({params,ready,children}){

    let zindex=7;
    zindex=zindex-params.level

    return(
        <div className={"board"+(params.theme!=""?" "+params.theme:"")} style={{zIndex:zindex}}>
            {ready==1?
            <Fragment>{children}</Fragment>
            :<div className="board-loading"></div>}
        </div>
    )
}