import {useState,Fragment} from "react"
import useBoard from "../hooks/useBoard"
import {humanDate} from "../hooks/Commons"
import {format} from "date-fns"
import ItemCheckbox from "../commons/ItemCheckbox"

export default function Item({item,section,on,selection,clickEvent}){

    const {loadBoard,onBoardItem}=useBoard()
    const [selected,setSelected]=useState(0)

    const selectItem=(item)=>{
        if(selected==0){
            setSelected(1)
        }else{
            setSelected(0)
        }
    }

    if(!clickEvent){
        if(section=="main"){
            clickEvent=()=>{
                onBoardItem("main",item.id)
                loadBoard({params:{alias:item.alias,level:1,theme:item.alias=="export"?"rabbit":"carbon"}})
            }
        }else if(section=="clients"){
            clickEvent=()=>{
                onBoardItem("clients",item.id)
                loadBoard({params:{alias:"clients-data",level:2,theme:"rabbit2",reference:item.id}})
            }
        }else if(section=="collections"){
            clickEvent=()=>{
                onBoardItem("collections",item.id)
                loadBoard({params:{alias:"collections-data",level:2,theme:"rabbit2",reference:item.id}})
            }
        }else if(section=="models"){
            clickEvent=()=>{
                onBoardItem("models",item.id)
                loadBoard({params:{alias:"models-edit",level:2,theme:"rabbit",reference:item.id,mode:"edit"}})
            }
        }else if(section=="imports"){
            clickEvent=()=>{
                onBoardItem("imports",item.id)
                loadBoard({params:{alias:"imports-data",level:2,theme:"rabbit",reference:item.id}})
            }
        }else if(section=="bufetes"){
            clickEvent=()=>{
                onBoardItem("bufetes",item.id)
                loadBoard({params:{alias:"bufetes-edit",level:2,theme:"rabbit",reference:item.id,mode:"edit"}})
            }
        }else if(section=="persons"){
            clickEvent=()=>{
                onBoardItem("persons",item.id)
                loadBoard({params:{alias:"persons-edit",level:2,theme:"rabbit",reference:item.id,mode:"edit"}})
            }
        }else if(section=="assemblages"){
            clickEvent=()=>{
                onBoardItem("assemblages",item.id)
                loadBoard({params:{alias:"assemblages-data",level:2,theme:"rabbit",reference:item.id}})
            }
        }else if(section=="templates"){
            clickEvent=()=>{
                onBoardItem("templates",item.id)
                loadBoard({params:{alias:"templates-edit",level:2,theme:"rabbit",reference:item.id,mode:"edit"}})
            }
        }else if(section=="imports-persons"){
            clickEvent=()=>{
                onBoardItem("imports-persons",item.id)
                loadBoard({params:{alias:"imports-persons-data",level:2,theme:"rabbit",reference:item.id}})
            }
        }
    }

    return(
        <div className={"item"+(on==item.id?" item-on":"")+(selection.on==1 && selected==1?" item-selected":"")+(item.status==0?" item-off":"")} onClick={clickEvent}>

            {item.tags &&
            <div className="item-tags">
                {item.tags.includes("1") && <div className="item-tag item-tag-1"></div>}
                {item.tags.includes("2") && <div className="item-tag item-tag-2"></div>}
                {item.tags.includes("3") && <div className="item-tag item-tag-3"></div>}
                {item.tags.includes("4") && <div className="item-tag item-tag-4"></div>}
                {item.tags.includes("5") && <div className="item-tag item-tag-5"></div>}
                {item.tags.includes("6") && <div className="item-tag item-tag-6"></div>}
                {item.tags.includes("7") && <div className="item-tag item-tag-7"></div>}
            </div>}

            {section=="main" &&
            <div className={"item-label"+(selection.on==1?" item-label-selection":"")}>
                {item.label}
            </div>}

            {section=="clients" &&
            <ItemCheckbox shown={selection.on} clickEvent={()=>{selectItem(item)}} />}

            {section=="clients" &&
            <Fragment>
                <div className={"item-label"+(selection.on==1?" item-label-selection":"")}>
                    {item.last_name!=""?item.last_name:"‒"}<span className="item-label-light">, {item.first_name!=""?item.first_name:"‒"}</span>{item.subclients!=0 && <span className="item-label-quant">(+{item.subclients})</span>}
                </div>
            </Fragment>}

            {section=="collections" &&
            <Fragment>
                <div className="item-label">
                    {item.name!=""?item.name:"‒"}
                    <div className="item-caption item-featured">
                        {item.date_start!=""?format(new Date(item.date_start*1000),"dd/MM/yyyy"):"/"} ~ {item.date_end!=""?format(new Date(item.date_end*1000),"dd/MM/yyyy"):"/"}
                    </div>
                    {item.periods.length>0 &&
                    <div>
                        {item.periods.map((period,i)=>{
                            return(
                                <div key={i} className={"data-period-alt data-period-"+period}>{period}</div>
                            )
                        })}
                    </div>}
                </div>
                <div className="item-counter">{item.clients}</div>
            </Fragment>}

            {section=="models" &&
            <Fragment>
                <div className="item-label">{item.name!=""?item.name:"‒"}</div>
                <div className="item-counter">{item.models_generated}</div>
            </Fragment>}

            {section=="bufetes" &&
            <Fragment>
                <div className="item-label">{item.name!=""?item.name:"‒"}</div>
                <div className="item-counter">{item.clients}</div>
            </Fragment>}

            {section=="imports" &&
            <Fragment>
                <div className="item-label">
                    {item.name!=""?item.name:"‒"} <span className="item-label-light item-label-small">{humanDate(item.created)}</span>
                </div>
                <div className="item-counter">{item.clients}</div>
            </Fragment>}

            {section=="persons" &&
            <Fragment>
                <div className="item-label">
                    {item.last_name!=""?item.last_name:"‒"}<span className="item-label-light">, {item.first_name!=""?item.first_name:"‒"}</span>
                </div>
            </Fragment>}

            {section=="assemblages" &&
            <Fragment>
                <div className="item-label">
                    {item.name!=""?item.name:"‒"}
                </div>
                <div className="item-counter">{item.persons}</div>
            </Fragment>}

            {section=="templates" &&
            <Fragment>
                <div className="item-label">{item.name!=""?item.name:"‒"}</div>
                <div className="item-counter">{item.templates_generated}</div>
            </Fragment>}

            {section=="imports-persons" &&
            <Fragment>
                <div className="item-label">
                    {item.name!=""?item.name:"‒"} <span className="item-label-light item-label-small">{humanDate(item.created)}</span>
                </div>
                <div className="item-counter">{item.persons}</div>
            </Fragment>}

        </div>
    )
}