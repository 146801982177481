import {Fragment,useContext,useEffect} from "react"
import GlobalContext from "../contexts/GlobalContext"
import AppsContext from "../contexts/AppsContext"
import useBoard from "../hooks/useBoard"
import useSession from "../hooks/useSession"
import Loader from "../components/Loader"
import Main from "../boards/Main"
import Dialog from "../components/Dialog"
import Toast from "../components/Toast"
import AppSwitcher from "../components/AppSwitcher"
import Preview from "../components/Viewer"
import Clients from "../boards/Clients"
import ClientsEdit from "../boards/ClientsEdit"
import ClientsImport from "../boards/ClientsImport"
import ClientsData from "../boards/ClientsData"
import Collections from "../boards/Collections"
import CollectionsEdit from "../boards/CollectionsEdit"
import CollectionsData from "../boards/CollectionsData"
import Bufetes from "../boards/Bufetes"
import BufetesEdit from "../boards/BufetesEdit"
import Models from "../boards/Models"
import ModelsEdit from "../boards/ModelsEdit"
import ModelsGenerate from "../boards/ModelsGenerate"
import Imports from "../boards/Imports"
import ImportsEdit from "../boards/ImportsEdit"
import ImportsData from "../boards/ImportsData"
import Export from "../boards/Export"
import Persons from "../boards/Persons"
import PersonsEdit from "../boards/PersonsEdit"
import PersonsImport from "../boards/PersonsImport"
import Assemblages from "../boards/Assemblages"
import AssemblagesEdit from "../boards/AssemblagesEdit"
import AssemblagesData from "../boards/AssemblagesData"
import Templates from "../boards/Templates"
import TemplatesEdit from "../boards/TemplatesEdit"
import TemplatesGenerate from "../boards/TemplatesGenerate"
import ImportsPersons from "../boards/ImportsPersons"
import ImportsPersonsEdit from "../boards/ImportsPersonsEdit"
import ImportsPersonsData from "../boards/ImportsPersonsData"
import Help from "../components/Help"

export default function Dashboard(){

    const {validateSession}=useSession()
    useEffect(()=>{
        validateSession()
    },[])
    const [globalContext]=useContext(GlobalContext)
    const [appsContext]=useContext(AppsContext)
    let app=appsContext.current
    let boards=appsContext.boards
    let switcher=appsContext.switcher
    const {loadBoard}=useBoard()

    useEffect(()=>{
        if(app=="sanpop"){
            loadBoard({params:{alias:"main",level:0,theme:""}})
        }else if(app=="models"){
            loadBoard({params:{alias:"main",level:0,theme:"green"}})
        }
    },[app])

    return(
        <Fragment>
            <div className="layout noselect">
                {app=="sanpop" &&
                <Fragment>
                    {boards.some(el=>el.alias=="main") && <Main params={boards[boards.findIndex(el=>el.alias=="main")].params} />}
                    {boards.some(el=>el.alias=="clients") && <Clients params={boards[boards.findIndex(el=>el.alias=="clients")].params} />}
                    {boards.some(el=>el.alias=="clients-edit") && <ClientsEdit params={boards[boards.findIndex(el=>el.alias=="clients-edit")].params} />}
                    {boards.some(el=>el.alias=="clients-import") && <ClientsImport params={boards[boards.findIndex(el=>el.alias=="clients-import")].params} />}
                    {boards.some(el=>el.alias=="collections") && <Collections params={boards[boards.findIndex(el=>el.alias=="collections")].params} />}
                    {boards.some(el=>el.alias=="collections-edit") && <CollectionsEdit params={boards[boards.findIndex(el=>el.alias=="collections-edit")].params} />}
                    {boards.some(el=>el.alias=="collections-data") && <CollectionsData params={boards[boards.findIndex(el=>el.alias=="collections-data")].params} />}
                    {boards.some(el=>el.alias=="clients-data") && <ClientsData params={boards[boards.findIndex(el=>el.alias=="clients-data")].params} />}
                    {boards.some(el=>el.alias=="bufetes") && <Bufetes params={boards[boards.findIndex(el=>el.alias=="bufetes")].params} />}
                    {boards.some(el=>el.alias=="bufetes-edit") && <BufetesEdit params={boards[boards.findIndex(el=>el.alias=="bufetes-edit")].params} />}
                    {boards.some(el=>el.alias=="models") && <Models params={boards[boards.findIndex(el=>el.alias=="models")].params} />}
                    {boards.some(el=>el.alias=="models-edit") && <ModelsEdit params={boards[boards.findIndex(el=>el.alias=="models-edit")].params} />}
                    {boards.some(el=>el.alias=="models-generate") && <ModelsGenerate params={boards[boards.findIndex(el=>el.alias=="models-generate")].params} />}
                    {boards.some(el=>el.alias=="imports") && <Imports params={boards[boards.findIndex(el=>el.alias=="imports")].params} />}
                    {boards.some(el=>el.alias=="imports-edit") && <ImportsEdit params={boards[boards.findIndex(el=>el.alias=="imports-edit")].params} />}
                    {boards.some(el=>el.alias=="imports-data") && <ImportsData params={boards[boards.findIndex(el=>el.alias=="imports-data")].params} />}
                    {boards.some(el=>el.alias=="export") && <Export params={boards[boards.findIndex(el=>el.alias=="export")].params} />}
                </Fragment>}
                {app=="models" &&
                <Fragment>
                    {boards.some(el=>el.alias=="main") && <Main params={boards[boards.findIndex(el=>el.alias=="main")].params} />}
                    {boards.some(el=>el.alias=="persons") && <Persons params={boards[boards.findIndex(el=>el.alias=="persons")].params} />}
                    {boards.some(el=>el.alias=="persons-edit") && <PersonsEdit params={boards[boards.findIndex(el=>el.alias=="persons-edit")].params} />}
                    {boards.some(el=>el.alias=="persons-import") && <PersonsImport params={boards[boards.findIndex(el=>el.alias=="persons-import")].params} />}
                    {boards.some(el=>el.alias=="assemblages") && <Assemblages params={boards[boards.findIndex(el=>el.alias=="assemblages")].params} />}
                    {boards.some(el=>el.alias=="assemblages-edit") && <AssemblagesEdit params={boards[boards.findIndex(el=>el.alias=="assemblages-edit")].params} />}
                    {boards.some(el=>el.alias=="assemblages-data") && <AssemblagesData params={boards[boards.findIndex(el=>el.alias=="assemblages-data")].params} />}
                    {boards.some(el=>el.alias=="templates") && <Templates params={boards[boards.findIndex(el=>el.alias=="templates")].params} />}
                    {boards.some(el=>el.alias=="templates-edit") && <TemplatesEdit params={boards[boards.findIndex(el=>el.alias=="templates-edit")].params} />}
                    {boards.some(el=>el.alias=="templates-generate") && <TemplatesGenerate params={boards[boards.findIndex(el=>el.alias=="templates-generate")].params} />}
                    {boards.some(el=>el.alias=="imports-persons") && <ImportsPersons params={boards[boards.findIndex(el=>el.alias=="imports-persons")].params} />}
                    {boards.some(el=>el.alias=="imports-persons-edit") && <ImportsPersonsEdit params={boards[boards.findIndex(el=>el.alias=="imports-persons-edit")].params} />}
                    {boards.some(el=>el.alias=="imports-persons-data") && <ImportsPersonsData params={boards[boards.findIndex(el=>el.alias=="imports-persons-data")].params} />}
                </Fragment>}
            </div>
            <Loader />
            <Dialog />
            <Toast />
            <Preview />
            {globalContext.help==1 && <Help />}
            {switcher=="shown" && <AppSwitcher />}
        </Fragment>
    )
}