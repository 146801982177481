export default function ButtonSmall({icon,style,clickEvent}){
    return(
        <div className={"button-small"+(style?" button-small-"+style:"")} onClick={clickEvent}>
            {icon=="add" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.09 24.09"><g id="c"><path d="m20.57,3.52h0C15.87-1.17,8.22-1.17,3.52,3.52-1.17,8.22-1.17,15.87,3.52,20.56c2.35,2.35,5.44,3.52,8.52,3.52s6.17-1.17,8.52-3.52c4.7-4.7,4.7-12.34,0-17.04Zm-1.49,15.56c-3.88,3.88-10.19,3.88-14.07,0s-3.88-10.19,0-14.07c1.94-1.94,4.49-2.91,7.04-2.91s5.1.97,7.04,2.91c3.88,3.88,3.88,10.19,0,14.07Zm-.8-7.04c0,.58-.47,1.05-1.05,1.05h-4.14v4.14c0,.58-.47,1.05-1.05,1.05s-1.05-.47-1.05-1.05v-4.14h-4.14c-.58,0-1.05-.47-1.05-1.05s.47-1.05,1.05-1.05h4.14v-4.14c0-.58.47-1.05,1.05-1.05s1.05.47,1.05,1.05v4.14h4.14c.58,0,1.05.47,1.05,1.05Z" fill="#06c"/></g></svg>}
            {icon=="edit" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.42 24.06"><g id="c"><path d="m1.05,24.06c-.28,0-.54-.11-.74-.31-.23-.24-.34-.57-.3-.9l.72-4.86c.03-.22.14-.43.3-.59L17.4,1.04c1.39-1.39,3.82-1.39,5.21,0l.21.21h0c1.55,1.55,1.55,4.07,0,5.61L6.65,23.04c-.16.16-.37.26-.59.3l-4.86.72c-.05,0-.1.01-.15.01Zm1.71-5.41l-.46,3.12,3.12-.46,15.92-15.92c.73-.73.73-1.92,0-2.64l-.21-.21c-.59-.59-1.64-.59-2.24,0L2.76,18.65Zm9.57,5.27c-.58,0-1.05-.47-1.05-1.05,0-.58.47-1.05,1.05-1.05h12.04s0,0,0,0c.58,0,1.05.47,1.05,1.05,0,.58-.47,1.05-1.05,1.05h-12.04s0,0,0,0Z" fill="#06c"/></g></svg>}
            {icon=="remove" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.12 24.1"><g id="c"><path d="m9.2,9.68v8.1c0,.58-.47,1.05-1.05,1.05s-1.05-.47-1.05-1.05v-8.1c0-.58.47-1.05,1.05-1.05s1.05.47,1.05,1.05Zm4.54-1.05c-.58,0-1.05.47-1.05,1.05v8.1c0,.58.47,1.05,1.05,1.05s1.05-.47,1.05-1.05v-8.1c0-.58-.47-1.05-1.05-1.05Zm8.39-4.22c0,.58-.47,1.05-1.05,1.05h-.42l-.87,15.43c-.1,1.8-1.59,3.21-3.4,3.21H5.5c-1.8,0-3.29-1.41-3.4-3.21l-.87-15.43h-.18c-.58,0-1.05-.47-1.05-1.05s.47-1.05,1.05-1.05h3.74l.6-1.71c.35-1,1.26-1.65,2.32-1.65h6.48c1.06,0,1.97.65,2.32,1.65l.6,1.71h3.97c.58,0,1.05.47,1.05,1.05Zm-15.11-1.05h7.87l-.35-1.02c-.05-.16-.18-.24-.34-.24h-6.48c-.17,0-.29.09-.34.24l-.35,1.02Zm11.54,2.1H3.33l.87,15.31c.04.69.61,1.23,1.3,1.23h10.89c.69,0,1.26-.54,1.3-1.23l.87-15.31Z" fill="#06c"/></g></svg>}
            {icon=="copy" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.1 24.1"><g id="c"><path d="m18.82,4.78h-.78v-.72c0-2.24-1.92-4.06-4.28-4.06H4.28C1.92,0,0,1.82,0,4.06v11.21c0,2.24,1.92,4.06,4.28,4.06h.78v.72c0,2.24,1.92,4.06,4.28,4.06h9.49c2.36,0,4.28-1.82,4.28-4.06v-11.21c0-2.24-1.92-4.06-4.28-4.06Zm-13.77,4.06v8.39h-.78c-1.2,0-2.18-.88-2.18-1.96V4.06c0-1.08.98-1.96,2.18-1.96h9.49c1.2,0,2.18.88,2.18,1.96v.72h-6.61c-2.36,0-4.28,1.82-4.28,4.06Zm15.94,11.21c0,1.08-.98,1.96-2.18,1.96h-9.49c-1.2,0-2.18-.88-2.18-1.96v-11.21c0-1.08.98-1.96,2.18-1.96h9.49c1.2,0,2.18.88,2.18,1.96v11.21Z" fill="#06c"/></g></svg>}
            {icon=="confirm" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.29 28"><g id="c"><path d="m15.29,28c-.77,0-1.54-.29-2.12-.88L.88,14.83c-1.17-1.17-1.17-3.07,0-4.24,1.17-1.17,3.07-1.17,4.24,0l10.17,10.17L35.17.88c1.17-1.17,3.07-1.17,4.24,0,1.17,1.17,1.17,3.07,0,4.24l-22,22c-.59.59-1.35.88-2.12.88Z" fill="#06c"/></g></svg>}
            {icon=="cancel" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.7 24.7"><g id="c"><path d="m24.3,22.4c.53.53.53,1.38,0,1.91-.26.26-.61.4-.95.4s-.69-.13-.95-.4l-10.05-10.05L2.3,24.3c-.26.26-.61.4-.95.4s-.69-.13-.95-.4c-.53-.53-.53-1.38,0-1.91l10.05-10.05L.4,2.3C-.13,1.78-.13.92.4.4.92-.13,1.78-.13,2.3.4l10.05,10.05L22.4.4c.53-.53,1.38-.53,1.91,0,.53.53.53,1.38,0,1.91l-10.05,10.05,10.05,10.05Z" fill="#06c"/></g></svg>}
            {icon=="preview" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 22"><g id="c"><g><path d="m13.98,20.57c-1.11,0-2.22-.13-3.31-.37-.1-.02-.2-.06-.29-.11-2.85-.71-5.43-2.21-7.47-4.34-1.1-1.13-2.01-2.42-2.7-3.84-.15-.28-.22-.62-.21-.96-.02-.25.06-.59.21-.9.69-1.4,1.6-2.7,2.7-3.83,1.53-1.59,3.34-2.83,5.39-3.66,1.82-.77,3.74-1.16,5.76-1.13,1.95-.04,3.91.36,5.74,1.13,1.74.71,3.33,1.71,4.71,2.98,1.27,1.16,2.33,2.52,3.14,4.04.03.05.05.1.07.15l.02.04s.05.1.06.1c.05.1.08.16.11.23.06.14.11.29.15.45.1.44.04.92-.17,1.34-.96,1.94-2.35,3.69-4.04,5.04-1.36,1.15-2.92,2.06-4.62,2.69-.71.27-1.46.48-2.22.62-.99.2-2.01.3-3.03.3Zm-2.58-2.36c.85.17,1.72.25,2.59.26.87,0,1.75-.09,2.61-.26.65-.12,1.28-.3,1.89-.53,1.47-.54,2.82-1.33,4.01-2.34,1.47-1.18,2.67-2.68,3.49-4.35l-.06-.11s-.06-.12-.06-.12l-.08-.16s-.04-.09-.06-.13c-.69-1.26-1.58-2.39-2.64-3.36-1.2-1.11-2.58-1.98-4.09-2.59,0,0-.01,0-.02,0-1.57-.66-3.23-.98-4.93-.97-1.72.01-3.38.31-4.95.97-1.79.73-3.36,1.79-4.69,3.18-.95.98-1.73,2.09-2.33,3.31.6,1.21,1.39,2.32,2.33,3.3,1.83,1.92,4.16,3.24,6.74,3.83.08.02.17.05.24.09Zm2.64-1.83h0c-2.97,0-5.38-2.42-5.37-5.39,0-2.97,2.42-5.38,5.39-5.38h0c2.97,0,5.38,2.42,5.37,5.39,0,2.96-2.42,5.37-5.38,5.37Zm0-8.67c-1.81,0-3.28,1.47-3.29,3.28,0,1.81,1.47,3.28,3.28,3.29h0c1.81,0,3.28-1.47,3.28-3.28,0-1.81-1.47-3.29-3.28-3.29h0Z" fill="#06c"/><rect x=".05" width="28" height="22" fill="none"/></g></g></svg>}
            {icon=="download" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.66 24.1"><g id="c"><path d="m9.83,24.1c-.5,0-.97-.21-1.3-.58L.46,14.69c-.48-.53-.59-1.25-.3-1.89s.9-1.04,1.6-1.04h3.33V1.75c0-.97.79-1.75,1.75-1.75h5.98c.97,0,1.75.79,1.75,1.75v10h3.33c.7,0,1.31.4,1.6,1.04.29.64.17,1.36-.29,1.89l-8.08,8.84c-.32.36-.8.58-1.3.58h0Zm-.25-1.99s0,0,0,0h0Zm.51,0h0,0ZM2.54,13.85l7.29,7.98,7.29-7.98h-3.6c-.58,0-1.05-.47-1.05-1.05V2.1h-5.29v10.7c0,.58-.47,1.05-1.05,1.05h-3.59Z" fill="#06c"/></g></svg>}
            {icon=="exclude" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><g id="c"><path d="m18.78,3.22c-2.15-2.15-4.96-3.22-7.78-3.22S5.37,1.07,3.22,3.22c-4.3,4.3-4.3,11.26,0,15.56,2.15,2.15,4.96,3.22,7.78,3.22s5.63-1.07,7.78-3.22c4.3-4.3,4.3-11.26,0-15.56Zm-14.07,1.48c1.68-1.68,3.92-2.61,6.29-2.61,2.02,0,3.94.68,5.5,1.91l-12.49,12.49c-2.75-3.49-2.52-8.58.69-11.8Zm12.59,12.59c-1.68,1.68-3.92,2.61-6.29,2.61-2.02,0-3.94-.68-5.5-1.91l12.49-12.49c2.75,3.49,2.52,8.58-.69,11.8Z" fill="#06c"/></g></svg>}
        </div>
    )
}