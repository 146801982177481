import markImg from "../assets/mark.svg"

export default function ItemCheckbox({shown,clickEvent}){
    return(
        <div className={shown==0?"item-select":"item-select item-select-shown"} onClick={clickEvent}>
            <div className="item-checkbox">
                <img className="item-checkbox-mark" src={markImg} />
            </div>
        </div>
    )
}