import {Fragment} from "react"
import {formatAmount} from "../hooks/Commons"
import ButtonSmall from "../commons/ButtonSmall"
import star from "../assets/star.svg"
import rocket from "../assets/rocket.svg"
import bolt from "../assets/bolt.svg"

export default function DataRecord({item,index,header,editEvent,removeEvent}){
    return(
        <Fragment>
            {header ?
            <div className="data-record">
                <div className="data-record-header">Fecha</div>
                <div className="data-record-header">Operación</div>
                <div className="data-record-header data-record-right">Cantidad</div>
                <div className="data-record-header data-record-right">Valor</div>
                <div className="data-record-header data-record-right">Acciones</div>
            </div>:
            <div className="data-record">
                <div>{item.moment}</div>
                <div>
                    <div className="data-bold">{item.detail}</div>
                    <div className="data-colored data-small">{item.description}</div>
                    <div className={"data-period data-period-"+item.period_name}>{item.period_name}</div>
                    {item.premium==1 && <div className="data-premium"><img src={star} /></div>}
                    {item.initial_point==1 && <div className="data-rocket"><img src={rocket} /></div>}
                    {item.period==8 && <div className="data-bolt"><img src={bolt} /></div>}
                </div>
                <div className="data-record-right data-medium">{item.quantity}</div>
                <div className="data-record-right data-bold data-colored">{formatAmount(item.amount,"EUR","display")}</div>
                <div className="data-record-actions">
                    <ButtonSmall icon="edit" clickEvent={()=>{editEvent(index)}} />
                    <ButtonSmall icon="remove" clickEvent={()=>{removeEvent(index)}} />
                </div>
            </div>}
        </Fragment>
    )
}