import unknownIcon from "../assets/unknown.svg"

export default function Avatar({context,id,picture,capitals}){
    let className="avatar"
    let styles={}
    let showCapitals=false
    let unknown=false
    if(id==""){
        showCapitals=false
        unknown=true
    }
    if(context=="entrance"){
        className+=" avatar-entrance"
    }
    if(picture){
        styles.backgroundImage="url(/images/users/"+id+".jpg)"
    }else{
        showCapitals=true
    }
    if(id==""){
        showCapitals=false
        unknown=true
    }
    return(
        <div className={className} style={styles}>
            {showCapitals && 
            <div className="avatar-capitals">
                <svg width="100%" height="100%" viewBox="0 0 100 100">
                    <text x="50%" y="50%" fontSize="48" fill="#FFFFFF" dominantBaseline="middle" textAnchor="middle">{capitals}</text>
                </svg>
            </div>}
            {unknown && <img className="avatar-unknown" src={unknownIcon} />}
        </div>
    )
}