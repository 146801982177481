import {Fragment} from "react"
import {formatAmount} from "../hooks/Commons"
import money from "../assets/money.svg"

export default function DataCalculate({item,header}){
    return(
        <Fragment>
            {header ?
            <div className="data-calculate">
                <div className="data-record-header">Fecha</div>
                <div className="data-record-header">Evento</div>
                <div className="data-record-header data-record-right">Cantidad</div>
                <div className="data-record-header data-record-right">Valor</div>
                <div className="data-record-header data-record-right">Total</div>
                <div className="data-record-header data-record-right">Reclamación</div>
            </div>:
            <div className={"data-calculate"+(item.type=="filled"?" data-calculate-filled":"")}>
                <div>{item.moment}</div>
                <div>
                    <div className={item.type=="contrasplit"?" data-colored-4":item.type=="filled"?" data-medium data-colored":item.type=="investment"?" data-medium data-colored-2":item.type=="rights"?" data-medium data-colored-5":""}>
                        {item.type=="filled" && <div className="data-money"><img src={money} /></div>}{item.description}
                    </div>
                </div>
                <div className="data-record-right data-colored-3">{item.quantity!==""?item.quantity:""}</div>
                <div className="data-record-right data-colored-3">{item.amount!==""?formatAmount(item.amount,"EUR","display"):""}</div>
                <div className={"data-record-right data-colored-2"+(item.type=="filled"?" data-medium":item.type=="contrasplit"?" data-colored-4":"")}>{item.total!==""?item.total:""}</div>
                <div className={"data-record-right data-colored"+(item.type=="filled"?" data-bold":item.type=="investment"?" data-bold data-colored-2":"")}>{item.balance!==""?formatAmount(item.balance,"EUR","display"):""}</div>
            </div>}
        </Fragment>
    )
}