export default function BoardActions({actionsLeft,actionsRight}){
    return(
        <div className="board-actions">
            <div className="board-actions-left">
                {actionsLeft}
            </div>
            <div className="board-actions-right">
                {actionsRight}
            </div>
        </div>
    )
}