import {Fragment} from "react"
import useBoard from "../hooks/useBoard"
import ButtonSmall from "../commons/ButtonSmall"

export default function DataPerson({context,item,index,excludeEvent}){
    
    const {loadBoard}=useBoard()

    const openPerson=(person)=>{
        loadBoard({params:{alias:"persons-edit",level:2,theme:"rabbit2",reference:person}})
    }

    return(
        <div className={"data-client"+(item.status==0?" data-client-off":"")+(item.excluded==1?" data-client-excluded":"")}>
            <div className="data-client-name data-client-name-alt">
                <div className="data-client-title data-medium">{item.last_name}, {item.first_name}</div>
            </div>
            <div className="data-client-actions">
                {context=="assemblage" &&
                <Fragment>
                    <ButtonSmall icon="preview" clickEvent={()=>{openPerson(item.id)}} />
                    <ButtonSmall icon="exclude" clickEvent={()=>{excludeEvent(index)}} />
                </Fragment>}
            </div>
        </div>
    )
}