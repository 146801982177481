import {useContext} from "react"
import ToastContext from "../contexts/ToastContext"

export default function Toast(){
    const [toastContext]=useContext(ToastContext)
    return(
        <div id="toast" className="toast noselect">
            <div className="toast-box">
                {toastContext.toast=="saved" &&
                <div>¡Cambios guardados!</div>}
                {toastContext.toast=="created_m" &&
                <div>¡Creado!</div>}
                {toastContext.toast=="created_f" &&
                <div>¡Creada!</div>}
                {toastContext.toast=="removed" &&
                <div>Elemento eliminado.</div>}
                {toastContext.toast=="file_format_not_allowed" &&
                <div>Formato de archivo no permitido.</div>}
                {toastContext.toast=="imported" &&
                <div>¡Datos importados correctamente!</div>}
                {toastContext.toast=="exported" &&
                <div>¡Datos exportados correctamente!</div>}
                {toastContext.toast=="generic_error" &&
                <div>Algo no anduvo bien :(</div>}
                {toastContext.toast=="var_copied" &&
                <div>¡Variable copiada!</div>}
                {toastContext.toast=="package_generated" &&
                <div>¡Paquete generado!</div>}
                {toastContext.toast=="assembly_generated" &&
                <div>¡Documentos ensamblados!</div>}
                {toastContext.toast=="undone_import" &&
                <div>¡Importación deshecha!</div>}
            </div>
        </div>
    )
}