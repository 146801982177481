import {useContext} from "react"
import GlobalContext from "../contexts/GlobalContext"
import useBoard from "../hooks/useBoard"

export default function useRefresh(){
    const [globalContext,setGlobalContext]=useContext(GlobalContext)
    const {closeBoard}=useBoard()
    const refresh=(context)=>{
        let globalContextNew={...globalContext}
        globalContextNew["refresh"]=context
        setGlobalContext(globalContextNew)
        if(context.close){
            closeBoard(context.close)
        }
    }
    return {refresh}
}