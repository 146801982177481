import {Fragment,useContext,useEffect,useState,useRef} from "react"
import GlobalContext from "../contexts/GlobalContext"
import AppsContext from "../contexts/AppsContext"
import closeImg from "../assets/close.svg"

export default function Help(){

    const [globalContext,setGlobalContext]=useContext(GlobalContext)
    const [appsContext]=useContext(AppsContext)
    let app=appsContext.current
    let boards=appsContext.boards
    let activeBoard=boards[boards.length-1]["alias"]
    const [alternateInfo,setAlternateInfo]=useState(0)
    const info=useRef()

    const help=()=>{
        let globalContextNew={...globalContext}
        if(globalContext.help==1){
            globalContextNew["help"]=0
        }else{
            globalContextNew["help"]=1
        }
        setGlobalContext(globalContextNew)
    }

    useEffect(()=>{
        setAlternateInfo(0)
        info.current.scrollTop=0
        activeBoard=boards[boards.length-1]["alias"]
    },[appsContext])

    useEffect(()=>{
        info.current.scrollTop=0
    },[alternateInfo])

    return(
        <div ref={info} className="help">
            <div className="help-close" onClick={()=>{help()}}>
                <img src={closeImg} />
            </div>

            {activeBoard=="main" &&
            <div>
                {app=="sanpop" &&
                <Fragment>
                    <h2>¡Te damos la bienvenida a la app Santander/Popular!</h2>
                    <p>Administra clientes, grupos y modelos relacionados a demandas del Banco Popular/Santander.</p>
                    <p>Utiliza los botones de la izquierda para ingresar a las distintas secciones.</p>
                    <p>Para cerrar la sesión, utiliza el botón de apagado que se encuentra en zona inferior. Para intercambiar entre apps, utiliza el botón de apps. Para mostrar la asistencia, utiliza el botón de ayuda.</p>
                </Fragment>}
                {app=="models" &&
                <Fragment>
                    <h2>¡Te damos la bienvenida a la app Modelos!</h2>
                    <p>Crea plantillas con información dinámica y genera documentos personalizados.</p>
                    <p>Utiliza los botones de la izquierda para ingresar a las distintas secciones.</p>
                    <p>Para cerrar la sesión, utiliza el botón de apagado que se encuentra en zona inferior. Para intercambiar entre apps, utiliza el botón de apps. Para mostrar la asistencia, utiliza el botón de ayuda.</p>
                </Fragment>}
            </div>}

            {activeBoard=="clients" &&
            <div>
                <h2>Clientes</h2>
                <p>Listado general ordenado alfabéticamente con todos los clientes registrados.</p>
                <p>Utiliza el buscador para comenzar una búsqueda instantánea o el botón agregar para crear un cliente nuevo.</p>
                <p>Haz clic en un cliente para ver información detallada. El número celeste que aparece entre paréntesis indica la cantidad de personas asociadas al cliente.</p>
                <p>Los clientes inactivos se muestran con transparencia.</p>
                <p>Las etiquetas de colores sirven para identificar clientes de acuerdo a criterios específicos.</p>
            </div>}

            {activeBoard=="clients-edit" &&
            <div>
                <h2>Agregar o modificar clientes</h2>
                <p>Completa la información general del cliente, puedes asociarlo a un bufete o asignarle etiquetas para identificarlo de acuerdo a criterios predefinidos.</p>
                <p>Los clientes inactivos no aparecen en los modelos ni se tienen en cuenta para los cálculos de reclamaciones conjuntas.</p>
                <p>Utiliza el botón Eliminar para borrar el cliente en forma definitiva.</p>
                <h2>Importar clientes</h2>
                <p>Para importar clientes en forma masiva desde un archivo, utiliza el botón "Importar".</p>
            </div>}

            {activeBoard=="clients-data" &&
            <div>
                {alternateInfo==0 &&
                <div>
                    <h2>Detalles del cliente</h2>
                    <p>Puedes visualizar información general del cliente, personas asociadas, registro de operaciones, cálculo de reclamaciones y documentos adjuntos.</p>
                    <p>Haz clic en el botón "Editar" para modificar los datos del cliente o para eliminarlo.</p>
                    <h2>Personas asociadas</h2>
                    <p>Estas personas no son clientes como tales, pero se adjunta la información de ellas al cliente principal al momento de generar los datos dinámicos de los modelos.</p>
                    <p>Haz clic en el botón "Más" para añadir una persona asociada; también puedes usar los botones para "Editar" o "Borrar" que se encuentran al lado de cada una. Recuerda hacer clic en el botón "Guardar" de la zona superior luego de que hayas agregado o editado personas asociadas.</p>
                    <p>Las personas asociadas que no estén activas, no se mostrarán en el modelo. </p>
                    <h2>Resumen de operaciones</h2>
                    <p>Muestra un listado con la sumatoria de cada tipo de operaciones registradas.</p>
                    <h2>Operaciones</h2>
                    <p>Aparecen las operaciones del cliente ordenadas en forma cronológica.</p>
                    <p>Utiliza el botón "Más" para añadir una operación. También puedes editar o eliminar cada operación con los botones correspondientes.  Recuerda hacer clic en el botón "Guardar" de la zona superior luego de que hayas agregado o editado operaciones.</p>
                    <p>Las etiquetas de colores identifican el período al que corresponde cada operación. Esta identificación es automática en base a parámetros definidos internamente.</p>
                    <p className="help-link" onClick={()=>{setAlternateInfo(1)}}>Haz <strong>clic aquí</strong> para ver información sobre la definición de los períodos.</p>
                    <p>El icono del "cohete" marca el punto de inicio que se tiene en cuenta para calcular las reclamaciones.</p>
                    <p>El icono del "rayo" identifica los pares de operaciones que corresponden a un contrasplit.</p>
                    <h2>Monto reclamable</h2>
                    <p>Muestra el resultado final del cálculo de las reclamaciones.</p>
                    <h2>Cálculo de reclamaciones</h2>
                    <p>Este cálculo se realiza en forma automática de acuerdo a parámetros establecidos internamente.</p>
                    <p className="help-link" onClick={()=>{setAlternateInfo(1)}}>Haz <strong>clic aquí</strong> para ver información sobre la definición de los períodos.</p>
                    <p>Para ello se hace un recorrido por las operaciones del cliente, calculando el daño de cada operación de inversión.</p>
                    <p>El punto de inicio determina la situación del cliente antes del comienzo de la información inexacta, es decir, a partir de este punto se comienza a calcular el daño.</p>
                    <p>Los registros en color celeste representan las operaciones originales de inversión.</p>
                    <p>Los registros en color negro representan los descuentos por ventas. En caso de que una venta se descuente de varias operaciones de inversión, los paréntesis indican el inicio "(..." y el final "...)" del descuento de esa misma venta.</p>
                    <p>Los registros en color fucsia indican la existencia de un contrasplit, alterando la cantidad de acciones de las operaciones siguientes (se dividen entre cinco).</p>
                    <p>Los registros en color azul muestran el resultado final de esa parte del cálculo, es decir, el monto reclamable correspondiente a la operación de inversión de ese grupo.</p>
                    <p>Las operaciones de "Compra de derechos", "Venta de derechos", "Compra de bonos" y "Venta de bonos" se agregan o restan al final, luego del cálculo FIFO.</p>
                    <p>El monto reclamable total es la sumatoria de todos los resultados de reclamaciones.</p>
                    <h2>Documentos adjuntos</h2>
                    <p>Listado de archivos asociados al cliente.</p>
                    <p>Utiliza el botón "Más" para adjuntar nuevos archivos. Puedes previsualizarlos, descargarlos o eliminarlos con los botones correspondientes. Recuerda hacer clic en el botón "Guardar" luego de que hayas agregado o editado archivos.</p>
                    <p>Puedes subir archivos PDF, documentos de Word, Excel, PowerPoint, texto, ZIP, RAR, imágenes JPG, PNG, GIF, TIFF o WEBP.</p>
                    <p>Se dividen en cuatro tipos: ficha general (planilla Excel con los datos de origen), poder para pleitos, comprobantes de operaciones y complementarios (para otro tipo de archivos generales).</p>
                    <p>Los documentos del mismo tipo se diferencian con un número correlativo que se asigna en forma automática.</p>
                    <h2>Paquete</h2>
                    <p>Utiliza el botón "Paquete" para obtener un archivo ZIP con todos los documentos asociados al cliente.</p>
                    <h2>Ensamblar</h2>
                    <p>Utiliza el botón "Ensamblar" para generar un documento PDF único que contenga: datos generales del cliente, monto reclamable, operaciones, poder para pleitos, comprobantes de operaciones y otros documentos asociados. Todo incluido en un mismo archivo.</p>
                </div>}
                {alternateInfo==1 &&
                <div>
                    <h2 className="help-link" onClick={()=>{setAlternateInfo(0)}}>« Volver</h2>
                    <h2>PRE12</h2>
                    <p>Operaciones desde el 01/01/1980 al 27/02/2012. Se detecta como operación inicial si se registró exactamente el 27/02/2012. Puede ser "Alta por canje", "Compra de acciones" o "Compra de bonos" y el valor unitario de la acción debe ser de 3.11 euros. Es mercado secundario.</p>
                    <h2>PRE16</h2>
                    <p>Operaciones desde el 28/02/2012 al 20/06/2016. Puede ser "Compra de acciones", "Alta por canje", "Compra de bonos", "Venta de acciones", "Baja por canje" o "Venta de bonos". Es mercado secundario.</p>
                    <h2>PRE16D</h2>
                    <p>Operaciones desde el 28/02/2012 al 20/06/2016. Puede ser "Compra de derechos" o "Venta de derechos". Es mercado secundario.</p>
                    <h2>PRE16AC</h2>
                    <p>Operaciones desde el 28/02/2012 al 20/06/2016. Es "Ampliación de capital". Es mercado secundario.</p>
                    <h2>AC12</h2>
                    <p>Operaciones desde el 01/12/2012 al 10/12/2012, suele aparecer exactamente el 05/12/2012. Puede ser "Ampliación de capital", "Compra de acciones" o "Compra de bonos". El valor unitario de la acción debe estar entre 0.36 y 0.41 euros. Es mercado primario.</p>
                    <h2>AC16</h2>
                    <p>Operaciones desde el 10/06/2016 al 30/06/2016, suele aparecer exactamente el 20/06/2016. Puede ser "Ampliación de capital", "Compra de acciones" o "Compra de bonos". El valor unitario de la acción debe estar entre 1.24 y 1.26 euros. Es mercado primario.</p>
                    <h2>CONTRASPLIT</h2>
                    <p>Operaciones desde el 10/06/2013 al 21/06/2013. Puede ser "Compra de acciones", "Alta por canje", "Venta de acciones" o "Baja por canje". El monto de las operaciones debe ser cero. Para detectar su par se busca un registro en donde el resultado de la cantidad del registro dividida la cantidad de algún otro registro esté entre 4.9 y 5.1, o el resultado la cantidad del registro multiplicada por 5 esté entre la cantidad menos 5.1 y la cantidad más 5.1 de algún otro registro. Únicamente si se encuentran dos registros coincidentes se marca como contrasplit. Es mercado secundario.</p>
                    <h2>POS</h2>
                    <p>Todas las operaciones de cualquier tipo desde el 21/06/2016 y que no hayan sido detectadas como AC16. Es mercado secundario.</p>
                    <h2>AMORT</h2>
                    <p>Operaciones en cualquier fecha que sean del tipo "Baja por amortización". Es mercado secundario.</p>
                    <h2>PREMIUM</h2>
                    <p>Todas las operaciones desde el 26/05/2016. Pueden ser "Compra de acciones", "Alta por canje", "Ampliación de capital", "Compra de derechos" o "Compra de bonos". Las operaciones premium, además pueden pertenecer a alguno de los otros períodos definidos. Es mercado secundario.</p>
                    <h2 className="help-link" onClick={()=>{setAlternateInfo(0)}}>« Volver</h2>
                </div>}
            </div>}

            {activeBoard=="clients-import" &&
            <div>
                <h2>Importar clientes</h2>
                <p>Con esta opción podrás importar clientes en forma masiva desde un único archivo.</p>
                <p>Debe ser un archivo comprimido en formato ZIP que contenga diferentes carpetas con los archivos de cada cliente.</p>
                <p>¡Importante! El archivo ZIP debe contener las carpetas de cada cliente en el nivel principal. El sistema no realizará la importación si por ejemplo dentro del archivo hubiera una sola carpeta llamada "Clientes", que a su vez contenga subcarpetas con cada cliente.</p>
                <p>Dentro de la carpeta de cada cliente deben estar los archivos correspondientes según las reglas establecidas previamente.</p>
                <h2>1. Ficha de Excel</h2>
                <p>Debe contener la información personal del cliente y el registro de sus operaciones. Es importante respetar el diseño estandarizado de esta ficha para que la plataforma pueda importar la información correctamente.</p>
                <h2>2. Poderes de actuación</h2>
                <p>Deben ser uno o más documentos PDF con cualquier nombre, si están en el nivel principal se identificarán como "Poder de actuación".</p>
                <h2>3. Comprobantes de operaciones</h2>
                <p>Deben estar dentro de una carpeta con cualquier nombre. Pueden ser más de uno, en formato PDF.</p>
                <h2>Subir el archivo</h2>
                <p>Selecciona el archivo de tu computadora para subirlo. Es posible que este proceso demore algunos minutos, dependerá del tamaño del archivo y de la cantidad de clientes a importar.</p>
                <h2>Previsualización</h2>
                <p>Una vez analizado el archivo, podrás ver un listado con los datos importados. Podrás asignarle un nombre a la importación para luego poder identificarla.</p>
                <h2>Control de errores</h2>
                <p>Los errores detectados aparecerán marcados en color rojo. ¡Este es el momento para corregirlos!</p>
                <h2>Confirmar la importación</h2>
                <p>Una vez que hayas revisado y corregido los datos, haz clic en el botón "Importar" para finalizar el proceso. Es posible que demore algunos minutos. ¡Los clientes importados se agregarán a la plataforma!</p>
                <p>Ten en cuenta que podrás "Deshacer" la operación desde el tablero "Importaciones" en cualquier momento.</p>
                <h2>Descartar la importación</h2>
                <p>Si por algún motivo prefieres no proceder con la importación, es necesario que hagas clic en el botón "Descartar"; de esta forma se eliminarán los archivos temporales que ocupan recursos considerables dentro del servidor. ¡No lo olvides!</p>
            </div>}

            {activeBoard=="collections" &&
            <div>
                <h2>Grupos</h2>
                <p>Agrupan clientes de acuerdo a una serie de criterios definidos previamente.</p>
                <p>Utiliza el buscador para comenzar una búsqueda instantánea o el botón agregar para crear un grupo nuevo.</p>
                <p>Haz clic en un grupo para ver información detallada. El número indica la cantidad de clientes totales que incluye.</p>
                <p>En celeste puedes visualizar el periodo de fechas que se tiene en cuenta para la búsqueda de operaciones.</p>
                <p>Los grupos inactivos se muestran con transparencia.</p>
                <p>Las etiquetas de colores indican si el grupo incluye clientes marcados con esas etiquetas.</p>
            </div>}

            {activeBoard=="collections-data" &&
            <div>
                <h2>Detalles del grupo</h2>
                <p>Puedes ver información general del grupo, los filtros aplicados y los clientes que se incluyen. Utiliza el botón "Editar" para modificar el grupo.</p>
                <h2>Valores totales y parciales</h2>
                <p>Los valores más oscuros se calculan aplicando los filtros del grupo, es decir, se tienen en cuenta sólo las operaciones que cumplan con las condiciones establecidas (período de fechas y características de las operaciones).</p>
                <p>Los valores más claros y entre paréntesis indican los valores totales, sin aplicar ningún filtro a las operaciones.</p>
                <h2>Monto reclamable</h2>
                <p>Muestra el resultado final del cálculo de las reclamaciones para todo el grupo.</p>
                <h2>Resumen de operaciones</h2>
                <p>Muestra un listado con la sumatoria de cada tipo de operaciones registradas.</p>
                <h2>Listado de clientes</h2>
                <p>Puedes ver los clientes incluidos en el grupo de acuerdo a la configuración de filtros aplicada. Utiliza el botón "Mirar" de cada cliente para acceder a la información detallada.</p>
                <h2>Excluir clientes</h2>
                <p>Has clic en el botón "Excluir" que se encuentra al lado de cada cliente, el nombre aparecerá tachado. Vuelve a hacer clic para quitar la exclusión. Cuando hayas definido todas las exclusiones, haz clic en el botón "Guardar".</p>
                <p>Los clientes excluidos del grupo no se tendrán en cuenta al momento de calcular las reclamaciones ni al generar los documentos dinámicos. Ten en cuenta que los clientes inactivos también se excluyen en forma general.</p>
                <h2>Paquete</h2>
                <p>Utiliza el botón "Paquete" para obtener un único archivo ZIP que contenga carpetas para cada cliente con todos los documentos asociados a cada uno.</p>
                <p>De acuerdo a la cantidad de clientes que contenga el grupo, este proceso puede demorar varios minutos y podría generar archivos de gran tamaño. ¡Ten paciencia!</p>
                <h2>Ensamblar</h2>
                <p>Utiliza el botón "Ensamblar" para generar un único archivo ZIP que contenga un documento PDF para cada cliente con: datos generales del cliente, monto reclamable, operaciones, poder para pleitos, comprobantes de operaciones y otros documentos asociados. Todo incluido en un mismo archivo.</p>
                <p>De acuerdo a la cantidad de clientes que contenga el grupo, este proceso puede demorar varios minutos y podría generar archivos de gran tamaño. ¡Ten paciencia!</p>
            </div>}

            {activeBoard=="collections-edit" &&
            <div>
                <h2>Agregar o modificar grupos</h2>
                <p>Asigna un nombre identificativo para el grupo. Luego, puedes definir una serie de filtros que determinarán qué clientes estarán dentro del grupo.</p>
                <h2>Intervalo</h2>
                <p>Incluye clientes que presenten operaciones dentro del rango de fechas indicado. Puedes indicar sólo la fecha de inicio o de finalización, incluirás operaciones desde o hasta respectivamente.</p>
                <h2>Períodos</h2>
                <p>Incluye clientes que presenten operaciones identificadas con los tipos de períodos seleccionados, también puedes indicar si poseen operaciones premium.</p>
                <h2>Operaciones</h2>
                <p>Incluye clientes que presenten o no operaciones. Puedes indicar los tipos de operaciones o mercados.</p>
                <h2>Etiquetas y bufetes</h2>
                <p>Incluye clientes que pertenezcan a los bufetes seleccionados o que estén marcados con las etiquetas indicadas.</p>
                <h2>Clientes</h2>
                <p>Filtra de acuerdo a si los clientes están activos o inactivos, o si tienen o no personas asociadas.</p>
                <h2>Documentos</h2>
                <p>Incluye a clientes que tengan cargados los documentos seleccionados.</p>
                <h2>Eliminar</h2>
                <p>Utiliza el botón Eliminar para borrar el grupo en forma definitiva, ten en cuenta que no se borrarán los clientes que contenga el grupo.</p>
            </div>}

            {activeBoard=="bufetes" &&
            <div>
                <h2>Bufetes</h2>
                <p>Despachos de abogados registrados en el sistema. Puedes asociar clientes a cada bufete.</p>
                <p>Utiliza el buscador para comenzar una búsqueda instantánea o el botón agregar para crear un bufete nuevo.</p>
                <p>Haz clic en un bufete para editar la información. El número indica la cantidad de clientes totales asociados al bufete.</p>
                <p>Los bufetes inactivos se muestran con transparencia.</p>
            </div>}

            {activeBoard=="bufetes-edit" &&
            <div>
                <h2>Agregar o modificar bufetes</h2>
                <p>Completa el nombre de bufete, puedes agregar una referencia al sitio web institucional.</p>
                <p>Asocia clientes a cada bufete desde el tablero de edición de clientes.</p>
                <p>Utiliza el botón Eliminar para borrar el bufete en forma definitiva.</p>
            </div>}

            {activeBoard=="models" &&
            <div>
                <h2>Modelos</h2>
                <p>Son plantillas de Word personalizadas que contienen variables, las cuales son reemplazadas por datos dinámicos extraídos de la plataforma.</p>
                <p>Utiliza el buscador para comenzar una búsqueda instantánea o el botón agregar para crear un modelo nuevo.</p>
                <p>Haz clic en un modelo para ver información detallada. El número indica la cantidad de documentos que se pueden generar.</p>
            </div>}

            {activeBoard=="models-edit" &&
            <div>
                <h2>Agregar o modificar modelos</h2>
                <p>Asigna un nombre identificativo para el modelo.</p>
                <h2>Plantilla de Word</h2>
                <p>Sube un archivo creado con Word que servirá de modelo para generar documentos con la información dinámica asociada a los grupos.</p>
                <p>Dentro de este archivo deben estar indicados los lugares que se reemplazarán por información dinámica.</p>
                <h2>Variables</h2>
                <p>Las variables sirven para definir los datos dinámicos que se agregarán a la plantilla. Cada variable será reemplazada por los datos indicados.</p>
                <p>Utiliza el botón "Más" para agregar variables.</p>
                <h2>Nombre de la variable</h2>
                <p>Este nombre identifica a la variable y debe incluirse dentro del archivo de Word con el formato indicado por la plataforma. Elige nombres cortos, que no se repitan y sin espacios.</p>
                <h2>Reemplazar por</h2>
                <p>Selecciona un valor de lista, las variables se reemplazarán por esos datos y con ese formato. Elige la opción más adecuada de acuerdo a las necesidades del modelo.</p>
                <h2>Otras opciones</h2>
                <p>La opción "Incluir personas asociadas" agrega la información de las personas asociadas junto a cada cliente (en caso de que tuvieran).</p>
                <p>La opción "En mayúsculas" formatea el texto en letras mayúsculas.</p>
                <h2>Cómo indicar las variables</h2>
                <p>Una vez que hayas creado una variable, debes indicar dentro del archivo de Word la posición en donde aparecerá.</p>
                <p>Para ello, copia el texto indicado (puedes utilizar el botón "Copiar") y pégalo dentro del documento en cualquier parte que desees. Es muy importante que respetes el formato exactamente como se indica.</p>
                <h2>Cómo continuar</h2>
                <p>Una vez que hayas subido el archivo y definido las variables, guarda el modelo. Luego podrás utilizarlo para crear documentos dinámicos con la información de algún grupo.</p>
                <h2>Generar documentos</h2>
                <p>Una vez que hayas creado el modelo, podrás ver el botón "Generar". Haz clic en él para asociar el modelo a un grupo determinado.</p>
                <p>Puedes generar varios documentos asociados a diferentes grupos.</p>
                <h2>Obtener documentos</h2>
                <p>Una vez que hayas generado documentos, los verás en esta lista.</p>
                <p>Haz clic en el botón "Previsualizar" o en el botón "Descargar" para obtener un documento actualizado con los datos dinámicos del grupo agregados en la posición de cada variable del modelo.</p>
                <h2>Eliminar</h2>
                <p>Utiliza el botón Eliminar para borrar el modelo en forma definitiva, ten en cuenta que no se borrarán ni los grupos ni los clientes relacionados.</p>
            </div>}

            {activeBoard=="models-generate" &&
            <div>
                <h2>Generar documento</h2>
                <p>Escribe un nombre identificativo para el documento.</p>
                <p>Podrás elegir un modelo y asociarlo con la información dinámica de un grupo determinado.</p>
                <p>Una vez que lo hayas generado, lo verás en el listado "Obtener documentos" y desde allí podrás conseguir una copia del modelo con los datos dinámicos reemplazados.</p>
            </div>}

            {activeBoard=="imports" &&
            <div>
                <h2>Importaciones</h2>
                <p>Aquí se guardan las importaciones masivas de clientes. Te permite identificar la fecha en que se hizo la importación y los clientes que fueron agregados. Además, puedes deshacer la importación en caso de equivocación.</p>
                <p>Utiliza el buscador para comenzar una búsqueda instantánea.</p>
                <p>Haz clic en una importación para ver información detallada. El número indica la cantidad de clientes importados.</p>
            </div>}

            {activeBoard=="imports-data" &&
            <div>
                <h2>Detalle de la importación</h2>
                <p>Puedes ver el nombre y la fecha de la importación. Además, puedes visualizar un listado con los clientes que se importaron.</p>
                <h2>Deshacer</h2>
                <p>Si por algún motivo necesitaras deshacer esta importación, utiliza el botón "Deshacer". De esta forma, se eliminarán todos los clientes que se agregaron al momento de la importación. Ten en cuenta que los clientes se eliminarán en forma definitiva.</p>
                <h2>Edición</h2>
                <p>Utiliza el botón "Editar" para modificar el nombre de la importación.</p>
            </div>}

            {activeBoard=="imports-edit" &&
            <div>
                <h2>Editar importación</h2>
                <p>Desde aquí puedes modificar el nombre de esta importación.</p>
            </div>}

            {activeBoard=="persons" &&
            <div>
                <h2>Personas</h2>
                <p>Listado general ordenado alfabéticamente con todas las personas registradas.</p>
                <p>Utiliza el buscador para comenzar una búsqueda instantánea o el botón agregar para crear una persona nueva.</p>
                <p>Haz clic en una persona para editar la información.</p>
                <p>Las personas inactivas se muestran con transparencia.</p>
                <p>Las etiquetas de colores sirven para identificar personas de acuerdo a criterios específicos.</p>
            </div>}

            {activeBoard=="persons-edit" &&
            <div>
                <h2>Agregar o modificar personas</h2>
                <p>Completa la información general de la persona, puedes asociarla a uno o más grupos o asignarle etiquetas para identificarla de acuerdo a criterios predefinidos.</p>
                <p>Los siete campos de información extra sirven para guardar datos genéricos asociados a cada persona, utilízalos de acuerdo a las necesidades del modelo.</p>
                <p>Las personas inactivas no aparecen en los modelos.</p>
                <p>Utiliza el botón Eliminar para borrar la persona en forma definitiva.</p>
                <h2>Importar personas</h2>
                <p>Para importar personas en forma masiva desde un archivo, utiliza el botón "Importar".</p>
            </div>}

            {activeBoard=="persons-import" &&
            <div>
                <h2>Importar personas</h2>
                <p>Con esta opción podrás importar clientes en forma masiva desde un Excel.</p>
                <h2>Plantilla</h2>
                <p>Debes utilizar como base la plantilla proporcionada. Haz clic en "Descargar plantilla" para obtenerla.</p>
                <p>En este archivo encontrarás una estructura base sobre la cual podrás ir agregando filas con la información de cada persona.</p>
                <p>Es muy importante que utilices la plantilla proporcionada y que respetes el formato para que la importación sea exitosa.</p>
                <h2>Subir el archivo</h2>
                <p>Selecciona el archivo de tu computadora para subirlo, es decir, la plantilla que habías descargado pero con los datos de las personas que se importarán.</p>
                <h2>Previsualización</h2>
                <p>Una vez analizado el archivo, podrás ver un listado con los datos importados. Podrás asignarle un nombre a la importación para luego poder identificarla.</p>
                <h2>Control de errores</h2>
                <p>Los errores detectados aparecerán marcados en color rojo. ¡Este es el momento para corregirlos!</p>
                <h2>Confirmar la importación</h2>
                <p>Una vez que hayas revisado y corregido los datos, haz clic en el botón "Importar" para finalizar el proceso. ¡Las personas importadas se agregarán a la plataforma!</p>
                <p>Ten en cuenta que podrás "Deshacer" la operación desde el tablero "Importaciones" en cualquier momento.</p>
                <h2>Descartar la importación</h2>
                <p>Si por algún motivo prefieres no proceder con la importación, es necesario que hagas clic en el botón "Descartar".</p>
            </div>}

            {activeBoard=="assemblages" &&
            <div>
                <h2>Grupos</h2>
                <p>Sirven para agrupar personas, que luego serán utilizadas para generar los documentos dinámicos.</p>
                <p>Utiliza el buscador para comenzar una búsqueda instantánea o el botón agregar para crear un nuevo grupo.</p>
                <p>Haz clic en un grupo para ver información detallada. El número indica la cantidad de personas totales que incluye.</p>
                <p>Los grupos inactivos se muestran con transparencia.</p>
                <p>Las etiquetas de colores indican si el grupo incluye personas marcadas con esas etiquetas.</p>
            </div>}

            {activeBoard=="assemblages-data" &&
            <div>
                <h2>Detalles del grupo</h2>
                <p>Puedes ver información general sobre el grupo y un listado con las personas incluidas.</p>
                <h2>Excluir personas</h2>
                <p>Has clic en el botón "Excluir" que se encuentra al lado de cada persona, el nombre aparecerá tachado. Vuelve a hacer clic para quitar la exclusión. Cuando hayas definido todas las exclusiones, haz clic en el botón "Guardar".</p>
                <p>Las personas excluidas del grupo no se tendrán en cuenta al momento de generar los documentos dinámicos. Ten en cuenta que las personas inactivas también se excluyen en forma general.</p>
                <h2>Edición</h2>
                <p>Haz clic en el botón "Editar" para modificar la configuración del grupo.</p>
            </div>}

            {activeBoard=="assemblages-edit" &&
            <div>
                <h2>Agregar o modificar grupos</h2>
                <p>Asigna un nombre identificativo para el grupo.</p>
                <h2>Incluir personas</h2>
                <p>Puedes agregar personas al grupo seleccionándolas de la lista, también puedes agregarlas desde el tablero de edición de cada persona.</p>
                <h2>Etiquetas y estados</h2>
                <p>Incluye a clientes que estén marcados con las etiquetas seleccionadas. También puedes incluir sólo a personas activas o inactivas.</p>
                <h2>Variables</h2>
                <p>Los siete campos de información variable sirven para guardar datos genéricos asociados al grupo, utilízalos de acuerdo a las necesidades del modelo.</p>
                <h2>Eliminar</h2>
                <p>Utiliza el botón Eliminar para borrar el grupo en forma definitiva, ten en cuenta que no se borrarán las personas que contenga el grupo.</p>
            </div>}

            {activeBoard=="templates" &&
            <div>
                <h2>Modelos</h2>
                <p>Son plantillas de Word personalizadas que contienen variables, las cuales son reemplazadas por datos dinámicos extraídos de la plataforma.</p>
                <p>Utiliza el buscador para comenzar una búsqueda instantánea o el botón agregar para crear un modelo nuevo.</p>
                <p>Haz clic en un modelo para ver información detallada. El número indica la cantidad de documentos que se pueden generar.</p>
            </div>}

            {activeBoard=="templates-edit" &&
            <div>
                <h2>Agregar o modificar modelos</h2>
                <p>Asigna un nombre identificativo para el modelo.</p>
                <h2>Plantilla de Word</h2>
                <p>Sube un archivo creado con Word que servirá de modelo para generar documentos con la información dinámica asociada a los grupos.</p>
                <p>Dentro de este archivo deben estar indicados los lugares que se reemplazarán por información dinámica.</p>
                <h2>Variables</h2>
                <p>Las variables sirven para definir los datos dinámicos que se agregarán a la plantilla. Cada variable será reemplazada por los datos indicados.</p>
                <p>Utiliza el botón "Más" para agregar variables.</p>
                <h2>Nombre de la variable</h2>
                <p>Este nombre identifica a la variable y debe incluirse dentro del archivo de Word con el formato indicado por la plataforma. Elige nombres cortos, que no se repitan y sin espacios.</p>
                <h2>Reemplazar por</h2>
                <p>Debes definir un formato que se utilizará como base para reemplazar con los datos dinámicos.</p>
                <p>Para ello puedes utilizar los dos grupos de variables disponibles: las que están relacionadas al grupo o las que están relacionadas a las personas.</p>
                <p>Haz clic sobre cada variable para copiarla, luego puedes pegarla en el recuadro.</p>
                <p>Puedes agregar más de una variable, ordenarlas y combinarlas de diferentes formas. Incluso puedes agregar texto o caracteres como puntos, comas, paréntesis, guiones.</p>
                <p>Ejemplo: "last_name, first_name (N° nif)" se reemplazaría por "Martínez, Valentina (N° 16471388F)"</p>
                <p>Define el formato más adecuado de acuerdo a las necesidades del modelo.</p>
                <h2>Otras opciones</h2>
                <p>La opción "En mayúsculas" formatea el texto en letras mayúsculas.</p>
                <h2>Cómo indicar las variables</h2>
                <p>Una vez que hayas creado una variable, debes indicar dentro del archivo de Word la posición en donde aparecerá.</p>
                <p>Para ello, copia el texto indicado (puedes utilizar el botón "Copiar") y pégalo dentro del documento en cualquier parte que desees. Es muy importante que respetes el formato exactamente como se indica.</p>
                <h2>Cómo continuar</h2>
                <p>Una vez que hayas subido el archivo y definido las variables, guarda el modelo. Luego podrás utilizarlo para crear documentos dinámicos con la información de algún grupo.</p>
                <h2>Generar documentos</h2>
                <p>Una vez que hayas creado el modelo, podrás ver el botón "Generar". Haz clic en él para asociar el modelo a un grupo determinado.</p>
                <p>Puedes generar varios documentos asociados a diferentes grupos.</p>
                <h2>Obtener documentos</h2>
                <p>Una vez que hayas generado documentos, los verás en esta lista.</p>
                <p>Haz clic en el botón "Previsualizar" o en el botón "Descargar" para obtener un documento actualizado con los datos dinámicos del grupo agregados en la posición de cada variable del modelo.</p>
                <h2>Eliminar</h2>
                <p>Utiliza el botón Eliminar para borrar el modelo en forma definitiva, ten en cuenta que no se borrarán ni los grupos ni las personas relacionadas.</p>
            </div>}

            {activeBoard=="templates-generate" &&
            <div>
                <h2>Generar documento</h2>
                <p>Escribe un nombre identificativo para el documento.</p>
                <p>Podrás elegir un modelo y asociarlo con la información dinámica de un grupo determinado.</p>
                <p>Una vez que lo hayas generado, lo verás en el listado "Obtener documentos" y desde allí podrás conseguir una copia del modelo con los datos dinámicos reemplazados.</p>
            </div>}

            {activeBoard=="imports-persons" &&
            <div>
                <h2>Importaciones</h2>
                <p>Aquí se guardan las importaciones masivas de personas. Te permite identificar la fecha en que se hizo la importación y las personas que fueron agregadas. Además, puedes deshacer la importación en caso de equivocación.</p>
                <p>Utiliza el buscador para comenzar una búsqueda instantánea.</p>
                <p>Haz clic en una importación para ver información detallada. El número indica la cantidad de personas importadas.</p>
            </div>}

            {activeBoard=="imports-persons-data" &&
            <div>
                <h2>Detalle de la importación</h2>
                <p>Puedes ver el nombre y la fecha de la importación. Además, puedes visualizar un listado con las personas que se importaron.</p>
                <h2>Deshacer</h2>
                <p>Si por algún motivo necesitaras deshacer esta importación, utiliza el botón "Deshacer". De esta forma, se eliminarán todas las personas que se agregaron al momento de la importación. Ten en cuenta que las personas se eliminarán en forma definitiva.</p>
                <h2>Edición</h2>
                <p>Utiliza el botón "Editar" para modificar el nombre de la importación.</p>
            </div>}

            {activeBoard=="imports-persons-edit" &&
            <div>
                <h2>Editar importación</h2>
                <p>Desde aquí puedes modificar el nombre de esta importación.</p>
            </div>}

        </div>
    )
}