import {useContext} from "react"
import LoaderContext from "../contexts/LoaderContext"

export default function Loader(){
    const [loaderContext]=useContext(LoaderContext)
    if(loaderContext.show==1){
        return(
            <div className="loader">
                <div className="spinner"></div>
            </div>
        )
    }
}