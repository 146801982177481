import {Fragment} from "react"
import Item from "../components/Item"
import ItemCheckbox from "../commons/ItemCheckbox"
import ItemsGroup from "./ItemsGroup"

export default function Items({section,selection,setSelection,items,onItem}){

    if(items.length>0){
        return(
            <div className="items">

                {selection.on==1 &&
                <div>
                    <div className="select-all">
                        <ItemCheckbox shown={selection.on} clickEvent={()=>{}} />
                        <div className="select-all-label">Seleccionar todo</div>
                    </div>
                </div>}

                {items.map((item,i)=>{
                    return(
                        <Fragment key={i}>
                            {item.group==1?<ItemsGroup item={item} section={section} />
                            :<Item item={item} section={section} on={onItem} selection={selection} setSelection={setSelection} clickEvent={item.clickEvent} />}
                        </Fragment>
                    )
                })}
                
            </div>
        )
    }else{
        return(
            <div className="items-nothing">Nada por aquí...</div>
        )
    }
}