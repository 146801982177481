import {useContext} from "react"
import GlobalContext from "../contexts/GlobalContext"
import AppsContext from "../contexts/AppsContext"
import useApp from "../hooks/useApp"
import useApi from "../hooks/useApi"
import useSession from "../hooks/useSession"
import useDialog from "../hooks/useDialog"
import Board from "../components/Board"
import BoardBottom from "../components/BoardBottom"
import BoardContent from "../components/BoardContent"
import Items from "../components/Items"
import BottomActions from "../components/BottomActions"

export default function Main({params}){

    const [appsContext]=useContext(AppsContext)
    let app=appsContext.current
    const [globalContext,setGlobalContext]=useContext(GlobalContext)
    const {terminateSession}=useSession()
    const {switcherApp}=useApp()
    const {changeDialog,manageDialog}=useDialog()
    const requestApi=useApi()

    const endThisSession=()=>{
        requestApi("users/session_terminate",{
                session_token_id:globalContext.session_token_id
            })
            .then(data=>{
                if(data.status=="success"){
                    manageDialog("hide")
                    terminateSession()
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    const help=()=>{
        let globalContextNew={...globalContext}
        if(globalContext.help==1){
            globalContextNew["help"]=0
        }else{
            globalContextNew["help"]=1
        }
        setGlobalContext(globalContextNew)
    }

    return(
        <Board params={params} ready={1}>
            <BoardContent>
                {app=="sanpop" &&
                <Items 
                    section="main"
                    selection={0}
                    items={[
                            {id:1,alias:"clients",label:"Clientes"},
                            {id:2,alias:"collections",label:"Grupos"},
                            {id:3,alias:"bufetes",label:"Bufetes"},
                            {id:4,alias:"models",label:"Modelos"},
                            {id:5,alias:"imports",label:"Importaciones"},
                            {id:6,alias:"export",label:"Exportar"}
                        ]}
                    onItem={params.onItem}
                />}
                {app=="models" &&
                <Items 
                    section="main"
                    selection={0}
                    items={[
                            {id:1,alias:"persons",label:"Personas"},
                            {id:2,alias:"assemblages",label:"Grupos"},
                            {id:4,alias:"templates",label:"Modelos"},
                            {id:5,alias:"imports-persons",label:"Importaciones"}
                        ]}
                    onItem={params.onItem}
                />}
            </BoardContent>
            <BoardBottom>
                <BottomActions actions={[
                    {label:"",icon:"exit",clickEvent:()=>{changeDialog("terminate_this_session","",endThisSession)}},
                    {label:"",icon:"apps",clickEvent:()=>{switcherApp()}},
                    {label:"",icon:"help",clickEvent:()=>{help()}}
                ]} />
            </BoardBottom>
        </Board>
    )
}