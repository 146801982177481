import {useState,useEffect,Fragment} from "react"
import {formatDate} from "../hooks/Commons"
import useApi from "../hooks/useApi"
import useBoard from "../hooks/useBoard"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import DataBlock from "../components/DataBlock"
import DataRows from "../components/DataRows"
import DataList from "../components/DataList"
import DataListItem from "../components/DataListItem"
import DataClient from "../components/DataClient"

export default function ImportsData({params}){

    const section="imports-data"
    const requestApi=useApi()
    const [ready,setReady]=useState(0)
    const {loadBoard}=useBoard()
    const {changeDialog,manageDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const [data,setData]=useState("")

    const loadData=()=>{
        requestApi("imports/info",{
                reference:params.reference
            })
            .then(data=>{
                setData(data.data)
                setReady(1)
            })
    }

    useEffect(()=>{
        loadData()
    },[])

    useEffect(()=>{
        loadData()
    },[params.reference])

    const undo=()=>{
        changeDialog("undo_import","",()=>{
            requestApi("imports/undo",{
                    reference:data.id
                })
                .then(data=>{
                    manageDialog("hide")
                    if(data.status=="success"){
                        changeToast("undone_import","")
                        refresh({board:"imports",close:"imports-data"})
                    }else{
                        changeToast("generic_error","")
                    }
                })
        })
    }

    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft={
                    <Fragment>
                        <ButtonAction label="Deshacer" icon="undo" style="alt" clickEvent={undo} />
                    </Fragment>
                }
                actionsRight={<ButtonAction label="Editar" icon="edit" clickEvent={()=>{loadBoard({params:{alias:"imports-edit",level:2,theme:"rabbit",reference:params.reference,mode:"edit"}})}} />} />
            <BoardContent>
                {data &&
                <div className="board-data board-data-xl">
                    <DataBlock title="" style="bluish">
                        <DataRows items={[
                            {label:"Nombre",content:data.name,style:"bold"},
                            {label:"Fecha de importación",content:formatDate(data.created,"semilong"),style:""}
                        ]} />
                    </DataBlock>
                    <DataBlock title={data.clients.quantity+(data.clients.quantity==1?" cliente importado":" clientes importados")} style="white">
                        {data.clients.clients.length>0?
                        <DataList>
                            {data.clients.clients.map((item,i)=>{
                                return(
                                    <DataListItem key={i} left={<DataClient context="imports" item={item} index={i} />} right={""} />
                                )
                            })}
                        </DataList>                        
                        :<div>No se econtraron clientes (podrían haberse eliminado luego de haber realizado esta importación).</div>}
                    </DataBlock>
                </div>}
            </BoardContent>
        </Board>
    )
}