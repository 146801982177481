import {useState,useEffect,Fragment} from "react"
import useApi from "../hooks/useApi"
import useBoard from "../hooks/useBoard"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import DataBlock from "../components/DataBlock"
import DataRows from "../components/DataRows"
import DataList from "../components/DataList"
import DataListItem from "../components/DataListItem"
import DataPerson from "../components/DataPerson"

export default function AssemblagesData({params}){

    const section="assemblages-data"
    const requestApi=useApi()
    const [ready,setReady]=useState(0)
    const {loadBoard}=useBoard()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const [data,setData]=useState("")

    const loadData=()=>{
        requestApi("assemblages/info",{
                reference:params.reference
            })
            .then(data=>{
                setData(data.data)
                setReady(1)
            })
    }

    useEffect(()=>{
        loadData()
    },[])

    useEffect(()=>{
        loadData()
    },[params.reference])

    const saveData=()=>{
        requestApi("assemblages/save_data",{
                id:data["id"],
                persons:data["persons"].persons
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("saved","")
                    refresh({board:"assemblages",close:"assemblages-data"})
                    setTimeout(()=>{
                        loadBoard({params:{alias:"assemblages-data",level:2,theme:"rabbit",reference:params.reference}})
                    },100)
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    const excludePerson=(person)=>{
        const newPersons=data.persons
        if(newPersons.persons[person].excluded==0){
            newPersons.persons[person].excluded=1
        }else{
            newPersons.persons[person].excluded=0
        }
        setData({...data,persons:newPersons})
    }

    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft={
                    <Fragment>
                        <ButtonAction label="Guardar" icon="wink" clickEvent={saveData} />
                    </Fragment>
                }
                actionsRight={<ButtonAction label="Editar" icon="edit" clickEvent={()=>{loadBoard({params:{alias:"assemblages-edit",level:2,theme:"rabbit",reference:params.reference,mode:"edit"}})}} />} />
            <BoardContent>
                {data &&
                <div className="board-data board-data-xl">
                    <DataBlock title="Información" style="bluish">
                        <DataRows items={[
                            {label:"Nombre",content:data.name,style:"bold"},
                            {label:"Estado de las personas",content:data.persons_status=="actives"?"Activas":data.persons_status=="inactives"?"Inactivas":"Todas",style:""},
                            {label:"Variable 1",content:data.variable_1?data.variable_1:"‒",style:""},
                            {label:"Variable 2",content:data.variable_2?data.variable_2:"‒",style:""},
                            {label:"Variable 3",content:data.variable_3?data.variable_3:"‒",style:""},
                            {label:"Variable 4",content:data.variable_4?data.variable_4:"‒",style:""},
                            {label:"Variable 5",content:data.variable_5?data.variable_5:"‒",style:""},
                            {label:"Variable 6",content:data.variable_6?data.variable_6:"‒",style:""},
                            {label:"Variable 7",content:data.variable_7?data.variable_7:"‒",style:""}
                        ]} />
                    </DataBlock>
                    {data.persons.persons.length>0 &&
                    <DataBlock title={data.persons.quantity.persons+(data.persons.quantity.persons==1?" persona":" personas")} style="white">
                        <DataList>
                            {data.persons.persons.map((item,i)=>{
                                return(
                                    <DataListItem key={i} left={<DataPerson context="assemblage" item={item} index={i} excludeEvent={excludePerson} />} right={""} />
                                )
                            })}
                        </DataList>
                    </DataBlock>}
                </div>}
            </BoardContent>
        </Board>
    )
}