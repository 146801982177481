import React,{forwardRef,useRef} from "react"
import useApi from "../hooks/useApi"
import Select from "react-select"
import AsyncSelect from "react-select/async"

const Selector=forwardRef(({options,label,placeholder,multiple,searchable,disabled,style,val,setVal,async,appearance},ref)=>{
    
    const caption=useRef()

    const requestApi=useApi()
    let asyncEndpoint=""
    let asyncDefaultOptions=false
    let asyncGetOptionLabel=""
    let asyncGetOptionValue=""
    if(async){
        if(async.context=="users"){
            asyncEndpoint="users/autocomplete"
            asyncDefaultOptions=false
            asyncGetOptionLabel="full_name"
            asyncGetOptionValue="id"
        }
    }
    const loadOptions=async(keywords)=>{
        const options=await requestApi(asyncEndpoint,{keywords:keywords,context:async.context,not_loader:true})
        return await options.items
    }

    const customStyles={
        control:(provided,state)=>({
            ...provided,
            minHeight:"42px",
            padding:"0px",
            boxSizing:"border-box",
            borderWidth:"2px",
            borderRadius:"5px",
            backgroundColor:state.isFocused?"rgba(97,218,251,0.07) !important":"#E2E2E2",
            borderColor:state.isFocused?"#0066CC !important":"transparent",
            "&:hover":{borderColor:"transparent"},
            boxShadow:"none",
            cursor:"pointer"
        }),
        input:(provided,state)=>({
            ...provided,
            padding:"0px",
            paddingLeft:"12px",
            paddingRight:"12px",
            margin:"0px",
            boxSizing:"border-box",
            border:"none",
            color:"#0066CC",
            fontSize:"16px",
            lineHeight:"18px",
            fontWeight:500,
            caretColor:"#0066CC",
            transition:"0.18s"
        }),
        placeholder:(provided,state)=>({
            ...provided,
            padding:"0px",
            paddingLeft:"12px",
            paddingRight:"12px",
            margin:"0px",
            boxSizing:"border-box",
            color:"#A5A5A5",
            fontSize:"16px",
            lineHeight:"18px",
            fontWeight:400,
            transition:"0.18s"
        }),
        dropdownIndicator:(provided,state)=>({
            ...provided,
            color:state.isFocused?"#0066CC":"#888888",
            "&:hover":state.isFocused?{color:"#0066CC"}:{color:"#888888"}
        }),
        indicatorSeparator:(provided)=>({
            ...provided,
            backgroundColor:"transparent"
        }),
        valueContainer:(provided,state)=>({
            ...provided,
            paddingTop:"2px",
            paddingBottom:"2px",
            paddingLeft:(state.isMulti && state.hasValue)?"3px":"0px",
            paddingRight:"0px",
            margin:"0px",
            boxSizing:"border-box"
        }),
        singleValue:(provided,state)=>({
            ...provided,
            paddingLeft:"12px",
            paddingRight:"12px",
            margin:"0px",
            boxSizing:"border-box",
            color:"#444444",
            fontSize:"16px",
            lineHeight:"18px",
            fontWeight:500
        }),
        multiValue:(provided,state)=>({
            ...provided,
            marginTop:"3px",
            marginBottom:"3px",
            marginRight:"4px",
            boxSizing:"border-box",
            borderRadius:"5px",
            backgroundColor:state.data.color?state.data.color:"rgba(0,0,0,0.1)",
        }),
        multiValueContainer:(provided,state)=>({
            ...provided
        }),
        multiValueLabel:(provided,state)=>({
            ...provided,
            paddingTop:"7px",
            paddingBottom:"6px",
            paddingLeft:"8px",
            paddingRight:"4px",
            margin:"0px",
            boxSizing:"border-box",
            color:state.data.color?"#FFFFFF":"#444444",
            fontSize:"15px",
            lineHeight:"15px",
            fontWeight:500
        }),
        multiValueRemove:(provided,state)=>({
            ...provided,
            paddingLeft:"5px",
            paddingRight:"5px",
            boxSizing:"border-box",
            borderTopRightRadius:"5px",
            borderBottomRightRadius:"5px",
            color:state.data.color?"#FFFFFF":"#444444",
            opacity:0.5,
            transition:"0.18s",
            "&:hover":{backgroundColor:"rgba(0,0,0,0.12)",color:state.data.color?"#FFFFFF":"#0066CC",opacity:1}
        }),
        menu:(provided,state)=>({
            ...provided,
            paddingTop:"0px",
            borderRadius:"5px",
            border:"solid 2px #0066CC",
            boxShadow:"none",
            overflow:"hidden",
            animationName:"selector-anima",
            animationDuration:"0.18s"
        }),
        menuPortal:(provided)=>({
            ...provided,
            zIndex:10,
        }),
        menuList:(provided,state)=>({
            ...provided,
            padding:"0px",
            "&::-webkit-scrollbar":{
                width:"11px",
                height:"11px",
                borderRadius:"30px"
            },
            "&::-webkit-scrollbar-thumb":{
                background:"rgba(0,102,204,0.2)",
                borderRadius:"30px",
                border:"solid 3px #FFFFFF"
            },
            "&::-webkit-scrollbar-thumb:hover":{
                background:"rgba(0,102,204,0.35)"
            },
            "&::-webkit-scrollbar-track":{
                background:"transparent",
                borderRadius:"30px"
            }
        }),
        option:(provided,state)=>({
            ...provided,
            paddingTop:"10px",
            paddingBottom:"10px",
            paddingLeft:"12px",
            paddingRight:"12px",
            boxSizing:"border-box",
            color:state.data.color?state.data.color:"#0066CC",
            fontSize:"16px",
            lineHeight:"22px",
            fontWeight:500,
            backgroundColor:state.isFocused?"transparent":"#FFFFFF",
            "&:hover":{color:"#FFFFFF",backgroundColor:state.data.color?state.data.color:"#0066CC"},
            transition:"0.07s"
        }),
        noOptionsMessage:(provided,state)=>({
            ...provided,
            paddingTop:"16px",
            paddingBottom:"16px",
            paddingLeft:"12px",
            paddingRight:"12px",
            boxSizing:"border-box",
            color:"#0066CC",
            fontSize:"16px",
            lineHeight:"22px",
            fontWeight:500
        }),
        loadingIndicator:(provided,state)=>({
            ...provided,
            color:"#0066CC",
            fontSize:"5px"
        }),
        loadingMessage:(provided,state)=>({
            ...provided,
            paddingTop:"16px",
            paddingBottom:"16px",
            paddingLeft:"12px",
            paddingRight:"12px",
            boxSizing:"border-box",
            color:"#0066CC",
            fontSize:"16px",
            lineHeight:"22px",
            fontWeight:500
        })
    }

    const handleChange=(selectedValue)=>{
        setVal(selectedValue.value)
    }

    return(
        <div className={"field noselect"+(appearance &&" field-"+appearance)}>
            {label!="" && <div ref={caption} className="field-label">{label}</div>}
            {!async?
            <Select 
            ref={ref}
            options={options}
            styles={customStyles}
            placeholder={placeholder}
            isMulti={multiple}
            isDisabled={disabled}
            isClearable={false}
            isSearchable={searchable}
            noOptionsMessage={()=>{return "Sin opciones..."}}
            blurInputOnSelect={true}
            onFocus={()=>{caption.current.classList.add("field-label-on")}}
            onBlur={()=>{caption.current.classList.remove("field-label-on")}}
            onChange={handleChange} />
            :<AsyncSelect 
            ref={ref}
            cacheOptions
            options={options}
            defaultOptions={asyncDefaultOptions}
            loadOptions={loadOptions}
            getOptionLabel={e=>e[asyncGetOptionLabel]}
            getOptionValue={e=>e[asyncGetOptionValue]}
            styles={customStyles}
            placeholder={placeholder}
            isMulti={multiple}
            isDisabled={disabled}
            isClearable={false}
            isSearchable={searchable}
            noOptionsMessage={()=>{return "Escribe para buscar..."}}
            loadingMessage={()=>{return "Buscando..."}}
            blurInputOnSelect={true}
            onFocus={()=>{caption.current.classList.add("field-label-on")}} 
            onBlur={()=>{caption.current.classList.remove("field-label-on")}}
            onChange={handleChange} />}
        </div>
    )
})

export default Selector