import useDialog from "../hooks/useDialog"
import remove from "../assets/remove.svg"

export default function ButtonCommand({label,icon,clickEvent,blocked}){

    const {changeDialog,manageDialog}=useDialog()

    const confirm=()=>{
        if(blocked==true){
            changeDialog("remove_blocked","",()=>{
                clickEvent()
                manageDialog("hide")
            })
        }else{
            changeDialog("remove","",()=>{
                clickEvent()
                manageDialog("hide")
            })
        }
    }

    return(
        <div className="command-button noselect" onClick={confirm}>
            {icon=="remove" && <div className="command-button-icon"><img src={remove} /></div>}
            <div className="command-button-label">{label}</div>
        </div>
    )
}