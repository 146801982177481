import ButtonSmall from "../commons/ButtonSmall"
import wordIcon from "../assets/word.png"
import excelIcon from "../assets/excel.png"
import pdfIcon from "../assets/pdf.png"
import powerpointIcon from "../assets/powerpoint.png"
import zipIcon from "../assets/zip.png"
import unknownIcon from "../assets/unknown.png"

export default function DataFile({item,index,previewEvent,downloadEvent,removeEvent}){
    return(
        <div className="data-file">
            <div className="data-file-name">
                <div className="data-file-icon">
                    <img src={item.extension=="docx"?wordIcon:item.extension=="xlsx"?excelIcon:item.extension=="pdf"?pdfIcon:item.extension=="zip"?zipIcon:item.extension=="pptx"?powerpointIcon:unknownIcon} />
                </div>
                <div className="data-file-title data-medium">{item.title}</div>
            </div>
            <div className="data-file-actions">
                {(item.extension=="docx" || item.extension=="xlsx" || item.extension=="pptx" || item.extension=="pdf") &&
                <ButtonSmall icon="preview" clickEvent={()=>{previewEvent(index)}} />}
                <ButtonSmall icon="download" clickEvent={()=>{downloadEvent(index)}} />
                <ButtonSmall icon="remove" clickEvent={()=>{removeEvent(index)}} />
            </div>
        </div>
    )
}