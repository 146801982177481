function formatAmount(amount,currency,response){
    var components={
        integerPart:0,
        decimalPart:0,
        currencySymbol:currency=="EUR"?"€":""
    }
    components["integerPart"]=parseInt(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g,".")
    components["decimalPart"]=","+(amount-Math.floor(amount)).toFixed(2).toString().split(".")[1]
    if(response=="components"){
        return components
    }else if(response=="display"){
        return components["integerPart"]+components["decimalPart"]+" "+components["currencySymbol"]
    }
}

function formatDate(timestamp,style){
    let date=""
    const jsDate=new Date(timestamp*1000);
    const days=["lunes","martes","miércoles","jueves","viernes","sábado","domingo"]
    const months=["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"]
    const dateDay=jsDate.getDate()
    const dateWeekday=jsDate.getDay()
    const dateMonth=jsDate.getMonth()
    const dateYear=jsDate.getFullYear()
    const dateTime=String(jsDate.getHours()).padStart(2,"0")+":"+String(jsDate.getMinutes()).padStart(2,"0")
    if(style=="long"){
        date=days[dateWeekday]+" "+dateDay+" de "+months[dateMonth]+" de "+dateYear+" a las "+dateTime
    }else if(style=="semilong"){
        date=dateDay+" de "+months[dateMonth]+" de "+dateYear+" ("+dateTime+")"
    }else if(style=="short"){
        date=dateDay+" de "+months[dateMonth]+" de "+dateYear
    }else if(style=="standard"){
        date=String(dateDay).padStart(2,"0")+"/"+String((dateMonth+1)).padStart(2,"0")+"/"+dateYear
    }else if(style=="minimal"){
        date=dateDay+"/"+(dateMonth+1)+"/"+dateYear.toString().substring(2,4)
    }else if(style=="time"){
        date=dateTime
    }
    /*let date=new Intl.DateTimeFormat("es-AR",{
        year:"numeric",
        month:"2-digit",
        day:"2-digit",
        hour:"2-digit",
        minute:"2-digit"}
    ).format(1655541830*1000)*/
    /*var s=new Date(1504095567183).toLocaleDateString("en-US")
    console.log(s)
    //expected output "8/30/2017"  
    var s=new Date(1504095567183).toLocaleTimeString("en-US")
    console.log(s)
    //expected output "3:19:27 PM"*/
    return date
}

function humanDate(timestamp){
    let human=""
    const jsDate=new Date(timestamp*1000);
    const dateDay=jsDate.getDate()
    const dateMonth=jsDate.getMonth()
    const dateYear=jsDate.getFullYear()
    const dateTime=jsDate.getHours()+":"+jsDate.getMinutes()
    const months=["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"]
    const diff=Math.floor(Date.now()/1000)-parseInt(timestamp)
    if(diff<60){
        //Now (less than 60 segundos)
        human="En este momento"
    }else if(diff<120){
        //One minute (less than 120 seconds)
        human="Hace un minuto"
    }else if(diff<300){
        //Few minutes (less than 5 minutes)
        human="Hace algunos minutos"
    }else if(diff<3600){
        //Minutes (within the hour)
        let minutes=Math.round(diff/60)
        human="Hace {MINUTES} minutos"
        human=human.replace("{MINUTES}",minutes)
    }else if(diff<86400){
        //Hours (less than 24 hours)
        let hours=Math.round(diff/3600)
        if(hours==1){
            human="Hace una hora"
        }else{
            human="Hace {HOURS} horas"
            human=human.replace("{HOURS}",hours)
        }
    }else if(diff<172800){
        //Yesterday (less than 2 days)
        human="Ayer a las {TIME}"
        human=human.replace("{TIME}",dateTime)
    }else if(diff<432000){
        //Few days (less than 5 days)
        let days=Math.round(diff/86400)
        human="Hace {DAYS} días"
        human=human.replace("{DAYS}",days)
    }else{
        let today=new Date()
        let year=today.getFullYear()
        if(dateYear==year){
            //Current year
            human="El {DAY} de {MONTH}"
            human=human.replace("{DAY}",dateDay)
            human=human.replace("{MONTH}",months[dateMonth])
        }else{
            //Old years
            human="El {DAY} de {MONTH} de {YEAR}"
            human=human.replace("{DAY}",dateDay)
            human=human.replace("{MONTH}",months[dateMonth])
            human=human.replace("{YEAR}",dateYear)
        }
    }
    return human
}

function timeAgo(timestamp){
    let ago=""
    let diff=Math.floor(Date.now()/1000)-parseInt(timestamp)
    let min_singular=["seg","min","h","d","sem","mes","año","década"]
    let min_plural=["seg","min","h","d","sem","meses","años","décadas"]
    let lengths=["60","60","24","7","4.35","12","10"]
    let j=0
    for(j=0;diff>=lengths[j];j++)diff/=lengths[j]
    let rounded=Math.round(diff)
    if(rounded!=1){
        ago=rounded+" "+min_plural[j]
    }else{
        ago=rounded+" "+min_singular[j]
    }
    if(rounded==0){
        ago="ahora"
    }
    return ago
}

function nl2p(text){
    let array=text.split("\n")
    let tagged=""
    array.forEach(function(element){
        if(element.length>1){
            tagged+="<p>"+element+"</p>"
        }
    })
    return tagged
}

export {formatAmount,formatDate,humanDate,timeAgo,nl2p}