import {useState,useEffect,useContext} from "react"
import ViewerContext from "../contexts/ViewerContext"
import useViewer from "../hooks/useViewer"
import closeImg from "../assets/close.svg"
import ViewerPdf from "../components/ViewerPdf"

export default function Viewer(){

    const [viewerContext]=useContext(ViewerContext)
    const {manageViewer}=useViewer()
    const [url,setUrl]=useState()

    useEffect(()=>{
        if(viewerContext.file!=""){
            manageViewer("show")
        }
        if(viewerContext.extension=="docx" || viewerContext.extension=="xlsx" || viewerContext.extension=="pptx"){
            //setUrl("https://view.officeapps.live.com/op/embed.aspx?src="+viewerContext.file)
            setUrl("https://docs.google.com/viewer?embedded=true&url="+viewerContext.file)
        }
    },[viewerContext])

    return(
        <div id="viewer" className="viewer noselect">
            <div className="viewer-canvas">
                <div className="viewer-frame">
                    <div className="viewer-frame-rounded">
                        {viewerContext.extension=="pdf"?<ViewerPdf url={viewerContext.file} />
                        :<iframe frameBorder="0" src={url}></iframe>}
                    </div>
                </div>
                <div className="viewer-close" onClick={()=>{manageViewer("hide")}}>
                    <img src={closeImg} />
                </div>
            </div>
        </div>
    )
}