import {useState,useRef,useEffect} from "react"
import useApi from "../hooks/useApi"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import Textbox from "../commons/Textbox"

export default function ImportsPersonsEdit({params}){

    const requestApi=useApi()
    const [ready,setReady]=useState(0)
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const mode=params.mode
    const [idVal,setIdVal]=useState(0)
    const [defaultData,setDefaultData]=useState("")
    const nameInput=useRef()
    const [nameVal,setNameVal]=useState()

    const loadData=()=>{
        setReady(0)
        if(mode=="edit"){
            requestApi("imports_persons/info",{
                    reference:params.reference
                })
                .then(data=>{
                    setReady(1)
                    setDefaultData(data.data)
                })
        }else{
            setDefaultData("")
            setReady(1)
        }
    }

    useEffect(()=>{
        loadData()
    },[params.reference])

    useEffect(()=>{
        if(mode=="edit"){
            setIdVal(defaultData.id)
            setNameVal(defaultData.name)
        }else{
            setIdVal(0)
            setNameVal("")
        }
    },[defaultData])

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            requestApi("imports_persons/save",{
                    id:idVal,
                    mode:mode,
                    name:nameVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        if(mode=="create"){
                            changeToast("created_f","")
                        }else if(mode=="edit"){
                            changeToast("saved","")
                        }
                        refresh({board:"imports-persons",close:"imports-persons-edit"})
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft={""}
                actionsRight={<ButtonAction label="Guardar" icon="wink" clickEvent={saveData} />} />
            <BoardContent>
                <div className="board-data">
                    <Textbox ref={nameInput} type="text" label="Nombre" val={nameVal} setVal={setNameVal} disabled={false} />            
                </div>
            </BoardContent>
        </Board>
    )
}