import {Fragment,useState,useRef,useEffect} from "react"
import {CopyToClipboard} from "react-copy-to-clipboard"
import useApi from "../hooks/useApi"
import useBoard from "../hooks/useBoard"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import useViewer from "../hooks/useViewer"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import Textbox from "../commons/Textbox"
import Selector from "../commons/Selector"
import Switch from "../commons/Switch"
import Uploader from "../commons/Uploader"
import Divisor from "../commons/Divisor"
import ButtonCommand from "../commons/ButtonCommand"
import DataBlock from "../components/DataBlock"
import DataList from "../components/DataList"
import DataListItem from "../components/DataListItem"
import ButtonSmall from "../commons/ButtonSmall"
import ItemListEdit from "../commons/ItemListEdit"

export default function ModelsEdit({params}){

    const requestApi=useApi()
    const [ready,setReady]=useState(0)
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {loadBoard}=useBoard()
    const {refresh}=useRefresh()
    const {changeViewer}=useViewer()

    const mode=params.mode
    const [idVal,setIdVal]=useState(0)
    const [defaultData,setDefaultData]=useState("")
    const nameInput=useRef()
    const [nameVal,setNameVal]=useState("")
    const templateInput=useRef()
    const [template,setTemplate]=useState()
    const [variables,setVariables]=useState([])
    const [modelsGenerated,setModelsGenerated]=useState([])
    const [editMode,setEditMode]=useState(0)
    const [editIndex,setEditIndex]=useState("")
    const itemNameInput=useRef()
    const [itemNameVal,setItemNameVal]=useState("")
    const itemReplaceValueInput=useRef()
    const [itemReplaceValueVal,setItemReplaceValueVal]=useState("")
    const itemReplaceValueOptions=[
        {label:"Nombre apellido",value:"first_name,last_name"},
        {label:"Apellido nombre",value:"last_name,first_name"},
        {label:"Nombre",value:"first_name"},
        {label:"Apellido",value:"last_name"},
        {label:"NIF",value:"nif"},
        {label:"Nombre apellido (NIF)",value:"first_name,last_name,nif"},
        {label:"Apellido nombre (NIF)",value:"last_name,first_name,nif"},
        {label:"Nombre (NIF)",value:"first_name,nif"},
        {label:"Apellido (NIF)",value:"last_name,nif"},
        {label:"Nombre apellido (Monto reclamable)",value:"first_name,last_name,claimable"},
        {label:"Apellido nombre (Monto reclamable)",value:"last_name,first_name,claimable"},
        {label:"Nombre apellido (Monto reclamable global)",value:"first_name,last_name,claimable_global"},
        {label:"Apellido nombre (Monto reclamable global)",value:"last_name,first_name,claimable_global"},
        {label:"Cantidad de clientes",value:"quantity"},
        {label:"Monto reclamable",value:"claimable"},
        {label:"Monto reclamable global",value:"claimable_global"}
    ]
    const [uppercaseVal,setUppercaseVal]=useState(0)
    const [includeSubclientsVal,setIncludeSubclientsVal]=useState(1)

    const loadData=()=>{
        setReady(0)
        if(mode=="edit"){
            requestApi("models/info",{
                    reference:params.reference
                })
                .then(data=>{
                    setDefaultData(data.data)
                    setReady(1)
                })
        }else{
            setDefaultData("")
            setReady(1)
        }
    }

    useEffect(()=>{
        loadData()
    },[])

    useEffect(()=>{
        loadData()
    },[params.reference])

    useEffect(()=>{
        if(mode=="edit"){
            setIdVal(defaultData.id)
            setNameVal(defaultData.name)
            setTemplate(defaultData.template)
            setVariables(defaultData.variables)
            setModelsGenerated(defaultData.models_generated)
            setEditIndex("")
        }else{
            setIdVal(0)
            setNameVal("")
            setTemplate("")
            setVariables([])
            setModelsGenerated([])
            setEditIndex("")
        }
        setEditMode(0)
    },[defaultData])

    const addVariable=()=>{
        setItemNameVal("")
        setUppercaseVal(0)
        setIncludeSubclientsVal(1)
        setItemReplaceValueVal("")
        setEditMode(1)
        setEditIndex("")
    }

    const editVariable=(i)=>{
        setEditIndex(i)
        setEditMode(1)
        setTimeout(()=>{
            setItemNameVal(variables[i]["name"])
            setUppercaseVal(variables[i]["uppercase"])
            setIncludeSubclientsVal(variables[i]["subclients"])
            itemReplaceValueInput.current.setValue(itemReplaceValueOptions.find(o=>o.value===variables[i]["replace_value"]))
        },100)
    }

    const removeVariable=(i)=>{
        const newVariables=[...variables]
        newVariables.splice(i,1)
        setVariables(newVariables)
    }

    const saveVariable=()=>{
        if(itemNameVal==""){
            itemNameInput.current.focus()
        }else{
            if(itemReplaceValueInput.current.getValue().length==0){
                itemReplaceValueInput.current.focus()
            }else{
                const newVariables=[...variables]
                if(editIndex===""){
                    let newVariable={
                        id:0,
                        name:itemNameVal,
                        replace_value:itemReplaceValueInput.current.getValue()[0].value,
                        uppercase:uppercaseVal,
                        subclients:includeSubclientsVal
                    }
                    newVariables.push(newVariable)
                }else{
                    newVariables[editIndex]["name"]=itemNameVal
                    newVariables[editIndex]["replace_value"]=itemReplaceValueInput.current.getValue()[0].value
                    newVariables[editIndex]["uppercase"]=uppercaseVal
                    newVariables[editIndex]["subclients"]=includeSubclientsVal
                }
                setVariables(newVariables)
                setEditMode(0)
                setEditIndex("")
            }
        }
    }

    const cancelVariable=()=>{
        setItemNameVal("")
        setUppercaseVal(0)
        setIncludeSubclientsVal(1)
        setItemReplaceValueVal("")
        setEditMode(0)
        setEditIndex("")
    }

    const generateDocument=(context,i)=>{
        requestApi("models/generate",{
                reference:modelsGenerated[i]["id"],
                context:context
            })
            .then(data=>{
                if(data.status=="success"){
                    if(context=="preview"){
                        changeViewer(data.data.url,data.data.extension)
                    }else if(context=="download"){
                        let link=document.createElement("a")
                        link.setAttribute("download","")
                        link.href=data.data.url
                        document.body.appendChild(link)
                        link.click()
                        link.remove()
                    }
                }else{
                    changeToast("generic_error","")
                }
            })
    }

    const removeDocument=(i)=>{
        const newModelsGenerated=[...modelsGenerated]
        newModelsGenerated.splice(i,1)
        setModelsGenerated(newModelsGenerated)
    }

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            requestApi("models/save",{
                    id:idVal,
                    mode:mode,
                    name:nameVal,
                    template:template,
                    variables:variables,
                    models_generated:modelsGenerated
                })
                .then(data=>{
                    if(data.status=="success"){
                        if(mode=="create"){
                            changeToast("created_m","")
                        }else if(mode=="edit"){
                            changeToast("saved","")
                        }
                        refresh({board:"models",close:"models-edit"})
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const remove=()=>{
        requestApi("models/remove",{
                reference:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    refresh({board:"models",close:"models-edit"})
                }else{
                    changeToast("generic_error","")
                }
            })
    }

    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft={mode=="edit" && <ButtonAction label="Generar" icon="wand" style="alt" clickEvent={()=>{loadBoard({params:{alias:"models-generate",level:2,theme:"rabbit",mode:"create",reference:idVal}})}} />}
                actionsRight={<ButtonAction label="Guardar" icon="wink" clickEvent={saveData} />} />
            <BoardContent>
                <div className="board-data">
                    <Textbox ref={nameInput} type="text" label="Nombre" val={nameVal} setVal={setNameVal} disabled={false} />
                    <Divisor />
                    <Uploader ref={templateInput} context="models-template" val={template} setVal={setTemplate} label="Subir plantilla Word" labelUploading="Subiendo plantilla" restrict={["application/vnd.openxmlformats-officedocument.wordprocessingml.document"]} multiple={false} viewer={true} />
                    <DataBlock title="Variables" style="white">
                        {variables &&
                        <DataList>
                            {variables.map((item,i)=>{
                                return(
                                    <DataListItem key={i}
                                        left={
                                            <Fragment>
                                                <div className="data-medium data-colored">{item.name?item.name:"‒"}</div>
                                                <div>Se reemplaza por <span className="data-medium">{itemReplaceValueOptions.find(o=>o.value===item.replace_value).label}</span></div>
                                                <div>{item.subclients==1?"Incluye subclientes.":"No incluye subclientes."} {item.uppercase==1?"Se convierte a mayúsculas.":""}</div>
                                                <div className="data-colored">Agregar en la plantilla <span className="data-medium">$&#123;{item.name}&#125;</span></div>
                                            </Fragment>
                                        }
                                        right={
                                            <div className="data-actions">
                                                <CopyToClipboard className={"copy-button-"+i} text={"${"+item.name+"}"} onCopy={()=>{document.getElementsByClassName("copy-button-"+i)[0].click();changeToast("var_copied","")}}>
                                                    <div><ButtonSmall icon="copy" clickEvent={()=>{}} /></div>
                                                </CopyToClipboard>
                                                <ButtonSmall icon="edit" clickEvent={()=>{editVariable(i)}} />
                                                <ButtonSmall icon="remove" clickEvent={()=>{removeVariable(i)}} />
                                            </div>
                                        }
                                    />
                                )
                            })}
                            {editMode==1 &&
                            <DataListItem left={
                                <ItemListEdit>
                                    <div className="item-list-edit-half">
                                        <Textbox ref={itemNameInput} type="text" appearance="compact" label="Nombre" val={itemNameVal} setVal={setItemNameVal} disabled={false} />
                                        <Selector ref={itemReplaceValueInput} appearance="compact" options={itemReplaceValueOptions} label="Reemplazar por" placeholder="" multiple={false} searchable={false} style="field" val={itemReplaceValueVal} setVal={setItemReplaceValueVal} />
                                    </div>
                                    <div className="item-list-edit-half">
                                        <Switch onLabel="Incluir personas asoc." offLabel="Incluir personas asoc." style="mini" val={[includeSubclientsVal,setIncludeSubclientsVal]} clickEvent="" />
                                        <Switch onLabel="En mayúsculas" offLabel="En mayúsculas" style="mini" val={[uppercaseVal,setUppercaseVal]} clickEvent="" />
                                    </div>
                                </ItemListEdit>
                            } right={
                                <div className="item-list-edit-actions">
                                    <div>
                                        <ButtonSmall icon="cancel" clickEvent={()=>{cancelVariable()}} />
                                        <ButtonSmall icon="confirm" style="confirm" clickEvent={()=>{saveVariable()}} />
                                    </div>
                                </div>
                            } />}
                            <DataListItem left={<ButtonSmall icon="add" style="alt" clickEvent={()=>{addVariable()}} />} right="" />
                        </DataList>}
                    </DataBlock>
                    {(modelsGenerated && modelsGenerated.length>0) &&
                    <DataBlock title="Obtener documentos" style="white">
                        <DataList>
                            {modelsGenerated.map((item,i)=>{
                                return(
                                    <DataListItem key={i}
                                        left={
                                            <Fragment>
                                                <div className="data-medium data-colored">{item.name?item.name:"‒"}</div>
                                                <div>{item.collection_name?item.collection_name:"‒"}</div>
                                            </Fragment>
                                        }
                                        right={
                                            <div className="data-actions">
                                                <ButtonSmall icon="preview" clickEvent={()=>{generateDocument("preview",i)}} />
                                                <ButtonSmall icon="download" clickEvent={()=>{generateDocument("download",i)}} />
                                                <ButtonSmall icon="remove" clickEvent={()=>{removeDocument(i)}} />
                                            </div>
                                        }
                                    />
                                )
                            })}
                        </DataList>
                    </DataBlock>}
                    {mode=="edit" &&
                    <div className="commands-block">
                        <ButtonCommand label="Eliminar" icon="remove" clickEvent={remove} />
                    </div>}
                </div>
            </BoardContent>
        </Board>
    )
}