import {useState,useEffect,useRef} from "react"
import {useSearchParams,useNavigate} from "react-router-dom"
import useSession from "../hooks/useSession"
import useApi from "../hooks/useApi"
import useDialog from "../hooks/useDialog"
import EntranceLayout from "../components/EntranceLayout"
import EntranceSection from "../components/EntranceSection"
import EntranceTextbox from "../commons/EntranceTextbox"
import EntranceButton from "../commons/EntranceButton"

export default function PasswordCreate(){

    const {validateSession}=useSession()
    useEffect(()=>{
        validateSession()
    },[])
    const requestApi=useApi()
    const {changeDialog}=useDialog()
    const [searchParams]=useSearchParams()
    let token=""
    if(searchParams.get("t")!=null){
        token=searchParams.get("t")
    }
    const navigate=useNavigate()
    const [tokenStatus,setTokenStatus]=useState("")
    const [passwordVal,setPasswordVal]=useState("")
    const [rePasswordVal,setRePasswordVal]=useState("")
    const passwordInput=useRef()
    const rePasswordInput=useRef()
    
    useEffect(()=>{
        requestApi("users/password_reset_request",{
                reset_token:token
            })
            .then(data=>{
                if(data.status=="valid_token"){
                    setTokenStatus("valid_token")
                }else if(data.status=="missed_token"){
                    setTokenStatus("missed_token")
                }else if(data.status=="expired_token"){
                    setTokenStatus("expired_token")
                }
            })
    },[])

    const changePassword=(event)=>{
        event.preventDefault()
        if(passwordVal==""){
            passwordInput.current.focus()
        }else{
            if(rePasswordVal==""){
                rePasswordInput.current.focus()
            }else{
                if(passwordVal!=rePasswordVal){
                    changeDialog("password_mismatch","","")
                }else{
                    if(passwordVal.length<7){
                        changeDialog("password_length","","")
                    }else{
                        requestApi("users/password_reset",{
                                context:"create",
                                reset_token:token,
                                new_password:passwordVal
                            })
                            .then(data=>{
                                if(data.status=="success"){
                                    setTokenStatus("changed")
                                }else if(data.status=="failed"){
                                    changeDialog("generic_error","","")
                                }
                            })
                    }
                }
            }
        }
    }

    return(
        <EntranceLayout>
            {tokenStatus=="valid_token" &&
            <EntranceSection title="Crear contraseña" caption="Para continuar, elige una clave.">
                <form>
                    <div className="entrance-fields">
                        <EntranceTextbox ref={passwordInput} type="password" placeholder="Contraseña" val={passwordVal} onChange={(event)=>{setPasswordVal(event.target.value)}} />
                        <EntranceTextbox ref={rePasswordInput} type="password" placeholder="Reescribir contraseña" val={rePasswordVal} onChange={(event)=>{setRePasswordVal(event.target.value)}} />
                    </div>
                    <EntranceButton label="Crear contraseña" clickEvent={changePassword} />
                </form>
            </EntranceSection>}
            {tokenStatus=="missed_token" &&
            <EntranceSection title="Algo no anduvo bien" caption="No pudimos validar el enlace para crear la contraseña. Revisa que el link sea exactamente igual al que te enviamos por correo."></EntranceSection>}
            {tokenStatus=="expired_token" &&
            <EntranceSection title="Algo no anduvo bien" caption="El enlace para crear la contraseña ya no puede utilizarse. Asegúrate de ingresar al link a los pocos minutos de recibir el correo.">
                <EntranceButton label="Reintentar" clickEvent={()=>{navigate("/")}} />
            </EntranceSection>}
            {tokenStatus=="changed" &&
            <EntranceSection title="¡Contraseña actualizada!" caption="Ya puedes ingresar a tu cuenta con la nueva contraseña.">
                <EntranceButton label="Entrar" clickEvent={()=>{navigate("/")}} />
            </EntranceSection>}
        </EntranceLayout>
    )
}