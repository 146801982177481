import {Fragment} from "react"
import Dialog from "./Dialog"
import brandImg from "../assets/brand.svg"

export default function EntranceLayout({children}){
    return(
        <Fragment>
            <div className="entrance-layout noselect">
                <div className="entrance-brand">
                    <img src={brandImg} />
                </div>
                {children}
            </div>
            <Dialog />
        </Fragment>
    )
}