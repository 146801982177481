import {useState,useEffect} from "react"
import {BrowserRouter,Route,Routes} from "react-router-dom"
import GlobalContext from "./contexts/GlobalContext"
import LoaderContext from "./contexts/LoaderContext"
import AppsContext from "./contexts/AppsContext"
import DialogContext from "./contexts/DialogContext"
import ViewerContext from "./contexts/ViewerContext"
import ToastContext from "./contexts/ToastContext"
import Entrance from "./views/Entrance"
import NotFound from "./views/NotFound"
import PasswordCreate from "./views/PasswordCreate"
import PasswordReset from "./views/PasswordReset"
import Verification from "./views/Verification"
import "./App.css"
import Dashboard from "./views/Dashboard"

export default function App(){

    const [globalContext,setGlobalContext]=useState({
        initiated:false,
        refresh:0,
        loading:false,
        //base_url:"http://localhost:3000/",
        //api_url:"https://localhost/api/",
        base_url:"https://ius-santander.duckdns.org/",
        api_url:"https://ius-santander.duckdns.org/api/",
        user_id:0,
        user_name:"",
        user_capitals:"",
        user_has_picture:0,
        session_token:"",
        session_token_id:0,
        help:0
    })

    const [appsContext,setAppsContext]=useState({
        current:"sanpop",
        switcher:"hidden",
        boards:[],
        apps:[
            {id:1,alias:"sanpop",name:"Santander",color:"blue"},
            {id:2,alias:"models",name:"Modelos",color:"green"}
        ]
    })

    const [dialogContext,setDialogContext]=useState({
        dialog:"",
        reference:"",
        action:"",
    })

    const [toastContext,setToastContext]=useState({
        toast:""
    })

    const [viewerContext,setViewerContext]=useState({
        file:"",
        extension:""
    })

    const [loaderContext,setLoaderContext]=useState({
        show:0
    })

    useEffect(()=>{

        //Detect touch (mouse hover event)
        var isTouch=false
        var isTouchTimer
        var touchClass=""

        //Touch events on Safari
        document.addEventListener("touchstart",function(){},true)

        //Detect touch input
        document.addEventListener("touchstart",function(event){
            clearTimeout(isTouchTimer)
            isTouch=true
            if(touchClass!=="touch"){
                touchClass="touch"
                document.body.className="touch"
            }
            isTouchTimer=setTimeout(function(){isTouch=false},2000)
        },false)

        //Detect all inputs (touch, mouse, trackpad)
        document.addEventListener("mouseover",function(event){
            if(!isTouch && touchClass==="touch"){
                isTouch=false
                touchClass=""
                document.body.className=""
            }
        },false)

        //Service worker
        if("serviceWorker" in navigator){
            navigator.serviceWorker.register("sw.js",{
                scope:"."
            }).then(function(registration){
                //Registration was successful
            },function(err){
                //Registration failed
            })
        }

    },[])

    return (
        <GlobalContext.Provider value={[globalContext,setGlobalContext]}>
        <LoaderContext.Provider value={[loaderContext,setLoaderContext]}>
        <AppsContext.Provider value={[appsContext,setAppsContext]}>
        <ViewerContext.Provider value={[viewerContext,setViewerContext]}>
        <DialogContext.Provider value={[dialogContext,setDialogContext]}>
        <ToastContext.Provider value={[toastContext,setToastContext]}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Entrance />} />
                    <Route path="/activar" element={<PasswordCreate />} />
                    <Route path="/resetear" element={<PasswordReset />} />
                    <Route path="/verificar" element={<Verification />} />
                    <Route path="/tablero" element={<Dashboard />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </ToastContext.Provider>
        </DialogContext.Provider>
        </ViewerContext.Provider>
        </AppsContext.Provider>
        </LoaderContext.Provider>
        </GlobalContext.Provider>
    )
}