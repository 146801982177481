import {useEffect,useRef} from "react"
import WebViewer from "@pdftron/pdfjs-express-viewer"

export default function ViewerPdf({url}){

    const viewer=useRef(null);

    useEffect(()=>{
        console.log(url)
        WebViewer({
                path:"/pdfexpress",
                initialDoc:url,
                licenseKey:"MJTSN9hCcnRNiawpfhTY",
                //licenseKey:"VMeLR5MsW5lX3X9YfqQF",
                disabledElements:["select"],
                enableAnnotations:false
            },
            viewer.current
        ).then((instance)=>{
            instance.setLanguage("es")
            instance.UI.setTheme(instance.UI.Theme.DARK)
        })
    },[])

    return(
        <div className="webviewer" ref={viewer}></div>
    )
}
