import {useRef} from "react"

export default function Finder({keyword,setKeyword}){

    const finder=useRef()
    const input=useRef()

    const focus=()=>{
        finder.current.classList.add("finder-on")
    }

    const blur=()=>{
        if(input.current.value==""){
            finder.current.classList.remove("finder-on")
        }
    }

    const change=event=>{
        setKeyword(event.target.value)
    }

    return(
        <div ref={finder} className="finder">
            <input ref={input} className="finder-box" type="text" value={keyword} onChange={change} onFocus={focus} onBlur={blur} />
            <svg className="finder-icon" id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42"><g id="c"><g><path d="M28.36,30c-.28,0-.56-.12-.76-.35l-3.4-3.94c-1.36,.9-2.96,1.32-4.62,1.21-1.99-.14-3.81-1.04-5.12-2.55-1.31-1.51-1.95-3.43-1.81-5.42s1.04-3.81,2.55-5.12c3.11-2.7,7.83-2.37,10.54,.74,1.31,1.51,1.95,3.43,1.81,5.42-.12,1.66-.76,3.19-1.84,4.41l3.4,3.94c.36,.42,.31,1.05-.1,1.41-.19,.16-.42,.24-.65,.24ZM20.1,14c-1.32,0-2.58,.47-3.58,1.34-1.1,.96-1.77,2.29-1.87,3.75-.1,1.46,.37,2.87,1.33,3.97,.96,1.1,2.29,1.77,3.75,1.87,1.46,.1,2.87-.37,3.97-1.33,1.1-.96,1.77-2.29,1.87-3.75s-.37-2.87-1.33-3.97c-.96-1.1-2.29-1.77-3.75-1.87-.13,0-.26-.01-.39-.01Z" fill="#fff"/><rect width="42" height="42" fill="none"/></g></g></svg>
        </div>
    )
}