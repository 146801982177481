import Avatar from "../commons/Avatar"

export default function EntranceSection({title,caption,avatar,children}){
    return(
        <div className="entrance-focused">
            <div className="entrance">
                {avatar!=undefined && <Avatar context="entrance" id={avatar.id} picture={avatar.picture} capitals={avatar.capitals} />}
                <div className="entrance-title">{title}</div>
                {caption!="" && <div className="entrance-caption" dangerouslySetInnerHTML={{__html:caption}}></div>}
                {children}
            </div>
        </div>
    )
}