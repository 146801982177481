import {useState,useEffect} from "react"
import {useSearchParams,useNavigate} from "react-router-dom"
import EntranceLayout from "../components/EntranceLayout"
import EntranceSection from "../components/EntranceSection"
import EntranceButton from "../commons/EntranceButton"
import useApi from "../hooks/useApi"

export default function Verification(){

    const [searchParams]=useSearchParams()
    let token=""
    if(searchParams.get("t")!=null){
        token=searchParams.get("t")
    }
    const navigate=useNavigate()
    const [tokenStatus,setTokenStatus]=useState("")
    const requestApi=useApi()
    
    useEffect(()=>{
        requestApi("users/email_verification",{
                verification_token:token
            })
            .then(data=>{
                if(data.status=="success"){
                    setTokenStatus("success")
                }else if(data.status=="missed_token"){
                    setTokenStatus("missed_token")
                }else if(data.status=="already_verified"){
                    setTokenStatus("already_verified")
                }
            })
    },[])

    return(
        <EntranceLayout>
            {tokenStatus=="success" &&
            <EntranceSection title="Email verificado correctamente" caption="Puedes seguir usando Ius+Aequitas con normalidad.">
                <EntranceButton label="Entrar" clickEvent={()=>{navigate("/")}} />
            </EntranceSection>}
            {tokenStatus=="missed_token" &&
            <EntranceSection title="Algo no anduvo bien" caption="No pudimos validar el enlace para verificar tu cuenta de email. Revisa que el link sea exactamente igual al que te enviamos por correo."></EntranceSection>}
            {tokenStatus=="already_verified" &&
            <EntranceSection title="¡Listo!" caption="La dirección de email ya estaba verificada. Puedes seguir usando Ius+Aequitas con normalidad.">
                <EntranceButton label="Entrar" clickEvent={()=>{navigate("/")}} />
            </EntranceSection>}
        </EntranceLayout>
    )
}