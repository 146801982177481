import {useState,useRef,useEffect} from "react"
import useApi from "../hooks/useApi"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import Divisor from "../commons/Divisor"
import Textbox from "../commons/Textbox"
import Selector from "../commons/Selector"
import Switch from "../commons/Switch"
import ButtonCommand from "../commons/ButtonCommand"

export default function AssemblagesEdit({params}){

    const section="assemblages-edit"
    const requestApi=useApi()
    const [ready,setReady]=useState(0)
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const mode=params.mode
    const [idVal,setIdVal]=useState(0)
    const [defaultData,setDefaultData]=useState("")
    const nameInput=useRef()
    const [nameVal,setNameVal]=useState("")
    const personsInput=useRef()
    const [personsVal,setPersonsVal]=useState("")
    const [personsOptions,setPersonsOptions]=useState([])
    const personStatusInput=useRef()
    const [personStatusVal,setPersonStatusVal]=useState("")
    const personStatusOptions=[
        {label:"Todas",value:""},
        {label:"Activas",value:"actives"},
        {label:"Inactivas",value:"inactives"}
    ]
    const tagsInput=useRef()
    const [tagsVal,setTagsVal]=useState("")
    const tagsOptions=[
        {label:"Rojo",value:"1",color:"#FF3B2F"},
        {label:"Amarillo",value:"2",color:"#FFD207"},
        {label:"Fucsia",value:"3",color:"#FC5CB9"},
        {label:"Verde",value:"4",color:"#4CDA64"},
        {label:"Violeta",value:"5",color:"#9C71DA"},
        {label:"Naranja",value:"6",color:"#FF9501"},
        {label:"Azulado",value:"7",color:"#67899B"}
    ]
    const variable1Input=useRef()
    const variable2Input=useRef()
    const variable3Input=useRef()
    const variable4Input=useRef()
    const variable5Input=useRef()
    const variable6Input=useRef()
    const variable7Input=useRef()
    const [variable1Val,setVariable1Val]=useState("")
    const [variable2Val,setVariable2Val]=useState("")
    const [variable3Val,setVariable3Val]=useState("")
    const [variable4Val,setVariable4Val]=useState("")
    const [variable5Val,setVariable5Val]=useState("")
    const [variable6Val,setVariable6Val]=useState("")
    const [variable7Val,setVariable7Val]=useState("")
    const [statusVal,setStatusVal]=useState(1)
   
    const loadPersons=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("persons/list",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(item){
                            newOptions.push({label:item.last_name+" "+item.first_name,value:item.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadData=()=>{
        return new Promise(function(resolve){
            if(mode=="edit"){
                resolve(
                    requestApi("assemblages/info",{
                            reference:params.reference
                        })
                        .then(data=>{
                            return data.data
                        })
                )
            }else{
                resolve(
                    ()=>{return ""}
                )
            }
        })
    }

    useEffect(()=>{
        load()
    },[params.reference])

    const load=()=>{
        Promise.all([loadPersons,loadData].map(func=>func())).then((result)=>{
            setPersonsOptions(result[0])
            setDefaultData(result[1])
            setReady(1)
        })
    }

    useEffect(()=>{
        if(mode=="edit" && ready){
            setIdVal(defaultData.id)
            setNameVal(defaultData.name)
            if(defaultData["persons_all"].length>0){
                let newPersonsValue=[]
                personsOptions.forEach(function(item){
                    defaultData["persons_all"].forEach(function(person){
                        if(person==item.value){
                            newPersonsValue.push({label:item.label,value:item.value})
                        }
                    })
                })
                personsInput.current.setValue(newPersonsValue)
            }
            if(defaultData.persons_status!=""){
                personStatusOptions.forEach(function(item){
                    if(item.value==defaultData.persons_status){
                        personStatusInput.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(defaultData["tags"].length>0){
                let newTagsValue=[]
                tagsOptions.forEach(function(item){
                    defaultData["tags"].forEach(function(tag){
                        if(tag==item.value){
                            newTagsValue.push({label:item.label,value:item.value,color:item.color})
                        }
                    })
                })
                tagsInput.current.setValue(newTagsValue)
            }
            setVariable1Val(defaultData.variable_1)
            setVariable2Val(defaultData.variable_2)
            setVariable3Val(defaultData.variable_3)
            setVariable4Val(defaultData.variable_4)
            setVariable5Val(defaultData.variable_5)
            setVariable6Val(defaultData.variable_6)
            setVariable7Val(defaultData.variable_7)
            setStatusVal(defaultData.status)
        }else{
            setNameVal("")
            setPersonsVal("")
            setPersonStatusVal("")
            setTagsVal("")
            setVariable1Val("")
            setVariable2Val("")
            setVariable3Val("")
            setVariable4Val("")
            setVariable5Val("")
            setVariable6Val("")
            setVariable7Val("")
            setStatusVal(1)
        }
    },[defaultData])

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            requestApi("assemblages/save",{
                    id:idVal,
                    mode:mode,
                    name:nameVal,
                    persons:personsInput.current.getValue(),
                    persons_status:personStatusInput.current.getValue()[0]?personStatusInput.current.getValue()[0].value:"",
                    tags:tagsInput.current.getValue(),
                    variable_1:variable1Val,
                    variable_2:variable2Val,
                    variable_3:variable3Val,
                    variable_4:variable4Val,
                    variable_5:variable5Val,
                    variable_6:variable6Val,
                    variable_7:variable7Val,
                    status:statusVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        if(mode=="create"){
                            changeToast("created_f","")
                        }else if(mode=="edit"){
                            changeToast("saved","")
                        }
                        refresh({board:"assemblages",close:"assemblages-edit"})
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const remove=()=>{
        requestApi("assemblages/remove",{
                reference:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    refresh({board:"assemblages",close:"assemblages-edit"})
                }else{
                    changeToast("generic_error","")
                }
            })
    }

    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft={""}
                actionsRight={<ButtonAction label="Guardar" icon="wink" clickEvent={saveData} />} />
            <BoardContent>
                <div className="board-form">
                    <Textbox ref={nameInput} type="text" label="Nombre del grupo" val={nameVal} setVal={setNameVal} disabled={false} />
                    <Divisor />
                    <Selector ref={personsInput} options={personsOptions} label="Personas" placeholder="" multiple={true} searchable={true} style="field" val={personsVal} setVal={setPersonsVal} />
                    <Divisor />
                    <Selector ref={tagsInput} options={tagsOptions} label="Etiquetas" placeholder="" multiple={true} searchable={false} style="field" val={tagsVal} setVal={setTagsVal} />
                    <Selector ref={personStatusInput} options={personStatusOptions} label="Estado de las personas" placeholder="" multiple={false} searchable={false} style="field" val={personStatusVal} setVal={setPersonStatusVal} />
                    <Divisor />
                    <Textbox ref={variable1Input} type="text" label="Variable 1" val={variable1Val} setVal={setVariable1Val} disabled={false} />
                    <Textbox ref={variable2Input} type="text" label="Variable 2" val={variable2Val} setVal={setVariable2Val} disabled={false} />
                    <Textbox ref={variable3Input} type="text" label="Variable 3" val={variable3Val} setVal={setVariable3Val} disabled={false} />
                    <Textbox ref={variable4Input} type="text" label="Variable 4" val={variable4Val} setVal={setVariable4Val} disabled={false} />
                    <Textbox ref={variable5Input} type="text" label="Variable 5" val={variable5Val} setVal={setVariable5Val} disabled={false} />
                    <Textbox ref={variable6Input} type="text" label="Variable 6" val={variable6Val} setVal={setVariable6Val} disabled={false} />
                    <Textbox ref={variable7Input} type="text" label="Variable 7" val={variable7Val} setVal={setVariable7Val} disabled={false} />
                    <Divisor />
                    <Switch onLabel="Activa" offLabel="Inactiva" val={[statusVal,setStatusVal]} clickEvent="" />
                    {mode=="edit" &&
                    <div className="commands-block">
                        <ButtonCommand label="Eliminar" icon="remove" clickEvent={remove} />
                    </div>}
                </div>
            </BoardContent>
        </Board>
    )
}