export default function ButtonAction({label,icon,style,clickEvent}){
    return(
        <div className={"button-action"+(style?" button-action-"+style:"")} onClick={clickEvent}>
            {icon=="wink" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.35 22.35"><g id="c"><path d="M10.73,17.16c-.67,0-1.34-.1-1.98-.29-.62-.18-.98-.84-.79-1.46,.18-.62,.84-.98,1.46-.79,.5,.15,1.02,.21,1.56,.18,1.8-.09,3.35-1.17,3.85-2.7,.2-.62,.86-.95,1.48-.75,.62,.2,.95,.87,.75,1.48-.8,2.45-3.2,4.18-5.97,4.31-.12,0-.23,0-.35,0Zm-2.56-6.81c.96,0,1.75-.79,1.75-1.75s-.79-1.75-1.75-1.75-1.75,.79-1.75,1.75,.79,1.75,1.75,1.75Zm6,0c.96,0,1.75-.79,1.75-1.75s-.79-1.75-1.75-1.75-1.75,.79-1.75,1.75,.79,1.75,1.75,1.75Zm8.17,.83C22.35,5.01,17.34,0,11.17,0S0,5.01,0,11.17s5.01,11.17,11.17,11.17,11.17-5.01,11.17-11.17Zm-2.35,0c0,4.87-3.96,8.83-8.83,8.83S2.35,16.04,2.35,11.17,6.31,2.35,11.17,2.35s8.83,3.96,8.83,8.83Z" fill="#FFFFFF"/></g></svg>}
            {icon=="edit" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 26.91"><g id="c"><g><path d="M24.57,23.54h0c0,.65-.52,1.18-1.17,1.18H12.21c-.65,0-1.17-.52-1.17-1.17s.52-1.17,1.17-1.17h11.2c.64,0,1.17,.52,1.17,1.16Zm-2.43-14.82L7.01,23.84c-.18,.18-.41,.29-.65,.33l-4.58,.71s-.08,0-.11,0c-.03,0-.06,0-.08,0-.03,0-.1,0-.16-.01-.63-.1-1.07-.7-.97-1.33l.7-4.58c.04-.25,.16-.47,.33-.65L16.6,3.17c1.53-1.52,4.01-1.52,5.54,0,.73,.73,1.15,1.74,1.14,2.77,0,1.03-.42,2.04-1.14,2.77Zm-1.66-3.88c-.61-.61-1.61-.61-2.23,0L3.4,19.67l-.4,2.64,2.63-.4L20.48,7.06c.61-.61,.61-1.61,0-2.23Z" fill="#FFFFFF"/><rect width="25" height="26.91" fill="none"/></g></g></svg>}
            {icon=="folder" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22.59"><g id="c"><g><path d="M21.18,2.71H11.06c-.17,0-.31-.14-.31-.31v-.2c0-.58-.23-1.14-.64-1.55-.41-.41-.97-.64-1.55-.64H2.82c-.75,0-1.47,.3-2,.83s-.83,1.25-.83,2v14.74c0,.75,.3,1.47,.83,2s1.25,.83,2,.83H21.18c.75,0,1.47-.3,2-.83s.83-1.25,.83-2V5.53c0-.75-.3-1.47-.83-2-.53-.53-1.25-.83-2-.83h0Zm.94,4.19H1.88V2.82c0-.25,.1-.49,.28-.67,.18-.18,.42-.28,.67-.28h5.73c.08,0,.16,.03,.22,.09,.06,.06,.09,.14,.09,.22v.2c0,.58,.23,1.14,.64,1.55,.41,.41,.97,.64,1.55,.64h10.12c.25,0,.49,.1,.67,.28,.18,.18,.28,.42,.28,.67v1.37Z" fill="#61dafb"/><rect width="24" height="22.59" fill="none"/></g></g></svg>}
            {icon=="assembly" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 24"><g id="c"><path d="m12.27,0c-1.5.1-3,.27-4.5.51-.86.13-1.68.76-1.58,1.69.2,2.04.37,4.09.45,6.13,0,.44-.13.82-.43,1.13-.3.3-.67.44-1.08.41-.34-.04-.68-.14-.98-.3-.39-.21-.84-.34-1.31-.34-1.58,0-2.85,1.36-2.85,3.04s1.27,3.03,2.85,3.03c.6,0,1.16-.19,1.62-.54.44-.32.99-.35,1.46-.07.47.28.74.78.71,1.35-.1,1.89-.23,3.79-.45,5.68-.1.92.72,1.54,1.59,1.68,1.6.27,3.19.48,4.78.6.5.04.96-.2,1.24-.64.3-.45.33-.98.1-1.46,0-.04,0-.06-.03-.09-.2-.42-.33-.89-.33-1.4,0-1.67,1.27-3.03,2.85-3.03s2.86,1.35,2.86,3.03c0,.49-.07.99-.26,1.4-.23.49-.2,1.03.07,1.49.3.46.75.7,1.27.65,1.39-.12,2.77-.31,4.14-.56.86-.16,1.74-.78,1.58-1.69-.36-2.04-.59-4.07-.7-6.11,0-.43.1-.82.39-1.13.3-.31.65-.46,1.06-.44.37.04.73.13,1.07.32.39.21.84.34,1.31.34,1.58,0,2.85-1.35,2.85-3.03s-1.27-3.04-2.85-3.04c-.59,0-1.14.2-1.6.53-.45.33-1,.34-1.47.06-.47-.29-.73-.8-.68-1.38.13-1.88.34-3.75.63-5.63.13-.92-.72-1.56-1.58-1.68-1.65-.23-3.31-.41-4.96-.51-.45-.04-.86.15-1.15.52-.3.37-.39.82-.3,1.28.07.3.17.59.3.86.2.42.3.89.3,1.4,0,1.67-1.27,3.03-2.85,3.03s-2.86-1.36-2.86-3.03c0-.65.2-1.25.52-1.75.33-.47.34-1.06.07-1.57C13.25.25,12.76-.03,12.21,0h.05Z" fill="#61dafb" fillRule="evenodd"/></g></svg>}
            {icon=="import" && <svg xmlns="http://www.w3.org/2000/svg" width="11.75" height="14.98" viewBox="0 0 11.75 14.98"><path d="M5.59.13,1,5.27a.37.37,0,0,0,.28.62H3.9v5.47a.38.38,0,0,0,.38.38H7.47a.38.38,0,0,0,.38-.38V5.89h2.62a.37.37,0,0,0,.28-.62L6.15.13a.37.37,0,0,0-.56,0ZM11.51,15H.24A.24.24,0,0,1,0,14.74V13.47a.24.24,0,0,1,.24-.24H11.51a.24.24,0,0,1,.24.24v1.27A.24.24,0,0,1,11.51,15Z" fill="#61DAFB" fillRule="evenodd"/></svg>}
            {icon=="wand" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.02 23.37"><g id="c"><path d="M.27,21.66L8.81,12.29l1.09,.29,.4,1.12L1.77,23.05c-.19,.2-.44,.32-.71,.33-.27,.01-.53-.08-.73-.27-.2-.18-.32-.43-.33-.71-.01-.27,.08-.53,.27-.74H.27Zm16.42-7.41c-.22,1.18-1.15,2.11-2.33,2.33,1.18,.22,2.11,1.15,2.33,2.33,.22-1.18,1.15-2.11,2.33-2.33-1.18-.22-2.11-1.15-2.33-2.33h0ZM13.66,3.77c.18-.96,.93-1.7,1.88-1.88-.96-.18-1.7-.93-1.88-1.88-.18,.96-.93,1.7-1.88,1.88,.96,.18,1.7,.93,1.88,1.88h0ZM4.55,9.4c.3-1.56,1.52-2.79,3.09-3.09-1.56-.3-2.79-1.52-3.09-3.09-.3,1.56-1.52,2.79-3.09,3.09,1.56,.3,2.79,1.52,3.09,3.09h0Zm2.05,1.14c.03,.14,.13,.25,.27,.29l2.63,.7,1.09,.29,.39,1.11,.89,2.52c.05,.13,.17,.23,.31,.25,.02,0,.04,0,.06,0,.12,0,.23-.07,.3-.17l2.09-3.23,3.85-.11c.15-.02,.27-.09,.33-.22,.06-.13,.04-.28-.05-.39l-2.43-2.99,1.08-3.69c.04-.14,0-.29-.11-.38-.11-.1-.26-.13-.39-.07l-3.59,1.38-3.18-2.17c-.12-.08-.28-.09-.4-.02-.13,.07-.2,.2-.19,.35l.2,3.85-3.05,2.35c-.11,.09-.17,.23-.14,.38Z" fill="#61DAFB" fillRule="evenodd"/></g></svg>}
            {icon=="undo" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.6 24"><g id="c"><path d="m.17,9.32L12.97.08c.13-.1.31-.11.45-.03.14.07.24.22.23.38v4.94c5.01.11,9.21,2.99,10.54,7.25,1.28,4.14-.53,8.47-4.73,11.3-.07.05-.16.07-.24.07h-.34c-.18,0-.34-.11-.4-.28-.06-.17-.02-.35.11-.47,1.86-1.7,2.47-3.76,1.7-5.65-.91-2.22-3.55-3.67-6.64-3.67v4.94c0,.16-.09.31-.23.38s-.32.06-.45-.03L.18,10.03c-.11-.08-.18-.21-.18-.35s.07-.27.18-.35h0Z" fill="#61dafb"/></g></svg>}
            {label}
        </div>
    )
}