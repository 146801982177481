import {useContext} from "react"
import AppsContext from "../contexts/AppsContext"

export default function useApp(){
    const [appsContext,setAppsContext]=useContext(AppsContext)
    const switcherApp=()=>{
        let appsContextNew={...appsContext}
        appsContextNew["switcher"]="shown"
        setAppsContext(appsContextNew)
    }
    const changeApp=(alias)=>{
        let appsContextNew={...appsContext}
        appsContextNew["switcher"]="hidden"
        appsContextNew["current"]=alias
        setAppsContext(appsContextNew)
    }
    return {switcherApp,changeApp}
}