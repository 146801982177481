import {useRef,forwardRef,useEffect} from "react"
import AirDatepicker from "air-datepicker"
import "../assets/air-datepicker.css"
import localeEs from "air-datepicker/locale/es"
import search from "../assets/search-2.svg"

const Textbox=forwardRef(({type,label,val,setVal,icon,disabled,max,appearance},ref)=>{

    const caption=useRef()

    let iconImage=""
    if(icon=="search"){
        iconImage=search
    }

    const change=(event)=>{
        if(type=="number"){
            if(event.target.validity.valid){
                setVal(event.target.value)
            }else{

            }
        }else{
            setVal(event.target.value)
        }
    }

    useEffect(()=>{
        if(type=="date"){
            let datepicker=new AirDatepicker(ref.current,{
                locale:localeEs,
                isMobile:false,
                dateFormat:"dd/MM/yyyy",
                autoClose:true,
                onSelect:({formattedDate})=>{
                    setVal(formattedDate)
                }
            })
        }
    },[])


    return(
        <div className={"field noselect"+(appearance &&" field-"+appearance)}>
            {label!="" && <div ref={caption} className="field-label">{label}</div>}
            <input 
                ref={ref}
                className={icon?"textbox textbox-with-icon":"textbox"}
                type={type=="password"?"password":"text"}
                pattern={type=="number"?"[0-9]*":""}
                maxLength={max!=""?max:""}
                disabled={disabled}
                value={val||""}
                onChange={change}
                onBlur={()=>{caption.current.classList.remove("field-label-on")}}
                onFocus={()=>{caption.current.classList.add("field-label-on")}}
            />
            {icon && <div className="textbox-icon"><img src={iconImage} /></div>}
        </div>
    )
})

export default Textbox