import {useRef,forwardRef} from "react"
import {NumericFormat} from "react-number-format"

const Numbox=forwardRef(({label,val,setVal,disabled,format,mask,decimalSeparator,suffix,prefix,placeholder,appearance},ref)=>{
    
    const caption=useRef()

    const change=(numberValue)=>{
        setVal(numberValue.value)
    }

    return(
        <div className={"field noselect"+(appearance &&" field-"+appearance)}>
            {label!="" && <div ref={caption} className="field-label">{label}</div>}
            <NumericFormat
                getInputRef={ref}
                className="textbox"
                value={val}
                onValueChange={change}
                onBlur={()=>{caption.current.classList.remove("field-label-on")}}
                onFocus={()=>{caption.current.classList.add("field-label-on")}}
                disabled={disabled}
                format={format}
                placeholder={placeholder}
                mask={mask}
                decimalSeparator={decimalSeparator}
                prefix={prefix}
                suffix={suffix}
            />
        </div>
    )
})

export default Numbox