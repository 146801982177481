import {Fragment} from "react"

export default function DataRows({context,items}){
    return(
        <div className="data-rows">
            {items.map((item,i)=>{
                let itemStyle=""
                if(item.style=="colored"){
                    itemStyle="data-colored"
                }else if(item.style=="bold"){
                    itemStyle="data-bold"
                }
                return(
                    <Fragment key={i}>
                        {context=="summary"?
                        <div className="data-rows-item-record">
                            <div>{item.label}</div>
                            <div className={itemStyle}>{item.quantity}</div>
                            <div className={itemStyle}>{item.amount}</div>
                        </div>
                        :context=="summary-collection"?
                        <div className="data-rows-item-record data-rows-item-record-alt">
                            <div>{item.label}</div>
                            <div className={itemStyle}>{item.quantity} <span className="data-transparent">({item.quantity_global})</span></div>
                            <div className={itemStyle}>{item.amount} <span className="data-transparent">({item.amount_global})</span></div>
                        </div>:
                        <div className="data-rows-item">
                            <div>{item.label}</div>
                            <div className={itemStyle}>{item.content}</div>
                        </div>}
                    </Fragment>
                )
            })}
        </div>
    )
}