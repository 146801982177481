import {useState,useRef,useEffect} from "react"
import useApi from "../hooks/useApi"
import useBoard from "../hooks/useBoard"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import Divisor from "../commons/Divisor"
import Textbox from "../commons/Textbox"
import Selector from "../commons/Selector"
import Switch from "../commons/Switch"
import ButtonCommand from "../commons/ButtonCommand"

export default function ClientsEdit({params}){

    const section="clients-edit"
    const requestApi=useApi()
    const [ready,setReady]=useState(0)
    const {loadBoard}=useBoard()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const mode=params.mode
    const [idVal,setIdVal]=useState(0)
    const [defaultData,setDefaultData]=useState("")

    const firstNameInput=useRef()
    const lastNameInput=useRef()
    const nifInput=useRef()
    const clientIdInput=useRef()
    const bufeteInput=useRef()
    const tagsInput=useRef()
    const [firstNameVal,setFirstNameVal]=useState("")
    const [lastNameVal,setLastNameVal]=useState("")
    const [nifVal,setNifVal]=useState("")
    const [clientIdVal,setClientIdVal]=useState("")
    const [bufeteVal,setBufeteVal]=useState("")
    const [tagsVal,setTagsVal]=useState("")
    const [statusVal,setStatusVal]=useState(1)

    const [bufeteOptions,setBufeteOptions]=useState([])
    const [tagsOptions,setTagsOptions]=useState([
        {label:"Rojo",value:"1",color:"#FF3B2F"},
        {label:"Amarillo",value:"2",color:"#FFD207"},
        {label:"Fucsia",value:"3",color:"#FC5CB9"},
        {label:"Verde",value:"4",color:"#4CDA64"},
        {label:"Violeta",value:"5",color:"#9C71DA"},
        {label:"Naranja",value:"6",color:"#FF9501"},
        {label:"Azulado",value:"7",color:"#67899B"}
    ])

    const loadBufetes=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("bufetes/list",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(item){
                            newOptions.push({label:item.name,value:item.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadData=()=>{
        return new Promise(function(resolve){
            if(mode=="edit"){
                resolve(
                    requestApi("clients/info",{
                            reference:params.reference
                        })
                        .then(data=>{
                            return data.data
                        })
                )
            }else{
                resolve(
                    ()=>{return ""}
                )
            }
        })
    }

    useEffect(()=>{
        load()
    },[params.reference])

    const load=()=>{
        Promise.all([loadBufetes,loadData].map(func=>func())).then((result)=>{
            setBufeteOptions(result[0])
            setDefaultData(result[1])
            setReady(1)
        })
    }

    useEffect(()=>{
        if(mode=="edit" && ready){
            setIdVal(defaultData.id)
            setFirstNameVal(defaultData.first_name)
            setLastNameVal(defaultData.last_name)
            setNifVal(defaultData.nif)
            setClientIdVal(defaultData.client_id)
            bufeteOptions.forEach(function(item){
                if(item.value==defaultData.bufete){
                    bufeteInput.current.setValue({label:item.label,value:item.value})
                }
            })
            let newTagsValue=[]
            tagsOptions.forEach(function(item){
                defaultData["tags"].forEach(function(tag){
                    if(tag==item.value){
                        newTagsValue.push({label:item.label,value:item.value,color:item.color})
                    }
                })
            })
            tagsInput.current.setValue(newTagsValue)
            setStatusVal(defaultData.status)
        }else{
            setFirstNameVal("")
            setLastNameVal("")
            setNifVal("")
            setClientIdVal("")
            setBufeteVal("")
            setTagsVal("")
            setStatusVal(1)
        }
    },[defaultData])

    const saveData=()=>{
        if(firstNameVal==""){
            firstNameInput.current.focus()
        }else{
            if(lastNameVal==""){
                lastNameInput.current.focus()
            }else{
                if(nifVal==""){
                    nifInput.current.focus()
                }else{
                    if(bufeteInput.current.getValue().length==0){
                        bufeteInput.current.focus()
                    }else{
                        requestApi("clients/save",{
                                id:idVal,
                                mode:mode,
                                first_name:firstNameVal,
                                last_name:lastNameVal,
                                nif:nifVal,
                                client_id:clientIdVal,
                                bufete:bufeteInput.current.getValue()[0]?bufeteInput.current.getValue()[0].value:0,
                                tags:tagsInput.current.getValue(),
                                status:statusVal
                            })
                            .then(data=>{
                                if(data.status=="success"){
                                    if(mode=="create"){
                                        changeToast("created_m","")
                                    }else if(mode=="edit"){
                                        changeToast("saved","")
                                    }
                                    refresh({board:"clients",close:"clients-edit"})
                                }else{
                                    changeDialog("generic_error","","")
                                }
                            })
                    }
                }
            }
        }
    }

    const remove=()=>{
        requestApi("clients/remove",{
                reference:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    refresh({board:"clients",close:"clients-edit"})
                }else{
                    changeToast("generic_error","")
                }
            })
    }
    
    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft={mode=="create" && <ButtonAction label="Importar" icon="import" style="alt" clickEvent={()=>{loadBoard({params:{alias:"clients-import",level:2,theme:"rabbit2"}})}} />}
                actionsRight={<ButtonAction label="Guardar" icon="wink" clickEvent={saveData} />} />
            <BoardContent>
                <div className="board-form">
                    <Textbox ref={firstNameInput} type="text" label="Nombres" val={firstNameVal} setVal={setFirstNameVal} disabled={false} />
                    <Textbox ref={lastNameInput} type="text" label="Apellidos" val={lastNameVal} setVal={setLastNameVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={nifInput} type="text" label="NIF" val={nifVal} setVal={setNifVal} disabled={false} />
                    <Textbox ref={clientIdInput} type="text" label="Número de cliente" val={clientIdVal} setVal={setClientIdVal} disabled={false} />
                    <Divisor />
                    <Selector ref={bufeteInput} options={bufeteOptions} label="Bufete" placeholder="" multiple={false} searchable={true} style="field" val={bufeteVal} setVal={setBufeteVal} />
                    <Selector ref={tagsInput} options={tagsOptions} label="Etiquetas" placeholder="" multiple={true} searchable={false} style="field" val={tagsVal} setVal={setTagsVal} />
                    <Divisor />
                    <Switch onLabel="Activo" offLabel="Inactivo" val={[statusVal,setStatusVal]} clickEvent="" />
                    {mode=="edit" &&
                    <div className="commands-block">
                        <ButtonCommand label="Eliminar" icon="remove" clickEvent={remove} />
                    </div>}
                </div>
            </BoardContent>
        </Board>
    )
}