import {useContext} from "react"
import DialogContext from "../contexts/DialogContext"

export default function useDialog(){
    const [dialogContext,setDialogContext]=useContext(DialogContext)
    let dialogTimer
    const changeDialog=(dialog,reference,action)=>{
        let dialogContextNew={...dialogContext}
        dialogContextNew["dialog"]=dialog
        dialogContextNew["reference"]=reference
        dialogContextNew["action"]=action
        setDialogContext(dialogContextNew)
    }
    const manageDialog=(action)=>{
        if(dialogTimer){
            clearTimeout(dialogTimer)
        }
        if(action=="show"){
            document.getElementById("dialog").style.display="block"
            dialogTimer=setTimeout(()=>{
                document.getElementById("dialog").classList.add("dialog-show")
            },50)
        }else if(action=="hide"){
            document.getElementById("dialog").classList.remove("dialog-show")
            dialogTimer=setTimeout(()=>{
                document.getElementById("dialog").style.display="none"
                let dialogContextNew={...dialogContext}
                dialogContextNew["dialog"]=""
                dialogContextNew["reference"]=""
                dialogContextNew["action"]=""
                setDialogContext(dialogContextNew)
            },250)
        }
    }
    return {manageDialog,changeDialog}
}