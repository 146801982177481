import {useState,useEffect,useRef,useContext,Fragment} from "react"
import GlobalContext from "../contexts/GlobalContext"
import useApi from "../hooks/useApi"
import useBoard from "../hooks/useBoard"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import ButtonCircle from "../commons/ButtonCircle"
import Textbox from "../commons/Textbox"
import Uploader from "../commons/Uploader"
import FieldTextbox from "../commons/FieldTextbox"
import ImportBlock from "../components/ImportBlock"
import FieldSelect from "../commons/FieldSelect"
import DataBlock from "../components/DataBlock"
import magic from "../assets/magic.webp"

export default function PersonsImport({params}){

    const section="persons-import"
    const [globalContext]=useContext(GlobalContext)
    const {changeDialog,manageDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const requestApi=useApi()
    const [ready,setReady]=useState(1)
    const {loadBoard}=useBoard()
    const fileInput=useRef()
    const [fileVal,setFileVal]=useState()
    const nameInput=useRef()
    const [nameVal,setNameVal]=useState("")

    const [data,setData]=useState()

    const [assemblageOptions,setAssemblageOptions]=useState([])
    useEffect(()=>{
        requestApi("assemblages/list",{})
            .then(data=>{
                let newOptions=[]
                newOptions.push({label:"",value:""})
                data.items.forEach(function(item){
                    newOptions.push({label:item.name,value:item.id})
                })
                setAssemblageOptions(newOptions)
            })
    },[])

    useEffect(()=>{
        if(fileVal){
            requestApi("persons/import",{
                    name:fileVal.name,
                    extension:fileVal.extension
                })
                .then(data=>{
                    setData(data)
                })
        }
    },[fileVal])

    const changeField=(val,index,field,fieldInner,indexInner,type)=>{
        let temp={...data}
        if(fieldInner){
            if(indexInner>=0){
                temp["items"][index][field][indexInner][fieldInner]=val.target.value
            }else{
                temp["items"][index][field][fieldInner]=val.target.value
            }
        }else{
            temp["items"][index][field]=val.target.value
        }
        setData(temp)
    }

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            requestApi("persons/import_save",{
                    name:nameVal,
                    data:data
                })
                .then(data=>{
                    if(data.status=="success"){
                        changeToast("imported","")
                        refresh({board:"persons",close:"persons-import"})
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const discard=()=>{
        changeDialog("discard_import","",()=>{
            manageDialog("hide")
            loadBoard({params:{alias:"persons-edit",level:2,theme:"rabbit",mode:"create"}})
        })
    }

    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft={
                    <Fragment>
                        {!data &&
                        <Fragment>
                            <ButtonCircle icon="back" style="alt" clickEvent={()=>{loadBoard({params:{alias:"persons-edit",level:2,theme:"rabbit",mode:"create"}})}} />
                        </Fragment>}                        
                    </Fragment>}
                actionsRight={
                    <Fragment>
                        {data &&
                        <Fragment>
                            <ButtonAction label="Descartar" icon="" style="alt" clickEvent={discard} />
                            <ButtonAction label="Importar" icon="wink" clickEvent={saveData} />
                        </Fragment>}
                    </Fragment>
                } />
            <BoardContent>
                {(!fileVal && !data) &&
                <div className="board-form">
                    <Uploader ref={fileInput} context="persons-import" val={fileVal} setVal={setFileVal} label="Subir archivo" note={<Fragment>Formato Excel (XLSX) <a className="uploader-note-link" href={globalContext.base_url+"resources/Plantilla_Personas.xlsx"} target="_blank">Descargar plantilla</a></Fragment>} labelUploading="Subiendo archivo" restrict={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]} multiple={false} />
                </div>}
                {(fileVal && !data) &&
                <div className="import-blocks">
                    <div className="import-magic">
                        <img src={magic} />
                    </div>                    
                </div>}
                {data &&
                <div className="import-blocks">
                    <DataBlock title="" style="bordered">
                        <div className="data-title-big data-colored">{data.items.length==1?"Se va a importar":"Se van a importar"} {data.items.length} {data.items.length==1?"persona":"personas"}</div>
                        <div className="data-colored">Este es el momento de revisar los datos y corregir errores.</div>
                        <Textbox ref={nameInput} type="text" label="Grupo de importación" val={nameVal} setVal={setNameVal} disabled={false} />
                    </DataBlock>
                    {data.items.map((item,i)=>{
                        return(
                            <ImportBlock key={i}>
                                <div className="import-section import-section-first">Persona #{i+1}</div>
                                <div className="import-half">
                                    <FieldTextbox val={item.first_name} label="Nombres" field="first_name" index={i} changeEvent={changeField} review={item.first_name==""?true:false} />
                                    <FieldTextbox val={item.last_name} label="Apellidos" field="last_name" index={i} changeEvent={changeField} review={item.last_name==""?true:false} />
                                </div>
                                <div className="import-third">
                                    <FieldTextbox val={item.nif} label="NIF" field="nif" index={i} changeEvent={changeField} review={item.nif==""?true:false} />
                                    <FieldTextbox val={item.client_id} label="Número de cliente" field="client_id" index={i} changeEvent={changeField} review={false} />
                                    <FieldSelect val={item.assemblage} options={assemblageOptions} label="Grupo" field="assemblage" index={i} changeEvent={changeField} review={false} />
                                </div>
                                <div className="import-third">
                                    <FieldTextbox val={item.extra_1} label="Extra 1" field="extra_1" index={i} changeEvent={changeField} review={false} />
                                    <FieldTextbox val={item.extra_2} label="Extra 2" field="extra_2" index={i} changeEvent={changeField} review={false} />
                                    <FieldTextbox val={item.extra_3} label="Extra 3" field="extra_3" index={i} changeEvent={changeField} review={false} />
                                </div>
                                <div className="import-third">
                                    <FieldTextbox val={item.extra_4} label="Extra 4" field="extra_4" index={i} changeEvent={changeField} review={false} />
                                    <FieldTextbox val={item.extra_5} label="Extra 5" field="extra_5" index={i} changeEvent={changeField} review={false} />
                                    <FieldTextbox val={item.extra_6} label="Extra 6" field="extra_6" index={i} changeEvent={changeField} review={false} />
                                </div>
                                <div className="import-third">
                                    <FieldTextbox val={item.extra_7} label="Extra 7" field="extra_7" index={i} changeEvent={changeField} review={false} />
                                </div>
                            </ImportBlock>
                        )
                    })}
                </div>}
            </BoardContent>
        </Board>
    )
}