import {Fragment,useState,useRef,useEffect} from "react"
import {CopyToClipboard} from "react-copy-to-clipboard"
import useApi from "../hooks/useApi"
import useBoard from "../hooks/useBoard"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import useViewer from "../hooks/useViewer"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import Textbox from "../commons/Textbox"
import Switch from "../commons/Switch"
import Uploader from "../commons/Uploader"
import Divisor from "../commons/Divisor"
import ButtonCommand from "../commons/ButtonCommand"
import DataBlock from "../components/DataBlock"
import DataList from "../components/DataList"
import DataListItem from "../components/DataListItem"
import ButtonSmall from "../commons/ButtonSmall"
import ItemListEdit from "../commons/ItemListEdit"

export default function TemplatesEdit({params}){

    const requestApi=useApi()
    const [ready,setReady]=useState(0)
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {loadBoard}=useBoard()
    const {refresh}=useRefresh()
    const {changeViewer}=useViewer()

    const mode=params.mode
    const [idVal,setIdVal]=useState(0)
    const [defaultData,setDefaultData]=useState("")
    const nameInput=useRef()
    const [nameVal,setNameVal]=useState("")
    const templateInput=useRef()
    const [template,setTemplate]=useState()
    const [variables,setVariables]=useState([])
    const [templatesGenerated,setTemplatesGenerated]=useState([])
    const [editMode,setEditMode]=useState(0)
    const [editIndex,setEditIndex]=useState("")
    const itemNameInput=useRef()
    const [itemNameVal,setItemNameVal]=useState("")
    const itemReplaceInput=useRef()
    const [itemReplaceVal,setItemReplaceVal]=useState("")
    const [uppercaseVal,setUppercaseVal]=useState(0)
    const replaceVariables=[
        {
            group:"Grupo",
            variables:[
                {variable:"group_name",label:"Nombre del grupo"},
                {variable:"variable_1",label:"Variable 1"},
                {variable:"variable_2",label:"Variable 2"},
                {variable:"variable_3",label:"Variable 3"},
                {variable:"variable_4",label:"Variable 4"},
                {variable:"variable_5",label:"Variable 5"},
                {variable:"variable_6",label:"Variable 6"},
                {variable:"variable_7",label:"Variable 7"},
                {variable:"quantity",label:"Cantidad de personas"}              
            ]
        },{
            group:"Personas",
            variables:[
                {variable:"first_name",label:"Nombres"},
                {variable:"last_name",label:"Apellidos"},
                {variable:"nif",label:"NIF"},
                {variable:"client_id",label:"Número de cliente"},
                {variable:"extra_1",label:"Extra 1"},
                {variable:"extra_2",label:"Extra 2"},
                {variable:"extra_3",label:"Extra 3"},
                {variable:"extra_4",label:"Extra 4"},
                {variable:"extra_5",label:"Extra 5"},
                {variable:"extra_6",label:"Extra 6"},
                {variable:"extra_7",label:"Extra 7"}
            ]
        }
    ]

    const loadData=()=>{
        setReady(0)
        if(mode=="edit"){
            requestApi("templates/info",{
                    reference:params.reference
                })
                .then(data=>{
                    setDefaultData(data.data)
                    setReady(1)
                })
        }else{
            setDefaultData("")
            setReady(1)
        }
    }

    useEffect(()=>{
        loadData()
    },[])

    useEffect(()=>{
        loadData()
    },[params.reference])

    useEffect(()=>{
        if(mode=="edit"){
            setIdVal(defaultData.id)
            setNameVal(defaultData.name)
            setTemplate(defaultData.template)
            setVariables(defaultData.variables)
            setTemplatesGenerated(defaultData.templates_generated)
            setEditIndex("")
        }else{
            setIdVal(0)
            setNameVal("")
            setTemplate("")
            setVariables([])
            setTemplatesGenerated([])
            setEditIndex("")
        }
        setEditMode(0)
    },[defaultData])

    const addVariable=()=>{
        setItemNameVal("")
        setUppercaseVal(0)
        setItemReplaceVal("")
        setEditMode(1)
        setEditIndex("")
    }

    const editVariable=(i)=>{
        setEditIndex(i)
        setEditMode(1)
        setTimeout(()=>{
            setItemNameVal(variables[i]["name"])
            setUppercaseVal(variables[i]["uppercase"])
            setItemReplaceVal(variables[i]["replace_value"])
        },100)
    }

    const removeVariable=(i)=>{
        const newVariables=[...variables]
        newVariables.splice(i,1)
        setVariables(newVariables)
    }

    const saveVariable=()=>{
        if(itemNameVal==""){
            itemNameInput.current.focus()
        }else{
            if(itemReplaceVal==""){
                itemReplaceInput.current.focus()
            }else{
                const newVariables=[...variables]
                if(editIndex===""){
                    let newVariable={
                        id:0,
                        name:itemNameVal,
                        replace_value:itemReplaceVal,
                        uppercase:uppercaseVal
                    }
                    newVariables.push(newVariable)
                }else{
                    newVariables[editIndex]["name"]=itemNameVal
                    newVariables[editIndex]["replace_value"]=itemReplaceVal
                    newVariables[editIndex]["uppercase"]=uppercaseVal
                }
                setVariables(newVariables)
                setEditMode(0)
                setEditIndex("")
            }
        }
    }

    const cancelVariable=()=>{
        setItemNameVal("")
        setUppercaseVal(0)
        setItemReplaceVal("")
        setEditMode(0)
        setEditIndex("")
    }

    const generateDocument=(context,i)=>{
        requestApi("templates/generate",{
                reference:templatesGenerated[i]["id"],
                context:context
            })
            .then(data=>{
                if(data.status=="success"){
                    if(context=="preview"){
                        changeViewer(data.data.url,data.data.extension)
                    }else if(context=="download"){
                        let link=document.createElement("a")
                        link.setAttribute("download","")
                        link.href=data.data.url
                        document.body.appendChild(link)
                        link.click()
                        link.remove()
                    }
                }else{
                    changeToast("generic_error","")
                }
            })
    }

    const removeDocument=(i)=>{
        const newTemplatesGenerated=[...templatesGenerated]
        newTemplatesGenerated.splice(i,1)
        setTemplatesGenerated(newTemplatesGenerated)
    }

    const editGenerateDocument=(i)=>{
        loadBoard({params:{alias:"templates-generate",level:2,theme:"rabbit",mode:"edit",reference:{template:idVal,document:templatesGenerated[i]["id"]}}})
    }

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            requestApi("templates/save",{
                    id:idVal,
                    mode:mode,
                    name:nameVal,
                    template:template,
                    variables:variables,
                    templates_generated:templatesGenerated
                })
                .then(data=>{
                    if(data.status=="success"){
                        if(mode=="create"){
                            changeToast("created_m","")
                        }else if(mode=="edit"){
                            changeToast("saved","")
                        }
                        refresh({board:"templates",close:"templates-edit"})
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const remove=()=>{
        requestApi("templates/remove",{
                reference:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    refresh({board:"templates",close:"templates-edit"})
                }else{
                    changeToast("generic_error","")
                }
            })
    }

    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft={mode=="edit" && <ButtonAction label="Generar" icon="wand" style="alt" clickEvent={()=>{loadBoard({params:{alias:"templates-generate",level:2,theme:"rabbit",mode:"create",reference:{template:idVal,document:0}}})}} />}
                actionsRight={<ButtonAction label="Guardar" icon="wink" clickEvent={saveData} />} />
            <BoardContent>
                <div className="board-data">
                    <Textbox ref={nameInput} type="text" label="Nombre" val={nameVal} setVal={setNameVal} disabled={false} />
                    <Divisor />
                    <Uploader ref={templateInput} context="templates-template" val={template} setVal={setTemplate} label="Subir plantilla Word" labelUploading="Subiendo plantilla" restrict={["application/vnd.openxmlformats-officedocument.wordprocessingml.document"]} multiple={false} viewer={true} />
                    <DataBlock title="Variables" style="white">
                        {variables &&
                        <DataList>
                            {variables.map((item,i)=>{
                                return(
                                    <DataListItem key={i}
                                        left={
                                            <Fragment>
                                                <div className="data-medium data-colored">{item.name?item.name:"‒"}</div>
                                                <div>Se reemplaza por <span className="data-medium">{item.replace_value?item.replace_value:"‒"}</span></div>
                                                <div className="data-colored">Agregar en la plantilla <span className="data-medium">$&#123;{item.name}&#125;</span></div>
                                            </Fragment>
                                        }
                                        right={
                                            <div className="data-actions">
                                                <CopyToClipboard className={"copy-button-"+i} text={"${"+item.name+"}"} onCopy={()=>{document.getElementsByClassName("copy-button-"+i)[0].click();changeToast("var_copied","")}}>
                                                    <div><ButtonSmall icon="copy" clickEvent={()=>{}} /></div>
                                                </CopyToClipboard>
                                                <ButtonSmall icon="edit" clickEvent={()=>{editVariable(i)}} />
                                                <ButtonSmall icon="remove" clickEvent={()=>{removeVariable(i)}} />
                                            </div>
                                        }
                                    />
                                )
                            })}
                            {editMode==1 &&
                            <DataListItem left={
                                <ItemListEdit>
                                    <div className="item-list-edit-full">
                                        <Textbox ref={itemNameInput} type="text" appearance="compact" label="Nombre" val={itemNameVal} setVal={setItemNameVal} disabled={false} />
                                    </div>
                                    <div className="item-list-edit-full">
                                        {replaceVariables.map(
                                            (item,i)=>{
                                                return(
                                                    <div key={i} className="item-list-tags">
                                                        <div className="item-list-tags-group">{item.group}</div>
                                                        {item.variables.map(
                                                            (variable,index)=>{
                                                                return(
                                                                    <CopyToClipboard key={index} className={"item-list-tag copy-variable-"+i+"-"+index} text={variable.variable} onCopy={()=>{document.getElementsByClassName("copy-variable-"+i+"-"+index)[0].click();changeToast("var_copied","")}}>
                                                                        <div title={variable.label}>{variable.variable}</div>
                                                                    </CopyToClipboard>
                                                                )
                                                            }
                                                        )}
                                                    </div>
                                                )
                                            }
                                        )}
                                        <Textbox ref={itemReplaceInput} type="text" appearance="compact" label="Reemplazar por" val={itemReplaceVal} setVal={setItemReplaceVal} disabled={false} />
                                    </div>
                                    <div className="item-list-edit-half">
                                        <Switch onLabel="En mayúsculas" offLabel="En mayúsculas" style="mini" val={[uppercaseVal,setUppercaseVal]} clickEvent="" />
                                    </div>
                                </ItemListEdit>
                            } right={
                                <div className="item-list-edit-actions">
                                    <div>
                                        <ButtonSmall icon="cancel" clickEvent={()=>{cancelVariable()}} />
                                        <ButtonSmall icon="confirm" style="confirm" clickEvent={()=>{saveVariable()}} />
                                    </div>
                                </div>
                            } />}
                            <DataListItem left={<ButtonSmall icon="add" style="alt" clickEvent={()=>{addVariable()}} />} right="" />
                        </DataList>}
                    </DataBlock>
                    {(templatesGenerated && templatesGenerated.length>0) &&
                    <DataBlock title="Obtener documentos" style="white">
                        <DataList>
                            {templatesGenerated.map((item,i)=>{
                                return(
                                    <DataListItem key={i}
                                        left={
                                            <Fragment>
                                                <div className="data-medium data-colored">{item.name?item.name:"‒"}</div>
                                                <div>{item.assemblage_name?item.assemblage_name:"‒"}</div>
                                            </Fragment>
                                        }
                                        right={
                                            <div className="data-actions">
                                                <ButtonSmall icon="preview" clickEvent={()=>{generateDocument("preview",i)}} />
                                                <ButtonSmall icon="download" clickEvent={()=>{generateDocument("download",i)}} />
                                                <ButtonSmall icon="edit" clickEvent={()=>{editGenerateDocument(i)}} />
                                                <ButtonSmall icon="remove" clickEvent={()=>{removeDocument(i)}} />
                                            </div>
                                        }
                                    />
                                )
                            })}
                        </DataList>
                    </DataBlock>}
                    {mode=="edit" &&
                    <div className="commands-block">
                        <ButtonCommand label="Eliminar" icon="remove" clickEvent={remove} />
                    </div>}
                </div>
            </BoardContent>
        </Board>
    )
}