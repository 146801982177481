import {useState,useEffect,useRef} from "react"
import useApi from "../hooks/useApi"
import useBoard from "../hooks/useBoard"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import ButtonCircle from "../commons/ButtonCircle"
import ButtonCommand from "../commons/ButtonCommand"
import Textbox from "../commons/Textbox"
import Selector from "../commons/Selector"
import Divisor from "../commons/Divisor"

export default function TemplatesGenerate({params}){

    const requestApi=useApi()
    const [ready,setReady]=useState(1)
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {loadBoard}=useBoard()
    const {refresh}=useRefresh()
    const mode=params.mode
    const [idVal,setIdVal]=useState(0)
    const [defaultData,setDefaultData]=useState("")
    const nameInput=useRef()
    const [nameVal,setNameVal]=useState("")
    const templateInput=useRef()
    const [templateVal,setTemplateVal]=useState("")
    const [templateOptions,setTemplateOptions]=useState([])
    const assemblageInput=useRef()
    const [assemblageVal,setAssemblageVal]=useState("")
    const [assemblageOptions,setAssemblageOptions]=useState([])

    const loadTemplates=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("templates/list",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(item){
                            newOptions.push({label:item.name,value:item.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadAssemblages=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("assemblages/list",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(item){
                            newOptions.push({label:item.name,value:item.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadData=()=>{
        return new Promise(function(resolve){
            if(mode=="edit"){
                resolve(
                    requestApi("templates/generated_info",{
                            reference:params.reference.document
                        })
                        .then(data=>{
                            return data.data
                        })
                )
            }else{
                resolve(
                    ()=>{return ""}
                )
            }
        })
    }

    useEffect(()=>{
        load()
    },[params.reference.document])

    const load=()=>{
        Promise.all([loadTemplates,loadAssemblages,loadData].map(func=>func())).then((result)=>{
            setTemplateOptions(result[0])
            setAssemblageOptions(result[1])
            setDefaultData(result[2])
            setReady(1)
        })
    }

    useEffect(()=>{
        templateOptions.forEach(function(item){
            if(item.value==params.reference.document){
                templateInput.current.setValue({label:item.label,value:item.value})
            }
        })
    },[templateOptions])

    useEffect(()=>{
        if(mode=="edit" && ready){
            setIdVal(defaultData.id)
            setNameVal(defaultData.name)
            templateOptions.forEach(function(item){
                if(item.value==defaultData.template){
                    templateInput.current.setValue({label:item.label,value:item.value})
                }
            })
            assemblageOptions.forEach(function(item){
                if(item.value==defaultData.assemblage){
                    assemblageInput.current.setValue({label:item.label,value:item.value})
                }
            })
        }else{
            setNameVal("")
            setTemplateVal("")
            setAssemblageVal("")
        }
    },[defaultData])

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            if(templateInput.current.getValue().length==0){
                templateInput.current.focus()
            }else{
                if(assemblageInput.current.getValue().length==0){
                    assemblageInput.current.focus()
                }else{
                    requestApi("templates/generated_save",{
                            id:idVal,
                            mode:mode,
                            name:nameVal,
                            template:templateInput.current.getValue()[0]?templateInput.current.getValue()[0].value:0,
                            assemblage:assemblageInput.current.getValue()[0]?assemblageInput.current.getValue()[0].value:0
                        })
                        .then(data=>{
                            if(data.status=="success"){
                                if(mode=="create"){
                                    changeToast("created_f","")
                                }else if(mode=="edit"){
                                    changeToast("saved","")
                                }
                                refresh({board:"templates",close:"templates-generate"})
                                loadBoard({params:{alias:"templates-edit",level:2,theme:"rabbit",reference:params.reference.template,mode:"edit"}})
                            }else{
                                changeDialog("generic_error","","")
                            }
                        })
                }
            }
        }
    }

    const remove=()=>{
        requestApi("templates/generated_remove",{
                reference:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    refresh({board:"templates",close:"templates-generated"})
                }else{
                    changeToast("generic_error","")
                }
            })
    }

    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft={<ButtonCircle icon="back" style="alt" clickEvent={()=>{loadBoard({params:{alias:"templates-edit",level:2,theme:"rabbit",mode:"edit",reference:params.reference.template}})}} />}
                actionsRight={<ButtonAction label="Guardar" icon="wink" clickEvent={saveData} />} />
            <BoardContent>
                <div className="board-form">
                    <Textbox ref={nameInput} type="text" label="Nombre" val={nameVal} setVal={setNameVal} disabled={false} />
                    <Divisor />
                    <Selector ref={templateInput} options={templateOptions} label="Modelo" placeholder="" multiple={false} searchable={true} style="field" val={templateVal} setVal={setTemplateVal} />
                    <Selector ref={assemblageInput} options={assemblageOptions} label="Grupo" placeholder="" multiple={false} searchable={true} style="field" val={assemblageVal} setVal={setAssemblageVal} />
                    {mode=="edit" &&
                    <div className="commands-block">
                        <ButtonCommand label="Eliminar" icon="remove" clickEvent={remove} />
                    </div>}
                </div>
            </BoardContent>
        </Board>
    )
}