import {useContext} from "react"
import AppsContext from "../contexts/AppsContext"

export default function useBoard(){
    const [appsContext,setAppsContext]=useContext(AppsContext)
    const loadBoard=(board)=>{
        let appsContextNew={...appsContext}
        appsContextNew["boards"].length=appsContextNew["boards"].length<board.params.level?appsContextNew["boards"].length:board.params.level
        appsContextNew["boards"].push({alias:board.params.alias,params:board.params})
        setAppsContext(appsContextNew)
    }
    const onBoardItem=(board,item)=>{
        let appsContextNew={...appsContext}
        const index=appsContextNew["boards"].findIndex(obj=>obj["alias"]===board)
        appsContextNew["boards"][index].params["onItem"]=item
        setAppsContext(appsContextNew)
    }
    const closeBoard=(board)=>{
        let appsContextNew={...appsContext}
        const index=appsContextNew["boards"].findIndex(obj=>obj["alias"]===board)
        appsContextNew["boards"]=index>=0?[
            ...appsContextNew["boards"].slice(0,index),
            ...appsContextNew["boards"].slice(index+1)
        ]:appsContextNew["boards"]
        setAppsContext(appsContextNew)
    }
    return {loadBoard,closeBoard,onBoardItem}
}