import {useState,useEffect,useRef} from "react"
import {DragDropContext,Droppable,Draggable} from "react-beautiful-dnd"
import useApi from "../hooks/useApi"
import useBoard from "../hooks/useBoard"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import Selector from "../commons/Selector"
import Switch from "../commons/Switch"
import SwitchMini from "../commons/SwitchMini"
import Divisor from "../commons/Divisor"
import dragIcon from "../assets/drag.svg"

export default function Export({params}){

    const requestApi=useApi()
    const [ready,setReady]=useState(1)
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {loadBoard}=useBoard()
    const {refresh}=useRefresh()

    const collectionInput=useRef()
    const [collectionVal,setCollectionVal]=useState("")
    const [collectionOptions,setCollectionOptions]=useState([])
    const [rows,setRows]=useState([
        {id:1,name:"Apellido",alias:"Apellido",field:"last_name",status:1},
        {id:2,name:"Nombre",alias:"Nombre",field:"first_name",status:1},
        {id:3,name:"NIF",alias:"NIF",field:"nif",status:1},
        {id:4,name:"Monto reclamable",alias:"Monto reclamable",field:"claimable",status:1},
        {id:5,name:"Monto reclamable global",alias:"Monto reclamable global",field:"claimable_global",status:0},
        {id:6,name:"Cantidad de subclientes",alias:"Subclientes",field:"subclients",status:0},
        {id:7,name:"Identificador único",alias:"ID",field:"id",status:0},
        {id:8,name:"Número de cliente",alias:"Número de cliente",field:"client_id",status:0},
        {id:9,name:"Fecha de creación",alias:"Fecha de creación",field:"created",status:0},
        {id:10,name:"Estado",alias:"Estado",field:"status",status:0}
    ])

    const [uppercaseVal,setUppercaseVal]=useState(0)
    const [includeSubclientsVal,setIncludeSubclientsVal]=useState(1)
    
    const loadCollections=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("collections/list",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(item){
                            newOptions.push({label:item.name,value:item.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    useEffect(()=>{
        load()
    },[params.reference])

    const load=()=>{
        Promise.all([loadCollections].map(func=>func())).then((result)=>{
            setCollectionOptions(result[0])
            setReady(1)
        })
    }

    const reorder=(startIndex,endIndex)=>{
        const result=Array.from(rows)
        const [removed]=result.splice(startIndex,1)
        result.splice(endIndex,0,removed)
        return result
    }

    const onDragEnd=(result)=>{
        if(!result.destination){
            return
        }
        const items=reorder(rows,result.source.index,result.destination.index)
        setRows(items)
    }

    const statusChange=(i)=>{
        const newRows=[...rows]
        if(newRows[i]["status"]==0){
            newRows[i]["status"]=1
        }else if(newRows[i]["status"]==1){
            newRows[i]["status"]=0
        }
        setRows(newRows)
    }

    const aliasChange=(event,i)=>{
        const newRows=[...rows]
        newRows[i]["alias"]=event.target.value
        setRows(newRows)
    }

    const saveData=()=>{
        if(collectionInput.current.getValue().length==0){
            collectionInput.current.focus()
        }else{
            requestApi("collections/export",{
                    collection:collectionInput.current.getValue()[0]?collectionInput.current.getValue()[0].value:0,
                    uppercase:uppercaseVal,
                    subclients:includeSubclientsVal,
                    rows:rows
                })
                .then(data=>{
                    if(data.status=="success"){
                        changeToast("exported","")
                        let link=document.createElement("a")
                        link.setAttribute("download","")
                        link.href=data.url
                        document.body.appendChild(link)
                        link.click()
                        link.remove()
                        refresh({board:"export",close:"export"})
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft=""
                actionsRight={<ButtonAction label="Exportar" icon="wink" clickEvent={saveData} />} />
            <BoardContent>
                <div className="board-form">
                    <Selector ref={collectionInput} options={collectionOptions} label="Grupo" placeholder="" multiple={false} searchable={true} style="field" val={collectionVal} setVal={setCollectionVal} />
                    <div className="item-list-edit-half">
                        <Switch onLabel="Incluir personas asoc." offLabel="Incluir personas asoc." style="compact" val={[includeSubclientsVal,setIncludeSubclientsVal]} clickEvent="" />
                        <Switch onLabel="En mayúsculas" offLabel="En mayúsculas" style="compact" val={[uppercaseVal,setUppercaseVal]} clickEvent="" />
                    </div>
                    <Divisor />
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" direction="vertical">
                            {(provided)=>(
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {rows.map((item,i)=>
                                        <Draggable key={i} draggableId={"draggable-"+i} index={i}>
                                            {(provided)=>(
                                                <div style={provided.draggableProps.style} className="row-draggable" ref={provided.innerRef} {...provided.draggableProps}>
                                                    <div className="row-item">
                                                        <div>
                                                            <div className="row-drag" {...provided.dragHandleProps}><img src={dragIcon} /></div>
                                                        </div>
                                                        <div>
                                                            <SwitchMini label={item["name"]} initial={item["status"]} clickEvent={()=>statusChange(i)} />
                                                        </div>
                                                        <div>
                                                            <input className="textbox-mini" type="text" value={item["alias"]} onChange={(event)=>aliasChange(event,i)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </BoardContent>
        </Board>
    )
}