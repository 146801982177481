import {useState,useRef,Fragment,useEffect} from "react"
import {osName,osVersion,browserName,browserVersion,mobileVendor,mobileModel,deviceType} from "react-device-detect"
import Countdown from "react-countdown"
import useApi from "../hooks/useApi"
import useDialog from "../hooks/useDialog"
import useSession from "../hooks/useSession"
import EntranceLayout from "../components/EntranceLayout"
import EntranceSection from "../components/EntranceSection"
import EntranceTextbox from "../commons/EntranceTextbox"
import EntranceButton from "../commons/EntranceButton"
import nextImg from "../assets/next.svg"

export default function Entrance(){

    const {validateSession}=useSession()
    useEffect(()=>{
        validateSession()
    },[])
    const requestApi=useApi()
    const {initSession}=useSession()
    const {changeDialog}=useDialog()
    const [entranceStep,setEntranceStep]=useState("username")
    const usernameInput=useRef()
    const passwordInput=useRef()
    const buttonNext1=useRef()
    const buttonNext2=useRef()
    const [usernameVal,setUsernameVal]=useState("")
    const [passwordVal,setPasswordVal]=useState("")
    const [welcomeName,setWelcomeName]=useState("")
    const [avatarId,setAvatarId]=useState("")
    const [avatarPicture,setAvatarPicture]=useState(false)
    const [avatarCapitals,setAvatarCapitals]=useState("")
    const [resetCountdown,setResetCountdown]=useState(false)
    const [firstTimeCountdown,setFirstTimeCountdown]=useState(false)

    useEffect(()=>{
        if(buttonNext1.current){
            if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(usernameVal)){
                buttonNext1.current.style.opacity="1"
            }else{
                buttonNext1.current.style.opacity="0.4"
            }            
        }
    },[usernameVal])

    useEffect(()=>{
        if(buttonNext2.current){
            if(passwordVal.length>3){
                buttonNext2.current.style.opacity="1"
            }else{
                buttonNext2.current.style.opacity="0.4"
            }            
        }
    },[passwordVal])

    const password=(event)=>{
        event.preventDefault()
        if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(usernameVal)){
            requestApi("users/entrance",{
                    email:usernameVal
                })
                .then(data=>{
                    setWelcomeName("")
                    if(data.status=="active"){
                        setWelcomeName(data.first_name)
                        setAvatarId(data.user_id)
                        setAvatarPicture(data.has_picture)
                        setAvatarCapitals(data.capitals)
                        setEntranceStep("password")
                    }else if(data.status=="first_time"){
                        setWelcomeName(data.first_name)
                        setAvatarId(data.user_id)
                        setAvatarPicture(data.has_picture)
                        setAvatarCapitals(data.capitals)
                        setResetCountdown(false)
                        setEntranceStep("first_time")
                    }else if(data.status=="not_exist"){
                        changeDialog("user_not_exists","","")
                    }else if(data.status=="inactive"){
                        changeDialog("user_inactive","","")
                    }else if(data.status=="blocked"){
                        changeDialog("user_blocked","","")
                    }
                })
        }else{
            usernameInput.current.focus()
        }
    }

    const authenticate=(event)=>{
        event.preventDefault()
        if(passwordVal.length>3){
            requestApi("users/authorize",{
                    email:usernameVal,
                    password:passwordVal,
                    os_name:osName,
                    os_version:osVersion,
                    browser_name:browserName,
                    browser_version:browserVersion,
                    device_type:deviceType,
                    device_vendor:mobileVendor,
                    device_model:mobileModel,
                    device:deviceType
                })
                .then(data=>{
                    if(data.status=="not_authorized"){
                        changeDialog("user_not_authorized","","")
                    }else if(data.status=="wrong_password"){
                        changeDialog("user_wrong_password","","")
                        setPasswordVal("")
                    }else if(data.status=="authorized"){
                        initSession("login",data.user_id,data.session_token,data.session_token_id,"/tablero")
                    }
                })
        }else{
            passwordInput.current.focus()
        }
    }

    const resetPassword=()=>{
        if(resetCountdown==false){
            requestApi("users/password_reset_token",{
                    context:"forget",
                    email:usernameVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        setResetCountdown(true)
                    }else if(data.status=="failed"){
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const createPassword=()=>{
        if(firstTimeCountdown==false){
            requestApi("users/password_reset_token",{
                    context:"auto_generated",
                    email:usernameVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        setFirstTimeCountdown(true)
                    }else if(data.status=="failed"){
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    return(
        <EntranceLayout>
            {entranceStep=="username" &&
            <EntranceSection title="Iniciar sesión" caption="">
                <div className="entrance-field">
                    <form>
                        <EntranceTextbox ref={usernameInput} type="text" placeholder="Email" val={usernameVal} onChange={(event)=>{setUsernameVal(event.target.value)}} />
                        <button ref={buttonNext1} type="submit" className="entrance-next" onClick={password}><img src={nextImg} /></button>
                    </form>
                </div>
                <div className="entrance-options">
                    <div className="entrance-option" onClick={()=>{changeDialog("not_account","","")}}>¿No tienes una cuenta?</div>
                </div>
            </EntranceSection>}
            {entranceStep=="password" &&
            <EntranceSection title={welcomeName!=""?"¡Hola, "+welcomeName+"!":"¡Hola!"} caption="" avatar={{id:avatarId,picture:avatarPicture,capitals:avatarCapitals}}>
                <div className="entrance-field">
                    <form>
                        <EntranceTextbox ref={passwordInput} type="password" placeholder="Contraseña" val={passwordVal} onChange={(event)=>{setPasswordVal(event.target.value)}} />
                        <button ref={buttonNext2} type="submit" className="entrance-next" onClick={authenticate}><img src={nextImg} /></button>
                    </form>
                </div>
                <div className="entrance-options">
                    <div className="entrance-option" onClick={()=>{setResetCountdown(false);setEntranceStep("reset")}}>¿Olvidaste tu contraseña?</div><br />
                    <div className="entrance-option" onClick={()=>{setEntranceStep("username")}}>{welcomeName!=""?"¿No eres "+welcomeName+"?":"¿Eres otra persona?"}</div>
                </div>
            </EntranceSection>}
            {entranceStep=="first_time" &&
            <EntranceSection title={firstTimeCountdown==false?welcomeName+", es la primera vez que ingresas a Ius+Aequitas.":"¡Enviado!"} caption={resetCountdown==false?"Enviaremos un correo a <strong>"+usernameVal+"</strong> para crear una contraseña nueva.":"Ingresa a <strong>"+usernameVal+"</strong>, busca el correo de <strong>Ius+Aequitas</strong> y sigue las indicaciones. Si no lo encuentras, revisa la carpeta de spam o intenta reenviándolo."} avatar={{id:avatarId,picture:avatarPicture,capitals:avatarCapitals}}>
                {firstTimeCountdown && 
                <div className="entrance-countdown">Reenviar en <Countdown date={Date.now()+300000} renderer={({minutes,seconds})=>{return <Fragment>{(Math.pow(10,2)+~~minutes).toString().substring(1)}:{(Math.pow(10,2)+~~seconds).toString().substring(1)}</Fragment>}} onComplete={()=>{setFirstTimeCountdown(false)}} /></div>}
                {firstTimeCountdown==false && <EntranceButton label="Enviar correo" clickEvent={createPassword} />}
                <div className="entrance-options">
                    <div className="entrance-option" onClick={()=>{setEntranceStep("username")}}>{welcomeName!=""?"¿No eres "+welcomeName+"?":"¿Eres otra persona?"}</div>
                </div>
            </EntranceSection>}
            {entranceStep=="reset" &&
            <EntranceSection title={resetCountdown==false?"Recuperar contraseña":"¡Enviado!"} caption={resetCountdown==false?"Enviaremos un correo a <strong>"+usernameVal+"</strong> para que puedas cambiar la contraseña.":"Ingresa a <strong>"+usernameVal+"</strong>, busca el correo de <strong>Ius+Aequitas</strong> y sigue las indicaciones. Si no lo encuentras, revisa la carpeta de spam o intenta reenviándolo."}>
                {resetCountdown && 
                <div className="entrance-countdown">Reenviar en <Countdown date={Date.now()+300000} renderer={({minutes,seconds})=>{return <Fragment>{(Math.pow(10,2)+~~minutes).toString().substring(1)}:{(Math.pow(10,2)+~~seconds).toString().substring(1)}</Fragment>}} onComplete={()=>{setResetCountdown(false)}} /></div>}
                {resetCountdown==false && <EntranceButton label="Enviar correo" clickEvent={resetPassword} />}
                <div className="entrance-options">
                    <div className="entrance-option" onClick={()=>{setEntranceStep("username")}}>{welcomeName!=""?"¿No eres "+welcomeName+"?":"¿Eres otra persona?"}</div>
                </div>
            </EntranceSection>}
        </EntranceLayout>
    )
}