import {useState,useRef,useEffect} from "react"
import useApi from "../hooks/useApi"
import useBoard from "../hooks/useBoard"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardActions from "../components/BoardActions"
import ButtonAction from "../commons/ButtonAction"
import Divisor from "../commons/Divisor"
import Textbox from "../commons/Textbox"
import Selector from "../commons/Selector"
import Switch from "../commons/Switch"
import ButtonCommand from "../commons/ButtonCommand"

export default function PersonsEdit({params}){

    const section="persons-edit"
    const requestApi=useApi()
    const [ready,setReady]=useState(0)
    const {loadBoard}=useBoard()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const mode=params.mode
    const [idVal,setIdVal]=useState(0)
    const [defaultData,setDefaultData]=useState("")

    const firstNameInput=useRef()
    const lastNameInput=useRef()
    const nifInput=useRef()
    const clientIdInput=useRef()
    const assemblagesInput=useRef()
    const tagsInput=useRef()
    const extra1Input=useRef()
    const extra2Input=useRef()
    const extra3Input=useRef()
    const extra4Input=useRef()
    const extra5Input=useRef()
    const extra6Input=useRef()
    const extra7Input=useRef()
    const [firstNameVal,setFirstNameVal]=useState("")
    const [lastNameVal,setLastNameVal]=useState("")
    const [nifVal,setNifVal]=useState("")
    const [clientIdVal,setClientIdVal]=useState("")
    const [assemblagesVal,setAssemblagesVal]=useState("")
    const [tagsVal,setTagsVal]=useState("")
    const [extra1Val,setExtra1Val]=useState("")
    const [extra2Val,setExtra2Val]=useState("")
    const [extra3Val,setExtra3Val]=useState("")
    const [extra4Val,setExtra4Val]=useState("")
    const [extra5Val,setExtra5Val]=useState("")
    const [extra6Val,setExtra6Val]=useState("")
    const [extra7Val,setExtra7Val]=useState("")
    const [statusVal,setStatusVal]=useState(1)

    const [assemblagesOptions,setAssemblagesOptions]=useState([])
    const [tagsOptions,setTagsOptions]=useState([
        {label:"Rojo",value:"1",color:"#FF3B2F"},
        {label:"Amarillo",value:"2",color:"#FFD207"},
        {label:"Fucsia",value:"3",color:"#FC5CB9"},
        {label:"Verde",value:"4",color:"#4CDA64"},
        {label:"Violeta",value:"5",color:"#9C71DA"},
        {label:"Naranja",value:"6",color:"#FF9501"},
        {label:"Azulado",value:"7",color:"#67899B"}
    ])

    const loadAssemblages=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("assemblages/list",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(item){
                            newOptions.push({label:item.name,value:item.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadData=()=>{
        return new Promise(function(resolve){
            if(mode=="edit"){
                resolve(
                    requestApi("persons/info",{
                            reference:params.reference
                        })
                        .then(data=>{
                            return data.data
                        })
                )
            }else{
                resolve(
                    ()=>{return ""}
                )
            }
        })
    }

    useEffect(()=>{
        load()
    },[params.reference])

    const load=()=>{
        Promise.all([loadAssemblages,loadData].map(func=>func())).then((result)=>{
            setAssemblagesOptions(result[0])
            setDefaultData(result[1])
            setReady(1)
        })
    }

    useEffect(()=>{
        if(mode=="edit" && ready){
            setIdVal(defaultData.id)
            setFirstNameVal(defaultData.first_name)
            setLastNameVal(defaultData.last_name)
            setNifVal(defaultData.nif)
            setClientIdVal(defaultData.client_id)
            let newAssemblagesValue=[]
            assemblagesOptions.forEach(function(item){
                defaultData["assemblages"].forEach(function(assemblage){
                    if(assemblage==item.value){
                        newAssemblagesValue.push({label:item.label,value:item.value})
                    }
                })
            })
            assemblagesInput.current.setValue(newAssemblagesValue)
            let newTagsValue=[]
            tagsOptions.forEach(function(item){
                defaultData["tags"].forEach(function(tag){
                    if(tag==item.value){
                        newTagsValue.push({label:item.label,value:item.value,color:item.color})
                    }
                })
            })
            tagsInput.current.setValue(newTagsValue)
            setExtra1Val(defaultData.extra_1)
            setExtra2Val(defaultData.extra_2)
            setExtra3Val(defaultData.extra_3)
            setExtra4Val(defaultData.extra_4)
            setExtra5Val(defaultData.extra_5)
            setExtra6Val(defaultData.extra_6)
            setExtra7Val(defaultData.extra_7)
            setStatusVal(defaultData.status)
        }else{
            setFirstNameVal("")
            setLastNameVal("")
            setNifVal("")
            setClientIdVal("")
            setAssemblagesVal("")
            setTagsVal("")
            setExtra1Val("")
            setExtra2Val("")
            setExtra3Val("")
            setExtra4Val("")
            setExtra5Val("")
            setExtra6Val("")
            setExtra7Val("")
            setStatusVal(1)
        }
    },[defaultData])

    const saveData=()=>{
        if(firstNameVal==""){
            firstNameInput.current.focus()
        }else{
            if(lastNameVal==""){
                lastNameInput.current.focus()
            }else{
                if(nifVal==""){
                    nifInput.current.focus()
                }else{
                    requestApi("persons/save",{
                            id:idVal,
                            mode:mode,
                            first_name:firstNameVal,
                            last_name:lastNameVal,
                            nif:nifVal,
                            client_id:clientIdVal,
                            assemblages:assemblagesInput.current.getValue(),
                            tags:tagsInput.current.getValue(),
                            extra_1:extra1Val,
                            extra_2:extra2Val,
                            extra_3:extra3Val,
                            extra_4:extra4Val,
                            extra_5:extra5Val,
                            extra_6:extra6Val,
                            extra_7:extra7Val,
                            status:statusVal
                        })
                        .then(data=>{
                            if(data.status=="success"){
                                if(mode=="create"){
                                    changeToast("created_f","")
                                }else if(mode=="edit"){
                                    changeToast("saved","")
                                }
                                refresh({board:"persons",close:"persons-edit"})
                            }else{
                                changeDialog("generic_error","","")
                            }
                        })
                }
            }
        }
    }

    const remove=()=>{
        requestApi("persons/remove",{
                reference:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    refresh({board:"persons",close:"persons-edit"})
                }else{
                    changeToast("generic_error","")
                }
            })
    }
    
    return(
        <Board params={params} ready={ready}>
            <BoardActions
                actionsLeft={mode=="create" && <ButtonAction label="Importar" icon="import" style="alt" clickEvent={()=>{loadBoard({params:{alias:"persons-import",level:2,theme:"rabbit"}})}} />}
                actionsRight={<ButtonAction label="Guardar" icon="wink" clickEvent={saveData} />} />
            <BoardContent>
                <div className="board-form">
                    <Textbox ref={firstNameInput} type="text" label="Nombres" val={firstNameVal} setVal={setFirstNameVal} disabled={false} />
                    <Textbox ref={lastNameInput} type="text" label="Apellidos" val={lastNameVal} setVal={setLastNameVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={nifInput} type="text" label="NIF" val={nifVal} setVal={setNifVal} disabled={false} />
                    <Textbox ref={clientIdInput} type="text" label="Número de cliente" val={clientIdVal} setVal={setClientIdVal} disabled={false} />
                    <Divisor />
                    <Selector ref={assemblagesInput} options={assemblagesOptions} label="Grupos" placeholder="" multiple={true} searchable={true} style="field" val={assemblagesVal} setVal={setAssemblagesVal} />
                    <Selector ref={tagsInput} options={tagsOptions} label="Etiquetas" placeholder="" multiple={true} searchable={false} style="field" val={tagsVal} setVal={setTagsVal} />
                    <Divisor />
                    <Textbox ref={extra1Input} type="text" label="Extra 1" val={extra1Val} setVal={setExtra1Val} disabled={false} />
                    <Textbox ref={extra2Input} type="text" label="Extra 2" val={extra2Val} setVal={setExtra2Val} disabled={false} />
                    <Textbox ref={extra3Input} type="text" label="Extra 3" val={extra3Val} setVal={setExtra3Val} disabled={false} />
                    <Textbox ref={extra4Input} type="text" label="Extra 4" val={extra4Val} setVal={setExtra4Val} disabled={false} />
                    <Textbox ref={extra5Input} type="text" label="Extra 5" val={extra5Val} setVal={setExtra5Val} disabled={false} />
                    <Textbox ref={extra6Input} type="text" label="Extra 6" val={extra6Val} setVal={setExtra6Val} disabled={false} />
                    <Textbox ref={extra7Input} type="text" label="Extra 7" val={extra7Val} setVal={setExtra7Val} disabled={false} />
                    <Divisor />
                    <Switch onLabel="Activa" offLabel="Inactiva" val={[statusVal,setStatusVal]} clickEvent="" />
                    {mode=="edit" &&
                    <div className="commands-block">
                        <ButtonCommand label="Eliminar" icon="remove" clickEvent={remove} />
                    </div>}
                </div>
            </BoardContent>
        </Board>
    )
}