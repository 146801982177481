import {useEffect,useState,useContext} from "react"
import GlobalContext from "../contexts/GlobalContext"
import useApi from "../hooks/useApi"
import useRefresh from "../hooks/useRefresh"
import Board from "../components/Board"
import BoardContent from "../components/BoardContent"
import BoardTools from "../components/BoardTools"
import Items from "../components/Items"

export default function Persons({params}){
    
    const section="persons"
    const [globalContext]=useContext(GlobalContext)
    const requestApi=useApi()
    const {refresh}=useRefresh()
    const [ready,setReady]=useState(0)
    const [items,setItems]=useState()
    const [keyword,setKeyword]=useState("")
    const [filters,setFilters]=useState("")
    const [selection,setSelection]=useState({on:0})

    const getData=()=>{
        requestApi("persons/list",{
                keywords:keyword,
                filters:filters
            })
            .then(data=>{
                setItems(data.items)
                setReady(1)
            })
    }

    useEffect(()=>{
        getData()
    },[keyword,filters])

    useEffect(()=>{
        if(globalContext["refresh"]["board"]=="persons"){
            getData()
            refresh(0)
        }
    },[globalContext["refresh"]])

    return(
        <Board params={params} ready={ready}>
            <BoardTools
                section={section}
                keyword={keyword}
                setKeyword={setKeyword}
                filters={filters}
                setFilters={setFilters}
                selection={selection}
                setSelection={setSelection}
            />
            <BoardContent>
                {items && <Items section={section} selection={selection} setSelection={setSelection} items={items} onItem={params.onItem} />}
            </BoardContent>
        </Board>
    )
}