import {useState,useEffect} from "react"

export default function SwitchMini({label,initial,clickEvent}){

    const [status,setStatus]=useState()

    useEffect(()=>{
        setStatus(initial)
    })

    const changeStatus=()=>{
        if(status){
            setStatus(0)
        }else{
            setStatus(1)
        }
        if(clickEvent!=""){
            clickEvent()
        }
    }

    return(
        <div className={"noselect switch switch-mini"+(status?" switch-on":"")} onClick={changeStatus}>
            <div>
                <div className="switch-chip">
                    <div className="switch-chip-bg">
                        <div className="switch-chip-point"></div>
                    </div>
                </div>
                <div className="switch-label">{label}</div>                
            </div>
        </div>
    )
    
}