import {useContext} from "react"
import AppsContext from "../contexts/AppsContext"
import useApp from "../hooks/useApp"

export default function AppSwitcher(){

    const [appsContext]=useContext(AppsContext)
    let apps=appsContext.apps
    const {changeApp}=useApp()

    return(
        <div className="app-switcher">
            <div className="app-switcher-grid">
                <div className="app-switcher-items noselect">
                    {apps.map((item,i)=>{
                        return(
                            <div key={i} className="app-switcher-item" onClick={()=>{changeApp(item.alias)}} style={{
                                backgroundColor:(item.color=="blue"?"#0066CC":item.color=="green"?"#1ED760":""),
                                boxShadow:"0px 0px 18px -2px rgba("+(item.color=="blue"?"0,102,204":item.color=="green"?"30,215,96":"")+",0.45)"
                            }}>{item.name}</div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}